import { LoyaltyRedemptionConstants } from "../constants";

export function LoyaltyRedemption(state = {}, action) {

    switch (action.type) {

        case LoyaltyRedemptionConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case LoyaltyRedemptionConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: state.items == null ? [action.data] : [action.data, ...state.items]
            };
        case LoyaltyRedemptionConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };


        case LoyaltyRedemptionConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        case LoyaltyRedemptionConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case LoyaltyRedemptionConstants.GETALL_SUCCESS:
            return {
                ...state,
                data: action.datas
            };
        case LoyaltyRedemptionConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };

        default:
            return state
    }


}