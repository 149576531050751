import { authHeader, handleResponse } from '../helpers';
export const ProductRatingRptServices = {
    fetchVerificationData   
};

function fetchVerificationData(data) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}ProductMaster/GetProductsRating`, requestOptions)
        .then(handleResponse);
}