export const issuePageConstants = {
  CREATE_REQUEST: "ISSUEPAGE_CREATE_REQUEST",
  CREATE_SUCCESS: "ISSUEPAGE_CREATE_SUCCESS",
  CREATE_FAILURE: "ISSUEPAGE_CREATE_FAILURE",

  UPDATE_REQUEST: "ISSUEPAGE_UPDATE_REQUEST",
  UPDATE_SUCCESS: "ISSUEPAGE_UPDATE_SUCCESS",
  UPDATE_FAILURE: "ISSUEPAGE_UPDATE_FAILURE",

  GETALL_REQUEST: "ISSUEPAGE_GETALL_REQUEST",
  GETALL_SUCCESS: "ISSUEPAGE_GETALL_SUCCESS",
  GETALL_FAILURE: "ISSUEPAGE_GETALL_FAILURE",

  ISACTIVE_REQUEST: "ISSUEPAGE_ISACTIVE_REQUEST",
  ISACTIVE_SUCCESS: "ISSUEPAGE_ISACTIVE_SUCCESS",
  ISACTIVE_FAILURE: "ISSUEPAGE_ISACTIVE_FAILURE",


  UPLOAD_REQUEST:"UPLOAD_REQUEST",
  UPLOAD_SUCCESS:"UPLOAD_SUCCESS",
  UPLOAD_PARTIAL_SUCCESS:"UPLOAD_PARTIAL_SUCCESS",
  UPLOAD_FAILURE:"UPLOAD_FAILURE",

  DIALOG_REQUEST: "DIALOG_REQUEST",
  LOADING_REQUEST: "LOADING_REQUEST",
  DIALOG_ISACTIVE_REQUEST: "DIALOG_ISACTIVE_REQUEST",
  
  ERROR_DIALOG_REQUEST: "ERROR_DIALOG_REQUEST",
  DIALOGUE_REQUEST: "DIALOGUE_REQUEST",
  LOADING_REQUEST: "LOADING_REQUEST",

  GETDEALERDDL_REQUEST: "GETDEALERDDL_REQUEST",
  GETDEALERDDL_SUCCESS: "GETDEALERDDL_SUCCESS",
  GETDEALERRDDL_FAILURE: "GETDEALERRDDL_FAILURE",
};
