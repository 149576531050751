import { authHeader, handleResponse, authHeaderFormData } from "../helpers";
export const issuePageService = {
  InsertIssueData,
  GetIssueData,
  fetchToNo,
  GetAvailableseries,
  UploadIssusePage,
  getDistributorDDL,
};

function InsertIssueData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}IssuePage/InsertIssueData`,
    requestOptions
  ).then(handleResponse);
}

function GetIssueData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  // return fetch('data/products.json').then(res => res.json()).then(d => d.data);
  return fetch(
    `${process.env.REACT_APP_API_URL}IssuePage/GetIssueData`,
    requestOptions
  ).then(handleResponse);
}

function fetchToNo() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  // return fetch('data/products.json').then(res => res.json()).then(d => d.data);
  return fetch(
    `${process.env.REACT_APP_API_URL}Mapping/GetToNumbers`,
    requestOptions
  ).then(handleResponse);
}

function GetAvailableseries(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}Mapping/GetAvailableseries`,
    requestOptions
  ).then(handleResponse);
}

function UploadIssusePage(formData) {
  ;
  console.log(formData)
  const requestOptions = {
    method: "POST",
    headers: authHeaderFormData(),
    body: formData,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}IssuePage/uploadIssusePage`,
    requestOptions
  ).then(handleResponse);
}

function getDistributorDDL() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}IssuePage/GetDistributorDLL`,
    requestOptions
  ).then(handleResponse);
}
