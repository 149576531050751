import { issuePageConstants } from "../constants/issuePageConstants";

export function issuePage(state = {}, action) {
  switch (action.type) {
    case issuePageConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool,
      };
    case issuePageConstants.DIALOG_REQUEST:
      return {
        ...state,
        showDialog: action.isbool,
      };
    case issuePageConstants.DIALOG_ISACTIVE_REQUEST:
      return {
        ...state,
        showIsActiveDialog: action.isbool,
      };
    case issuePageConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case issuePageConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };

    case issuePageConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case issuePageConstants.GETALL_AVAILABLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case issuePageConstants.GETALL_AVAILABLE_SUCCESS:
      return {
        ...state,
        Mappseries: action.datas,
      };
    case issuePageConstants.GETALL_AVAILABLE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case issuePageConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case issuePageConstants.CREATE_SUCCESS:
      return {
        ...state,
        // items: state.items == null ? [action.data] : [action.data, ...state.items]
      };
    case issuePageConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case issuePageConstants.UPDATE_REQUEST:
      return {
        ...state,
        // items: state.items.map(item =>
        //     item.CategoryId === action.data.CategoryId ? { ...item, updating: true } : item
        // )
      };
    case issuePageConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.CategoryId === action.data.CategoryId ? action.data : item
        ),
      };
    case issuePageConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case issuePageConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case issuePageConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.CategoryId === action.data
            ? {
                CategoryName: item.CategoryName,
                isActive: item.isActive,
              }
            : item
        ),
      };
    case issuePageConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case issuePageConstants.ISACTIVE_REQUEST:
      return {
        ...state,
        activating: true,
      };
    case issuePageConstants.ISACTIVE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.CategoryId === action.data.CategoryId
            ? {
                CategoryName: item.CategoryName,
                TotalRows: item.TotalRows,
                IsActive: action.data.IsActive,
                CreatedDate: item.CreatedDate,
              }
            : item
        ),
      };
    case issuePageConstants.ISACTIVE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case issuePageConstants.GETDEALERDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case issuePageConstants.GETDEALERDDL_SUCCESS:
      return {
        ...state,
        DealerData: action.datas,
      };
    case issuePageConstants.GETDEALERRDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    // DELETE_REQUEST:
    case issuePageConstants.UPLOAD_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case issuePageConstants.UPLOAD_SUCCESS:
      return {
        ...state,
      };
    case issuePageConstants.UPLOAD_PARTIAL_SUCCESS:
      ;
      return {
        ...state,
        showErrorDialog: true,
        ErrorData: action.datas,
      };
    case issuePageConstants.UPLOAD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case issuePageConstants.ERROR_DIALOG_REQUEST:
      return {
        ...state,
        showErrorDialog: action.isbool,
      };

    default:
      return state;
  }
}
