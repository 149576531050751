import { authHeader, handleResponse } from "../helpers";
export const categoryService = {
  getCategory,
  createCategory,
  createSubCategory,
  updateCategory,
  SubUpdateCategory,
  inActiveRow,
  inActiveRow2,
  getSubCategory,
  fetchCategoryDLL,
  fetchSubCategoryDLL,
};

function getCategory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}CategoryMaster/GetCategory`,
    requestOptions
  ).then(handleResponse);
}

function getSubCategory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}CategoryMaster/GetSubCategory`,
    requestOptions
  ).then(handleResponse);
}

function createCategory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}CategoryMaster/CreateCategory`,
    requestOptions
  ).then(handleResponse);
}

function createSubCategory(data) {
  console.log(data);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}CategoryMaster/CreateSubCategory`,
    requestOptions
  ).then(handleResponse);
}
function updateCategory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}CategoryMaster/UpdateCategory`,
    requestOptions
  ).then(handleResponse);
}
function SubUpdateCategory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}CategoryMaster/SubUpdateCategory`,
    requestOptions
  ).then(handleResponse);
}

function inActiveRow(categoryId, isActive) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}CategoryMaster/InActiveRow/${categoryId}/${isActive}`,
    requestOptions
  ).then(handleResponse);
}
function inActiveRow2(categoryId, isActive) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}CategoryMaster/InActiveRow2/${categoryId}/${isActive}`,
    requestOptions
  ).then(handleResponse);
}

function fetchCategoryDLL() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}CategoryMaster/GetCategoryDLL`,
    requestOptions
  ).then(handleResponse);
}
function fetchSubCategoryDLL(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}CategoryMaster/GetSubCategoryDLL?categoryId=${data}`,
  ).then(handleResponse);
}
