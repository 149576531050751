import { authHeader, handleResponse } from '../helpers';
export const damageService = {
    createDamage,
    fetchDamageData,
    fetchMappingData
};

function createDamage(data) {  

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Damage/CreateDamage`, requestOptions)
    .then(handleResponse);
}

function fetchDamageData(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}Damage/GetDamages`, requestOptions)
    .then(handleResponse);
}

function fetchMappingData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    // return fetch('data/products.json').then(res => res.json()).then(d => d.data);
    return fetch(`${process.env.REACT_APP_API_URL}Mapping/GetMappings`, requestOptions)
    .then(handleResponse);
}




