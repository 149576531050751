import { authHeader, handleResponse } from "../helpers";
export const LoyaltyRedemptionService = {
  LoyaltyRedemption,
  FetchLoyaltyRedemption,
};

function LoyaltyRedemption(data) {
  //console.log(data);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}RedemptionApproval/LoyaltyRedemption`,
    requestOptions
  ).then(handleResponse);
}

function FetchLoyaltyRedemption(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}RedemptionApproval/GetLoyaltyRedemption`,
    requestOptions
  ).then(handleResponse);
}
