import { UserloyalityLogRptConstants } from "../constants";

export function UserloyalityLogRpt(state = {}, action) {
    switch (action.type) {
        case UserloyalityLogRptConstants.LOADING_REQUEST:
            return {
                ...state,
                loading: action.isbool
            };
        case UserloyalityLogRptConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case UserloyalityLogRptConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case UserloyalityLogRptConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case UserloyalityLogRptConstants.GETEXPORT_REQUEST:
            return {
                ...state,
                exporting: true
            };
        case UserloyalityLogRptConstants.GETEXPORT_SUCCESS:
            return {
                ...state,
                exportitems: action.datas
            };
        case UserloyalityLogRptConstants.GETEXPORT_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }

}