import { authHeader, handleResponse } from '../helpers';
export const UnscanRptService = {
    fetchUnscanData   
};

function fetchUnscanData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}VerificationLog/GetUnscanReport`, requestOptions)
        .then(handleResponse);
}