import { listMasterConstants } from "../constants/listMasterConstants";

export function listMaster(state = {}, action) {
  switch (action.type) {
    case listMasterConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool,
      };
    case listMasterConstants.DIALOG_REQUEST:
      return {
        ...state,
        showDialog: action.isbool,
      };
    case listMasterConstants.DIALOGUE_REQUEST:
      return {
        ...state,
        showDialogue: action.isbool,
      };
    case listMasterConstants.DIALOG_ISACTIVE_REQUEST:
      return {
        ...state,
        showIsActiveDialog: action.isbool,
      };
    case listMasterConstants.UPDATE_PAGE_STORE:
      return {
        ...state,
        pages: action.pageList,
      };
    case listMasterConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case listMasterConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case listMasterConstants.GETALL_AVAILABLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case listMasterConstants.GETALL_AVAILABLE_SUCCESS:
      return {
        ...state,
        Mappseries: action.datas,
      };
    case listMasterConstants.GETALL_AVAILABLE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case listMasterConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case listMasterConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case listMasterConstants.CREATE_SUCCESS:
      return {
        ...state,
        items:
          state.items == null ? [action.data] : [action.data, ...state.items],
      };
    case listMasterConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case listMasterConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: (state.items || []).map((item) =>
          item.RoleId === action.data.RoleId
            ? { ...item, updating: true }
            : item
        ),
      };
    case listMasterConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.RoleId === action.data.RoleId ? action.data : item
        ),
      };
    case listMasterConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case listMasterConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case listMasterConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.RoleId === action.data
            ? {
                CompanyId: item.CompanyId,
                CompanyName: item.CompanyName,
                roleId: item.roleId,
                RoleName: item.roleName,
                RoleDesc: item.RoleDesc,
                isActive: item.isActive,
                CreatedDate: item.CreatedDate,
              }
            : item
        ),
      };
    case listMasterConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case listMasterConstants.GETROLEDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case listMasterConstants.GETROLEDDL_SUCCESS:
      return {
        ...state,
        rollddl: action.datas,
      };
    case listMasterConstants.GETROLEDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case listMasterConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case listMasterConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };

    case listMasterConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case listMasterConstants.GETPRDCTDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case listMasterConstants.GETPRDCTDDL_SUCCESS:
      return {
        ...state,
        prdctddl: action.datas,
      };
    case listMasterConstants.GETPRDCTDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case listMasterConstants.GET_PAGES_REQUEST:
      return {
        ...state,
        getpages: true,
      };
    case listMasterConstants.GET_PAGES_SUCCESS:
      return {
        ...state,
        pages: action.pages,
      };
    case listMasterConstants.GET_PAGES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case listMasterConstants.ISACTIVE_REQUEST:
      return {
        ...state,
        activating: true,
      };
    // case listMasterConstants.ISACTIVE_SUCCESS:
    //   return {
    //     ...state,
    //     items: state.items.map((item) =>
    //       item.RoleId === action.data.RoleId
    //         ? {
    //             CompanyId: item.CompanyId,
    //             CompanyName: item.CompanyName,
    //             RoleId: item.RoleId,
    //             RoleName: item.RoleName,
    //             RoleDesc: item.RoleDesc,
    //             TotalRows: item.TotalRows,
    //             IsActive: action.data.IsActive,
    //             CreatedDate: item.CreatedDate,
    //           }
    //         : item
    //     ),
    //   };

    case listMasterConstants.GETDATALISTDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case listMasterConstants.GETDATALISTDDL_SUCCESS:
      return {
        ...state,
        datalistddl: action.datas,
      };
    case listMasterConstants.GETDATALISTDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case listMasterConstants.UPLOAD_SO_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case listMasterConstants.UPLOAD_SO_SUCCESS:
      return {
        ...state,
      };
    case listMasterConstants.UPLOAD_SO_PARTIAL_SUCCESS:
      ;
      return {
        ...state,
        showErrorDialog: true,
        ErrorData: action.datas,
      };
    case listMasterConstants.UPLOAD_SO_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case listMasterConstants.ISACTIVE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case listMasterConstants.ERROR_DIALOG_REQUEST:
      return {
        ...state,
        showErrorDialog: action.isbool,
      };
    default:
      return state;
  }
}
