import { DealerStockInVoiceService } from '../services/DealerStockInvoiceService'
import { DealerStockInvoiceConstants } from '../constants';
import { alertActions } from '.';

export const DealerStockInvoiceActions = {
    getDealerDDL,
    getProductByQrScan,
    removeItem,
    saveDealerInvoicing,
    GetDealerInvoicing,
    loadingData
};

function getDealerDDL() {
    return dispatch => {
        dispatch(request());

        DealerStockInVoiceService.fetchDealerDDL().then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: DealerStockInvoiceConstants.GETDEALERDDL_REQUEST } }
    function success(datas) { return { type: DealerStockInvoiceConstants.GETDEALERDDL_SUCCESS, datas } }
    function failure(error) { return { type: DealerStockInvoiceConstants.GETDEALERDDL_FAILURE, error } }
};

function getProductByQrScan(data, scannedList) {
    
    return (dispatch) => {
        dispatch(request(data));

        DealerStockInVoiceService.getProductByQrScan(data).then(
            (response) => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        const resdata = response.ResponseData;
                        if (resdata.ProductID > 0) {
                            let checkseqNo = scannedList?.filter(
                                (y) => y.SeqNo == resdata.SeqNo
                            );
                            if (checkseqNo && checkseqNo.length > 0) {
                                dispatch(warning(response.ResponseMessage));
                                dispatch(alertActions.warning("already Scanned"));
                                return;
                            }
                            dispatch(success(response.ResponseData));
                        }
                    } else if (response.ResponseCode === "400") {
                        dispatch(warning(response.ResponseMessage));
                        dispatch(alertActions.warning(response.ResponseMessage));
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage));
                    }
                }
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) {
        return { type: DealerStockInvoiceConstants.ProductByQrScan_REQUEST, data };
    }
    function success(data) {
        return { type: DealerStockInvoiceConstants.ProductByQrScan_SUCCESS, data };
    }
    function warning(data) {
        return {
            type: DealerStockInvoiceConstants.ProductByQrScan_Warning,
            data,
        };
    }
    function failure(error) {
        return { type: DealerStockInvoiceConstants.ProductByQrScan_FAILURE, error };
    }
}

function removeItem(data) {
    return (dispatch) => {
        dispatch(success(data));
    };
    function success(data) {
        return {
            type: DealerStockInvoiceConstants.REMOVE_ITEM,
            data,
        };
    }
}

function saveDealerInvoicing(data) {
    return (dispatch) => {
        dispatch(request(data));

        DealerStockInVoiceService.saveDealerInvoice(data).then(
            (response) => {
                
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(alertActions.success(response.ResponseMessage));
                        dispatch(success(response.ResponseData));
                        window.location.reload();
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage));
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage));
                    }
                }
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) {
        return {
            type: DealerStockInvoiceConstants.SAVE_STOCK_INVOICE_RESUEST,
            data,
        };
    }
    function success(data) {
        return {
            type: DealerStockInvoiceConstants.SAVE_STOCK_INVOICE_SUCCESS,
            data,
        };
    }
    function failure(error) {
        return {
            type: DealerStockInvoiceConstants.SAVE_STOCK_INVOICE_FAILURE,
            error,
        };
    }
}


function GetDealerInvoicing(tblobj) {
    return (dispatch) => {
        dispatch(request());
        DealerStockInVoiceService.getDealerInvoicing(tblobj).then(
            (response) => {
                dispatch(loadingData(false));
                dispatch(success(response.ResponseData));
            },
            (error) => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: DealerStockInvoiceConstants.GETALL_REQUEST };
    }
    function success(data) {
        return { type: DealerStockInvoiceConstants.GETALL_SUCCESS, data };
    }
    function failure(error) {
        return { type: DealerStockInvoiceConstants.GETALL_FAILURE, error };
    }
}

function loadingData(isbool) {
    return {
        type: DealerStockInvoiceConstants.LOADING_REQUEST,
        isbool,
    };
}