import { authHeader, handleResponse } from "../helpers";

export const DealerStockInVoiceService = {
    fetchDealerDDL,
    getProductByQrScan,
    saveDealerInvoice,
    getDealerInvoicing
};

async function fetchDealerDDL() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    const response = await fetch(
        `${process.env.REACT_APP_API_URL}UserDMaster/DealerFrimDDL`,
        requestOptions
    );
    return handleResponse(response);
}

async function getProductByQrScan(data) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}UserDMaster/GetProductByQrScan`, requestOptions);
    return handleResponse(response);
}

async function saveDealerInvoice(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}UserDMaster/SaveDealerInvoice`, requestOptions);
    return handleResponse(response);
}

async function getDealerInvoicing(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}UserDMaster/GetDealerInvoicing`, requestOptions);
    return handleResponse(response);
}