import { authHeader, handleResponse } from "../helpers";
export const AlldealerdataLogServices = {
  fetchdealerdataData,
  fetchStateDLL,
  fetchCityDLL,
};
function fetchStateDLL(countryId) {
  ;
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}VerificationLog/GetStateDLL/${countryId}`,
    requestOptions
  ).then(handleResponse);
}

function fetchCityDLL(stateid) {
  ;
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}VerificationLog/GetCityDLL/${stateid}`,
    requestOptions
  ).then(handleResponse);
}

function fetchdealerdataData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}VerificationLog/GetAllDealerDataReport`,
    requestOptions
  ).then(handleResponse);
}
