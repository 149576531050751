import { dealerdataLogConstants } from "../constants";

export function dealerdata(state = {}, action) {
  switch (action.type) {
    case dealerdataLogConstants.LOADING_REQUEST:
      return {
        ...state,
        loading: action.isbool,
      };
    case dealerdataLogConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dealerdataLogConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case dealerdataLogConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case dealerdataLogConstants.GETEXPORT_REQUEST:
      return {
        ...state,
        exporting: true,
      };
    case dealerdataLogConstants.GETEXPORT_SUCCESS:
      return {
        ...state,
        exportitems: action.datas,
      };
    case dealerdataLogConstants.GETEXPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case dealerdataLogConstants.GETSCITYDDL_REQUEST:
      return {
        ...state,
        exporting: true,
      };
    case dealerdataLogConstants.GETSCITYDDL_SUCCESS:
      return {
        ...state,
        cityddl: action.datas,
      };
    case dealerdataLogConstants.GETSCITYDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case dealerdataLogConstants.GETSTATEDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dealerdataLogConstants.GETSTATEDDL_SUCCESS:
      return {
        ...state,
        stateddl: action.datas,
      };
    case dealerdataLogConstants.GETSTATEDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
