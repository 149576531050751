import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TextField from "@material-ui/core/TextField";
import { Calendar } from "primereact/calendar";
import { alertActions, AlldealerdataActions, dealerMasterActions, } from "../../actions";
import { connect } from "react-redux";
import tableRequest from "../../models/tableRequest.js";
import { Toast } from "primereact/toast";
import CsvDownloader from "react-csv-downloader";
import { CSVLink } from "react-csv";
import Viewer from "react-viewer"; 
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Loader from '../../templates/Loader.js';

class AllDealerDataRpt extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.FilterData = this.FilterData.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.ImageViewTemplate = this.ImageViewTemplate.bind(this);
        this.state = {
            loading: false,
            FromDate: new Date(),
            ToDate: new Date(),
            first: 0,
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            sortOrder: "",
            datalist: [],
            visible: false,
            aadharImage: null,
            Bill_City: 0,
            Bill_State: 0

        };
    }
    componentDidMount() {
        var date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() - 7);
        this.tblResponse.FromDate = date.toLocaleDateString();
        this.tblResponse.ToDate = new Date().toLocaleDateString();
        this.setState({
            FromDate: date,
            ToDate: new Date(),
        });
        this.props.fetchApi(this.tblResponse);
        this.props.exportingData(this.tblResponse);
        this.props.getStateDDL(1);
        // this.props.getCityDDL(this,this.tblResponse);
    }

    onChange = (date) => this.setState({ FromDate: "", ToDate: "" });

    // GetVerification()
    // {
    //     let ToDt = new Date();
    //     let FrmDt = new Date();
    //     FrmDt.setDate(ToDt.getDate() - 7);

    //     const myFmDt = this.getCurrentDateYYYYMMDD(FrmDt);
    //     const myToDt = this.getCurrentDateYYYYMMDD(ToDt);

    //     this.tblResponse.FromDate =this.state.FromDate;
    //     this.tblResponse.ToDate = this.state.ToDate;
    //     this.props.fetchApi(this.tblResponse);
    // }
    FilterData() {
        ;
        this.tblResponse.FromDate = this.state.FromDate;
        this.tblResponse.ToDate = this.state.ToDate;
        this.tblResponse.CityId = this.state.Bill_City
        this.tblResponse.StateId = this.state.Bill_State
        this.props.exportingData(this.tblResponse);
        this.props.fetchApi(this.tblResponse);

    }

    getCurrentDateYYYYMMDD(dt) {
        var dd = String(dt.getDate()).padStart(2, "0");
        var mm = String(dt.getMonth() + 1).padStart(2, "0");
        var yyyy = dt.getFullYear();

        dt = yyyy + "/" + mm + "/" + dd;
        return dt;
    }
    getStateList(event) {
        
        //this.handleChange(event)

        //this.setState({ Bill_Country: event.target.value });
        this.props.getStateDDL(1);


    }
    getCityList(event) {
        ;
        this.handleChange(event);
        //this.setState({ Bill_State: event.target.value });
        this.props.getCityDDL(event.target.value);
    }



    handleChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case "FromDate":
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    // this.refs.Messchild.showError("Please select from date.");
                    this.toast.show({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Please select from date.",
                        life: 3000,
                    });

                    return;
                } else if (
                    !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
                ) {
                    this.formIsValid = false;
                    this.toast.show({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Please enter only date.",
                        life: 3000,
                    });
                    // this.refs.Messchild.showError("Please enter only date.");
                    return;
                } else if (value instanceof Date && this.state.ToDate instanceof Date) {
                    const startDate = value;
                    const endDate = this.state.ToDate;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.toast.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "From date must be less than to date.",
                            life: 3000,
                        });
                        // this.refs.Messchild.showError("From date must be less than to date.");
                        return;
                    }
                }

                break;
            case "ToDate":
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.toast.show({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Please select to date.",
                        life: 3000,
                    });
                    // this.refs.Messchild.showError("Please select to date.");
                    return;
                } else if (
                    !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
                ) {
                    this.formIsValid = false;
                    this.toast.show({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Please enter only date.",
                        life: 3000,
                    });
                    // this.refs.Messchild.showError("Please enter only date.");
                    return;
                } else if (
                    value instanceof Date &&
                    this.state.FromDate instanceof Date
                ) {
                    const startDate = this.state.FromDate;
                    const endDate = value;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.toast.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "To date must be greater than from date.",
                            life: 3000,
                        });
                        // this.refs.Messchild.showError("To date must be greater than from date.");
                        return;
                    }
                }

                break;
            default:
                break;
        }

        this.setState({ [name]: value });
    }

    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = this.state.searchVal
            ? this.state.searchVal
            : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
        this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
        this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
        this.tblResponse.CityId = this.state.Bill_City
        this.tblResponse.StateId = this.state.Bill_State
    }

    onPage(event) {
        this.props.loadingData(true);
        //imitate delay of a backend call
        setTimeout(() => {
            ;
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
            this.props.exportingData(this.tblResponse);
        }, 250);
    }

    handleProductName(rowData) {
        if (rowData.ProductName == null) {
            let list;

            list = rowData.QrData;

            return list;
        } else {
            return rowData.ProductName;
        }
    }

    //to search
    searchFilter(e) {
        this.setState({
            searchVal: e.target.value,
        });
        this.props.loadingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
        this.props.exportingData(this.tblResponse);
    }

    //to sort
    onSort(event) {
        this.props.loadingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField =
            event.multiSortMeta[0].field === "CreatedDate"
                ? "CreatedDt"
                : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
        this.props.exportingData(this.tblResponse);
    }

    componentDidUpdate(prevProps) {
        if (this.props.verificationList) {
            if (prevProps.verificationList !== this.props.verificationList) {
                // this.props.myProp has a different value
                if (this.props.verificationList) {
                    var e = this.props.verificationList;
                    this.setState({
                        totalRecords: e[0].TotalRows,
                    });
                }
            }
        } else {
            if (this.state.totalRecords !== 0)
                this.setState({
                    totalRecords: 0,
                    first: 0,
                });
        }
    }



    ImageViewTemplate(rowData) {
        if (rowData.AadharImage) {
            return (
                <div>
                    <Button
                        onClick={() => {
                            this.setState({
                                visible: true,
                                aadharImage: rowData.AadharImage,
                            });
                        }}
                    >
                        View Image
                    </Button>
                </div>
            );
        } else {
            return <span>N/A</span>;
        }
    }

    render() {
        return (
            <div className="datatable-filter-demo">
                {/* <ToastContainer /> */}
                <Toast ref={(el) => (this.toast = el)} />
                <Loader loader={this.props.loading} />
                <div className="row mb-3">
                    <div className="col-md-2">
                        <h4>Dealer Report</h4>
                    </div>
                    <div className="col-md-10">
                        <div className="row text-right">
                            <div className="col-md-2 col-sm-12">
                                <Calendar
                                    placeholder="From Date"
                                    showIcon="true"
                                    name="FromDate"
                                    value={this.state.FromDate}
                                    onChange={this.handleChange}
                                    appendTo={document.getElementById("root")}
                                ></Calendar>
                            </div>

                            <div className="col-md-2 col-sm-12">
                                <Calendar
                                    placeholder="To Date"
                                    showIcon="true"
                                    name="ToDate"
                                    value={this.state.ToDate}
                                    onChange={this.handleChange}
                                    appendTo={document.getElementById("root")}
                                ></Calendar>
                            </div>
                            <div className="col-md-2 col-sm-12">
                                <Dropdown
                                    appendTo={document.getElementById("root")}
                                    value={this.state.Bill_State}
                                    onChange={(e) => {
                                        this.getCityList(e);
                                    }}
                                    options={this.props.StateData}
                                    optionValue="value"
                                    optionLabel="label"
                                    className="form-control custom-select"
                                    placeholder={"Select State*"}
                                    name="Bill_State"
                                    filter
                                />
                            </div>
                            <div className="col-md-2 col-sm-12">
                                <Dropdown
                                    appendTo={document.getElementById("root")}
                                    value={this.state.Bill_City}
                                    //value={this.props.ProductData}
                                    options={this.props.UserCityData}
                                    optionValue="value"
                                    optionLabel="label"
                                    className="form-control custom-select"
                                    placeholder={"Select City*"}
                                    name="Bill_City"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    filter
                                />
                            </div>
                            <div className="col-md-2 text-center">
                                <button
                                    type="button"
                                    onClick={this.FilterData}
                                    className="btn btn-primary p-2 buttone"
                                >
                                    Submit
                                </button>
                                &nbsp;
                                <CSVLink
                                    data={this.props.List}
                                    headers={this.state.headers}
                                    filename={"Dealer_Report.csv"}
                                    target="_blank"
                                >
                                    <button className="btn btn-success p-2 buttone">
                                        Export
                                    </button>
                                </CSVLink>
                            </div>
                            <div className="col-md-2 col-sm-12">
                                <TextField
                                    id="standard-basic"
                                    size="small"
                                    variant="outlined"
                                    className="fullwidth"
                                    label="Search"
                                    onInput={this.searchFilter}
                                />
                            </div>
                        </div>
                    </div>
                    <Viewer
                        visible={this.state.visible}
                        onClose={() => {
                            this.setState({ visible: false });
                        }}
                        images={[{ src: this.state.aadharImage }]}
                    />
                </div>

                <div className="card">
                    <DataTable
                        value={this.props.verificationList}
                        scrollable
                        responsive={true}
                        globalFilter={this.state.globalFilter}
                        emptyMessage="No data found."
                        sortMode="multiple"
                        paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows}
                        style={{ width: "100%" }}
                        rowsPerPageOptions={[10, 100, 200,500 ]}
                        totalRecords={parseInt(this.state.totalRecords)}
                        lazy={true}
                        first={this.state.first}
                        onPage={this.onPage}
                        onSort={this.onSort}
                        ref={(el) => {
                            this.dt = el;
                        }}
                    >
                        <Column
                            field="ProductCode"
                            disabled
                            header="Product Code"
                            sortable
                        ></Column>


                        <Column
                            field="DealerFirmName"
                            disabled
                            header="Dealer Name"
                            sortable
                        ></Column>

                        <Column
                            field="DistributorFirmName"
                            disabled
                            header="Distributor Name"
                            sortable
                        ></Column>

                        <Column
                            field="VerifiedDate"
                            disabled
                            header="Verified Date"
                            sortable
                        ></Column>
                           <Column field="BatchNo" disabled header="Batch No" sortable></Column>
                       
                        <Column field="SeqNo" disabled header="Seq No" sortable></Column>
                        <Column field="LoyalityPoints" disabled header="Loyality points" sortable></Column>
                        <Column field="MobileNo" header="Mobile" sortable></Column>
                        {/* <Column field="VerifiedBy" header="Verified By" sortable></Column> */}
                        <Column field="UserAddress" header="Address" sortable></Column>
                        <Column field="UserState" header="State" sortable></Column>

                        <Column field="UserCity" header="City" sortable></Column>
                        <Column field="UserZip" header="Pincode" sortable></Column>
                        {/* <Column
              body={this.ImageViewTemplate}
              header="Aadhar Image"
            ></Column> */}
                    </DataTable>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    //console.log(state.verification.items)
    // console.log("STATEDDL",state.dealerdata.bcityddl);
    return {
        verificationList: state.dealerdata.items,
        StateData: state.dealerdata.stateddl,
        UserCityData: state.dealerdata.cityddl,
        List:
            state.dealerdata.exportitems == undefined
                ? []
                : state.dealerdata.exportitems,
        loading: state.dealerdata.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(AlldealerdataActions.getAll(obj));
        },
        loadingData: (isLoding) =>
            dispatch(AlldealerdataActions.loadingData(isLoding)),
        exportingData: (obj) => dispatch(AlldealerdataActions.exportData(obj)),
        getStateDDL: (data) => dispatch(AlldealerdataActions.getStateDDL(data)),
        getCityDDL: (data) => dispatch(AlldealerdataActions.getCityDDL(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllDealerDataRpt);
