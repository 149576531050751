import { AlldealerdataLogServices } from '../services';
import { AlldealerdataLogConstants } from '../constants';
import { alertActions } from '.';

export const AlldealerdataActions = {
  getAll,
  loadingData,
  exportData,
  getStateDDL,
  getCityDDL
};

function getStateDDL(data) {
  return (dispatch) => {
    dispatch(request());

    AlldealerdataLogServices.fetchStateDLL(data).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  }; function request() {
    return { type: AlldealerdataLogConstants.GETSTATEDDL_REQUEST };
  }
  function success(datas) {
    return { type: AlldealerdataLogConstants.GETSTATEDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: AlldealerdataLogConstants.GETSTATEDDL_FAILURE, error };
  }
}
function getCityDDL(data) {
  return (dispatch) => {
    dispatch(request());

    AlldealerdataLogServices.fetchCityDLL(data).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: AlldealerdataLogConstants.GETSCITYDDL_REQUEST };
  }
  function success(datas) {
    return { type: AlldealerdataLogConstants.GETSCITYDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: AlldealerdataLogConstants.GETSCITYDDL_FAILURE, error };
  }
}

function getAll(obj) {
  return dispatch => {
    dispatch(request());
    AlldealerdataLogServices.fetchdealerdataData(obj).then(response => {
      dispatch(loadingData(false));
      dispatch(success(response.ResponseData))

      var data = response.ResponseData;
      if (data != null) {
        data.forEach(function (v) { delete v.TotalRows; delete v.ProductId });
      }
      dispatch(successExport(data))
    },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }

    );
  };
  function request() { return { type: AlldealerdataLogConstants.GETALL_REQUEST } }
  function success(datas) { return { type: AlldealerdataLogConstants.GETALL_SUCCESS, datas } }
  function successExport(datas) { return { type: AlldealerdataLogConstants.GETEXPORT_SUCCESS, datas } }
  function failure(error) { return { type: AlldealerdataLogConstants.GETALL_FAILURE, error } }
}

function exportData(obj) {
  return dispatch => {
    dispatch(request());
    AlldealerdataLogServices.fetchdealerdataData(obj).then(response => {
      
      dispatch(loadingData(false));
      var data = response.ResponseData;
      if (data != null) {
        data.forEach(function (v) {
          delete v.TotalRows;
          delete v.AadharImage;
          delete v.AadharNo;
          delete v.ProductId;
          delete v.ProductName;
          delete v.QrData;
          delete v.VerificationMode;
          delete v.VerifiedBy;
          delete v.RoleName;
          delete v.StatusResult;
        });
      }
      dispatch(success(data))
    },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      });
  };
  function request() { return { type: AlldealerdataLogConstants.GETEXPORT_REQUEST } }
  function success(datas) { return { type: AlldealerdataLogConstants.GETEXPORT_SUCCESS, datas } }
  function failure(error) { return { type: AlldealerdataLogConstants.GETEXPORT_FAILURE, error } }
}

function loadingData(isbool) {
  return {
    type: AlldealerdataLogConstants.LOADING_REQUEST, isbool
  }
}
