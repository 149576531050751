import { ProductRatingRptConstants } from "../constants";

export function ProductRatingRpt(state = {}, action) {
  switch (action.type) {
    case ProductRatingRptConstants.LOADING_REQUEST:
      return {
        ...state,
        loading: action.isbool,
      };
    case ProductRatingRptConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ProductRatingRptConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case ProductRatingRptConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ProductRatingRptConstants.GETEXPORT_REQUEST:
      return {
        ...state,
        exporting: true,
      };
    case ProductRatingRptConstants.GETEXPORT_SUCCESS:
      return {
        ...state,
        exportitems: action.datas,
      };
    case ProductRatingRptConstants.GETEXPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
