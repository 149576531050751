import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            
            <div className="row footer"> 
                <div className="col-md-6 text-left text-white">Copyright 2024 @AV Wood Coating. All Rights Reserved</div>
                <div className="col-md-6 text-right">Design & Developed by <a target="_blank" style={{color:"#00fff3"}} href="https://www.holostik.com">  Holostik India Limited</a></div>
            </div>
        )
    }
}