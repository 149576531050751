import { issuePageService } from "../services/issuePageService";
import { issuePageConstants } from "../constants/issuePageConstants";
import { alertActions } from ".";

export const issuePageActions = {
  getAll,
  InsertIssueData,
  openDialog,
  onHideShow,
  loadingData,
  onHideShowIsActive,
  getDistributorDDL,
  UploadIssusePage,
  onHideShowError
};

function getAll(data) {
  return (dispatch) => {
    dispatch(request());
    issuePageService.GetIssueData(data).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: issuePageConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: issuePageConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: issuePageConstants.GETALL_FAILURE, error };
  }
}

function InsertIssueData(data, obj) {
  return (dispatch) => {
    dispatch(request(data));
    issuePageService.InsertIssueData(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(getAll(obj));
            dispatch(success(response.ResponseData));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: issuePageConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: issuePageConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: issuePageConstants.CREATE_FAILURE, error };
  }
}

function onHideShow(isbool) {
  return {
    type: issuePageConstants.DIALOG_REQUEST,
    isbool,
  };
}


function onHideShowError(isbool) {
  
  return {
    type: issuePageConstants.ERROR_DIALOG_REQUEST,
    isbool,
  };
}

// function onHideShow(isbool) {
//   return {
//     type: issuePageConstants.DIALOG_REQUEST,
//     isbool,
//   };
// }
function loadingData(isbool) {
  return {
    type: issuePageConstants.LOADING_REQUEST,
    isbool,
  };
}

function onHideShowIsActive(isbool) {
  ;
  return {
    type: issuePageConstants.DIALOG_ISACTIVE_REQUEST,
    isbool,
  };
}

function openDialog(data) {
  return {
    type: issuePageConstants.OPEN_DIALOG_REQUEST,
    data,
  };
}

function getDistributorDDL() {
  return (dispatch) => {
    dispatch(request());
    issuePageService.getDistributorDDL().then(
      (response) => {
        // if (flag == 1) {
        //   response.ResponseData.splice(0, 0, { label: "All", value: "0" });
        // }
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: issuePageConstants.GETDEALERDDL_REQUEST };
  }
  function success(datas) {
    return { type: issuePageConstants.GETDEALERDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: issuePageConstants.GETDEALERRDDL_FAILURE, error };
  }
}

function UploadIssusePage(formData, tblResponse) {
  return (dispatch) => {
    dispatch(request());
    issuePageService.UploadIssusePage(formData).then(
      (response) => {
        dispatch(success(response));
        if (response.ResponseCode == "200") {
          dispatch(alertActions.success(response.ResponseMessage.toString()));
          dispatch(getAll(tblResponse));
        } else if (response.ResponseCode == "201") {
          dispatch(alertActions.warning(response.ResponseMessage.toString()));
          dispatch(partial_success(response.ResponseData));
          dispatch(getAll(tblResponse));
        } else
          dispatch(alertActions.error(response.ResponseMessage.toString()));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: issuePageConstants.UPLOAD_REQUEST };
  }
  function success(datas) {
    return { type: issuePageConstants.UPLOAD_SUCCESS, datas };
  }
  function partial_success(datas) {
    return { type: issuePageConstants.UPLOAD_PARTIAL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: issuePageConstants.UPLOAD_FAILURE, error };
  }
}
