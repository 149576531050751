export const LoyaltyRedemptionConstants = {

    GETALL_REQUEST: 'LOYALTY_GETALL_REQUEST',
    GETALL_SUCCESS: 'LOYALTY_GETALL_SUCCESS',
    GETALL_FAILURE: 'LOYALTY_GETALL_FAILURE',


    CREATE_REQUEST: 'LOYALTY_CREATE_REQUEST',
    CREATE_SUCCESS: 'LOYALTY_CREATE_SUCCESS',
    CREATE_FAILURE: 'LOYALTY_CREATE_FAILURE',

    LOADING_REQUEST:'LOADING_REQUEST'
};