export const dealerConstants = {
    CREATE_REQUEST: 'DEALERS_CREATE_REQUEST',
    CREATE_SUCCESS: 'DEALERS_CREATE_SUCCESS',
    CREATE_FAILURE: 'DEALERS_CREATE_FAILURE',

    UPDATE_REQUEST: 'DEALERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'DEALERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'DEALERS_UPDATE_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    GETCOUNTRYDDL_REQUEST: 'COUNTRY_DDL_REQUEST',
    GETCOUNTRYDDL_SUCCESS: 'COUNTRY_DDL_SUCCESS',
    GETCOUNTRYDDL_FAILURE: 'GETCOUNTRYDDL_FAILURE',

    GETSTATEDDL_REQUEST: 'GETSTATEDDL_REQUEST',
    GETSTATEDDL_SUCCESS: 'GETSTATEDDL_SUCCESS',
    GETSTATEDDL_FAILURE: 'GETSTATEDDL_FAILURE',

    GETFIRMDDL_REQUEST: 'FIRM_DDL_REQUEST',
    GETFIRMDDL_SUCCESS: 'FIRM_DDL_SUCCESS',
    GETFIRMDDL_FAILURE: 'GETFIRMDDL_FAILURE',

    GETSDISTRICTDDL_REQUEST: 'GETSDISTRICTDDL_REQUEST',
    GETSDISTRICTDDL_SUCCESS: 'GETSDISTRICTDDL_SUCCESS',
    GETSDISTRICTDDL_FAILURE: 'GETSDISTRICTDDL_FAILURE',

    GETBCITYDDL_REQUEST: 'GETBCITYDDL_REQUEST',
    GETBCITYDDL_SUCCESS: 'GETBCITYDDL_SUCCESS',
    GETBCITYDDL_FAILURE: 'GETBCITYDDL_FAILURE',
    
    ISACTIVE_REQUEST: 'USER_ISACTIVE_REQUEST',
    ISACTIVE_SUCCESS: 'USER_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'USER_ISACTIVE_FAILURE',

    GETEXPORT_REQUEST: 'DEALER_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'DEALER_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'DEALER_GETEXPORT_FAILURE',
    
    DIALOG_REQUEST:'DIALOG_REQUEST',

    LOADING_REQUEST:'LOADING_REQUEST',

    DIALOG_ISACTIVE_REQUEST:'DIALOG_ISACTIVE_REQUEST',

    GET_TRANSPORTATION_REQUEST: 'GET_TRANSPORTATION_REQUEST',
    GET_TRANSPORTATION_SUCCESS: 'GET_TRANSPORTATION_SUCCESS',
    GET_TRANSPORTATION_FAILURE: 'GET_TRANSPORTATION_FAILURE',
};