export const userConstants = {
    CREATE_REQUEST: 'USERS_CREATE_REQUEST',
    CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
    CREATE_FAILURE: 'USERS_CREATE_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    GETROLEDDL_REQUEST: 'ROLE_DDL_REQUEST',
    GETROLEDDL_SUCCESS: 'ROLE_DDL_SUCCESS',
    GETROLEDDL_FAILURE: 'GETROLEDDL_FAILURE',
    
    ISACTIVE_REQUEST: 'USER_ISACTIVE_REQUEST',
    ISACTIVE_SUCCESS: 'USER_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'USER_ISACTIVE_FAILURE',

    GETEXPORT_REQUEST: 'GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'GETEXPORT_FAILURE',
    
    DIALOG_REQUEST:'DIALOG_REQUEST',

    LOADING_REQUEST:'LOADING_REQUEST',

    DIALOG_ISACTIVE_REQUEST:'DIALOG_ISACTIVE_REQUEST'
};