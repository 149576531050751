import { authHeader, authHeaderFormData, handleResponse } from "../helpers";
export const loyalityschemeService = {
  createScheme,
  UpdateScheme,
  fetchSchemeData,
  inActiveRow,
  UploadSchemeMapping,
  fetchListDataDLL,
  GetZoneWisePointData,
};

function createScheme(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/CreateScheme`,
    requestOptions
  ).then(handleResponse);
}

function UpdateScheme(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/CreateScheme`,
    requestOptions
  ).then(handleResponse);
}

function fetchSchemeData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/GetSchemes`,
    requestOptions
  ).then(handleResponse);
}
function GetZoneWisePointData(StateId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  // Perform the fetch request
  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/GetPointsListOnStatewise/${StateId}`,
    requestOptions
  ).then(handleResponse)
}



function inActiveRow(SchemeId, isActive) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/InActiveRow/${SchemeId}/${isActive}`,
    requestOptions
  ).then(handleResponse);
}


function UploadSchemeMapping(formData) {
  ;
  const requestOptions = {
    method: "POST",
    headers: authHeaderFormData(),
    body: formData,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/UploadSchemeMapping`,
    requestOptions
  ).then(handleResponse);
}

function fetchListDataDLL() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/GetListDataDLL`,
    requestOptions
  ).then(handleResponse);
}
