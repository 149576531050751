import React, { Component } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { connect } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import TextField from "@material-ui/core/TextField";
import { DealerStockInvoiceActions } from "../../actions/DealerStockInvoiceActions.js";
import { alertActions } from "../../actions/alertActions.js";

class DealerStockInvoice extends React.Component {
    constructor(props) {
        super(props);
        this.autoref = React.createRef();
        this.tblResponse = new tableRequest();
        this.state = {
            remarks: "",
            InvoiceNumber: "",
            globalFilter: null,
            visible: false,
            sortIcon: false,
            productLoading: true,
            dealerLoading: false,
            sortOrder: "1",
            formError: {
                dealer: "",
                InvoiceNumber: "",
                remarks: ""
            },
            DealerDDL: [],
            dealer: '',
            buttonLabel: "Submit",
            sales: [],
            scanItems: [],
            loading: true,
            first: 0,
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            emptyMessage: "No Data found",
            isScanning: false,
            scanTxt: "",
        };

        this.actionTemplate = this.actionTemplate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitFormFinal = this.submitFormFinal.bind(this);
        this.onPage = this.onPage.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.onSort = this.onSort.bind(this);
        this.confirmSubmit = this.confirmSubmit.bind(this);
        this.ScanItem = this.ScanItem.bind(this);
    }
    //trigger api function on page call
    componentDidMount() {
        this.tblResponse.SortColumn = "CreatedDt";
        setTimeout(() => {
            this.props.GetDealerInvoicing(this.tblResponse);
        }, 100);
        this.props.getDealerDDL();
    }

    componentDidUpdate(prevProps) {
        if (this.props.stockItems) {
            if (prevProps.stockItems !== this.props.stockItems) {
                // this.props.myProp has a different value
                if (this.props.stockItems) {
                    var e = this.props.stockItems;
                    this.setState({
                        totalRecords: e[0].TotalRows,
                    });
                }
            }
        } else {
            if (this.state.totalRecords !== 0)
                this.setState({
                    totalRecords: 0,
                    first: 0,
                });
        }
    }

    // PAGE EVENT CALL
    onPage(event) {
        this.setState({ first: event.first, rows: event.rows });
        this.getLazyLoadingValues(event);
        this.props.GetDealerInvoicing(this.tblResponse);
    }

    // set lazy loading values
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    }

    handleChange(event) {
        const { name, value } = event.target;
        console.log(`Field changed: ${name}, Value: ${value}`); // Log for debugging
        this.setState({ [name]: value });
    }

    // reset form text value
    formReset() {
        this.setState({
            isScanning: false,
            Tvisible: false,
            ReferenceID: "",
            InvoiceNumber: "",
            remarks: "",
            rvisible: false,
            IsActive: true,
            dealer: '',
        });
        let errors = this.state.formError;
        Object.entries(errors).map(([key, value]) => {
            errors[key] = "";
        });
    }

    // open Dialog
    openDialog() {
        this.setState({ visible: true });
        this.formReset();
    }



    // submit stock receiving
    submitFormFinal = (event) => {

        // Check if the function is being called directly without event
        if (event) {
            event.preventDefault(); // Prevent default only if event exists
        }
        if (!this.state.dealer || !this.state.InvoiceNumber || !this.state.remarks) {
            this.props.showWarn("Please fill all required fields");
            return;
        }
        // Check if scanItems exist or are not empty
        if (!this.props.scanItems || this.props.scanItems.length === 0) {
            this.props.showWarn("Scan First");
            return;
        }

        this.setState({ productLoading: true }); // Set loading to true to indicate submission process
        console.log("Current State before submit:", this.state);
        const formData = {
            dealerStockDetails: this.props.scanItems,
            remarks: this.state.remarks,
            DealerId: this.state.dealer,
            InvoiceNo: this.state.InvoiceNumber,
        };

        console.log("FormDATA: ", formData);

        // Simulate form submission process (you can replace with an actual async call)
        setTimeout(() => {
            // Once the form is "submitted"
            this.setState({ productLoading: false });
            this.props.saveDealerInvoicing(formData);
            // Clear the form
            this.setState({ remarks: "" });
            this.formReset();

            // Focus back on the scan field if ref is available
            if (this.autoref && this.autoref.current) {
                this.autoref.current.focus();
            }
        }, 100);
    };

    // scan qr code
    ScanItem(e) {

        if (e.key === "Enter") {

            const { InvoiceNumber, dealer, remarks } = this.state;
            if (!InvoiceNumber) {
                this.props.showWarn("Enter the Invoice Number");
                return;
            }

            if (!dealer) {
                this.props.showWarn("Please Select Dealer");
                return;
            }
            if (!remarks) {
                this.props.showWarn("Enter the remarks");
                return;
            }
            this.setState({ isScanning: true });
            let qr = e.target.value;

            if (
                qr.includes("avwoods") ||
                qr.includes("localhost") ||
                qr.includes("104")
            ) {
                const splitQr = qr.split("=");
                qr = splitQr[1] === undefined ? qr : splitQr[1];
            }
            const fObj = { QrData: qr };
            this.props.GetProductByQrScan(fObj, this.props.scanItems);
            this.setState({
                scanTxt: "",
            });
            e.target.value = "";
            setTimeout(() => {
                this.setState({ isScanning: false });
            }, 9000);
        }
    }

    // delete user data
    deleteScanned(SeqNo) {
        console.log(this.props.scanItems);
        let arrayfilter = this.props.scanItems.filter((x) => x.SeqNo != SeqNo);
        this.setState({
            scanItems: arrayfilter
        });
        console.log(this.props.scanItems);
    }

    // seach table
    searchFilter(e) {
        this.props.loadingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.GetDealerInvoicing(this.tblResponse);
    }

    callbackFunction = (childData) => {
        this.setState({ message: childData });
    };

    // disabled checkbox
    activeTemplate(rowData, column) {
        return <input type="checkbox" disabled checked={rowData.IsActive} />;
    }

    // Sort table
    onSort(event) {
        this.props.loadingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField =
            event.multiSortMeta[0].field === "CreatedDate"
                ? "CreatedDate"
                : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.GetDealerInvoicing(this.tblResponse);
    }

    confirmSubmit = () => {
        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.submitFormFinal(),
                },
                {
                    label: "No",
                    onClick: () => null,
                },
            ],
        });
    };

    // edit form
    actionTemplate(rowData, column) {
        const deleteFunc = () => {
            confirmAlert({
                title: "Confirm to delete",
                message: "Are you sure to do this.",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => this.props.removeItem(rowData.SeqNo),
                    },
                    {
                        label: "No",
                        onClick: () => null,
                    },
                ],
            });
        };

        return (
            <span>
                <Button
                    type="button"
                    icon="pi pi-trash"
                    onClick={deleteFunc}
                    className="p-button-danger"
                ></Button>
            </span>
        );
    }

    // create html part
    render() {
        let header = (
            <div className="row align-items-center py-2">
                {/* Header Title */}
                <div className="col-md-2">
                    <h4>Stock Invoicing</h4>
                </div>

                {/* Invoice Number Input */}
                <div className="col-md-3">
                    <div className="form-group mb-0">
                        <TextField
                            onChange={this.handleChange}
                            name="InvoiceNumber"
                            autoComplete="false"
                            value={this.state.InvoiceNumber}
                            size="small"
                            variant="outlined"
                            className="fullwidth"
                            label="Invoice Number*"
                            disabled={this.state.isScanning}
                        />
                    </div>
                </div>

                {/* Dealer Dropdown */}
                <div className="col-md-3">
                    <div className="form-group mb-0 pl-3">
                        <Dropdown
                            onChange={this.handleChange}
                            name="dealer"
                            options={this.props.DealerDDL}
                            optionLabel="label"
                            optionValue="value"
                            value={this.state.dealer}
                            filter
                            className="form-control custom-select"
                            placeholder="Select Dealer*"
                            disabled={this.state.isScanning}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group mb-0">
                        <TextField
                            onChange={this.handleChange}
                            name="remarks"
                            autoComplete="false"
                            value={this.state.remarks}
                            size="small"
                            variant="outlined"
                            className="fullwidth"
                            label="Remarks*"
                            disabled={this.state.isScanning}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group mb-0">
                        <TextField
                            name="scanTxt"
                            ref={this.autoref}
                            autoComplete="false"
                            value={this.state.scanTxt}
                            type="search"
                            onChange={(e) => this.handleChange(e)}
                            id="scanTxt"
                            onKeyDown={(e) => this.ScanItem(e)}
                            size="small"
                            variant="outlined"
                            className="fullwidth"
                            label="Scan items*"
                        />
                    </div>
                </div>
            </div>
        );


        let header2 = (
            <div className="row">
                <div className="col-md-4 text-left">
                    <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
                    <InputText
                        type="search"
                        onInput={this.searchFilter}
                        placeholder="Global Search"
                        size="30"
                    />
                </div>
            </div>
        );

        return (
            <div className="datatable-filter-demo">
                <div className="card">
                    <div>
                        <DataTable
                            responsive={true}
                            header={header}
                            emptyMessage={this.state.emptyMessage}
                            value={this.props.scanItems}
                            paginator={true}
                            currentPageReportTemplate="Total Items Added: {totalRecords}, Showing {first} to {last}"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            sortMode="multiple"
                            totalRecords={this.state.scanItems.length}
                            rows={this.state.rows}
                            className="tblAuto"
                            globalFilter={this.state.globalFilter}
                        >
                            <Column field="SeqNo" header="Visible No" sortable={true} />
                            <Column field="GroupName" header="Group Name" sortable={true} />
                            <Column
                                field="ProductName"
                                header="Product Name"
                                sortable="custom"
                            />
                            <Column body={this.actionTemplate} header="Action" />
                        </DataTable>
                        <div className="col-md-12 text-center mt-4 mb-4">
                            <button className="btn btn-primary" onClick={this.confirmSubmit}>
                                Submit
                            </button>
                        </div>
                        <div className="col-md-12 p-0">
                            <DataTable
                                value={this.props.stockItems}
                                scrollable
                                responsive={true}
                                globalFilter={this.state.globalFilter}
                                header={header2}
                                emptyMessage="No data found."
                                sortMode="multiple"
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                rows={this.state.rows}
                                style={{ width: "100%" }}
                                rowsPerPageOptions={[5, 10, 20, 50]}
                                totalRecords={parseInt(this.state.totalRecords)}
                                lazy={true}
                                first={this.state.first}
                                onPage={this.onPage}
                                onSort={this.onSort}
                                loading={this.props.loading}
                            >
                                {/* <Column field="Id" headerStyle={{ width: '140px' }} header="Id" sortable={true} /> */}
                                <Column
                                    field="InvoiceNo"
                                    headerStyle={{ width: "250px" }}
                                    header="Invoice Number"
                                    sortable={true}
                                />
                                <Column
                                    field="DealerName"
                                    headerStyle={{ width: "250px" }}
                                    header="Dealer Name"
                                    sortable={true}
                                />
                                <Column
                                    field="TotalQty"
                                    headerStyle={{ width: "250px" }}
                                    header="Total Qty"
                                    sortable={true}
                                />
                                <Column
                                    field="remarks"
                                    headerStyle={{ width: "250px" }}
                                    header="Remarks"
                                    sortable={true}
                                />
                                <Column
                                    field="CreatedDt"
                                    headerStyle={{ width: "250px" }}
                                    header="Created Date"
                                    sortable={true}
                                />
                            </DataTable>

                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log("DDL: ", state.DealerStock.dealerddl),
        console.log("scannedItems: ", state.DealerStock?.scanneditems)
    console.log("Get Invoicing: ", state.DealerStock?.stockItems)
    return {
        DealerDDL: state.DealerStock.dealerddl,
        scanItems: state.DealerStock?.scanneditems,
        stockItems: state.DealerStock?.stockItems,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDealerDDL: () => {
            dispatch(DealerStockInvoiceActions.getDealerDDL());
        },
        GetProductByQrScan: (QrData, scanneditems) => {
            dispatch(
                DealerStockInvoiceActions.getProductByQrScan(QrData, scanneditems));
        },
        removeItem: (data) => dispatch(DealerStockInvoiceActions.removeItem(data)),
        showWarn: (message) => dispatch(alertActions.warning(message)),
        saveDealerInvoicing: (formData) =>
            dispatch(DealerStockInvoiceActions.saveDealerInvoicing(formData)),
        GetDealerInvoicing: (tblObj) =>
            dispatch(DealerStockInvoiceActions.GetDealerInvoicing(tblObj)),
        loadingData: (isLoding) => dispatch(DealerStockInvoiceActions.loadingData(isLoding)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DealerStockInvoice);
