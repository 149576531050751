import { DealerStockInvoiceConstants } from '../constants';

export function DealerStock(state = {}, action) {
    switch (action.type) {
        case DealerStockInvoiceConstants.GETDEALERDDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case DealerStockInvoiceConstants.GETDEALERDDL_SUCCESS:
            return {
                ...state,
                dealerddl: action.datas
            };
        case DealerStockInvoiceConstants.GETDEALERDDL_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case DealerStockInvoiceConstants.ProductByQrScan_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DealerStockInvoiceConstants.ProductByQrScan_SUCCESS:
            return {
                ...state,
                scanneditems:
                    state.scanneditems == null
                        ? [action.data]
                        : [action.data, ...state.scanneditems],
            };
        case DealerStockInvoiceConstants.ProductByQrScan_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case DealerStockInvoiceConstants.ProductByQrScan_Warning:
            return {
                ...state,
                warning: action.data == "no data found" ? true : false,
                alreadyReceived: action.data != "no data found" ? true : false,
            };
        case DealerStockInvoiceConstants.UPDATE_SCANNED_REQUEST:
            return {
                ...state,
                scanneditems: state.updatedList,
            };
        case DealerStockInvoiceConstants.SAVE_STOCK_INVOICE_RESUEST:
            return {
                ...state,
                saveing: true,
            };
        case DealerStockInvoiceConstants.SAVE_STOCK_INVOICE_SUCCESS:
            return {
                ...state,
                secondaryItems:
                    state.secondaryItems == null
                        ? [action.data]
                        : [action.data, ...state.secondaryItems],
                scanneditems: undefined,
            };
        case DealerStockInvoiceConstants.SAVE_STOCK_INVOICE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case DealerStockInvoiceConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DealerStockInvoiceConstants.GETALL_SUCCESS:
            return {
                ...state,
                stockItems: action.data,
            };
        case DealerStockInvoiceConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case DealerStockInvoiceConstants.REMOVE_ITEM:
            return {
                ...state,
                scanneditems:
                    state.scanneditems.filter((x) => x.SeqNo != action.data).length == 0
                        ? undefined
                        : state.scanneditems.filter((x) => x.SeqNo != action.data),
            };
        case DealerStockInvoiceConstants.Warning:
            return {
                ...state,
                warning: action.isbool,
            };
        case DealerStockInvoiceConstants.AlreadyReceived:
            return {
                ...state,
                alreadyReceived: action.isbool,
            };
        default:
            return state
    }
}
