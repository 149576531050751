export const DealerStockInvoiceConstants = {
    GETDEALERDDL_REQUEST: 'DEALERS_GETDEALERDDL_REQUEST',
    GETDEALERDDL_SUCCESS: 'DEALERS_GETDEALERDDL_SUCCESS',
    GETDEALERDDL_FAILURE: 'DEALERS_GETDEALERDDL_FAILURE',

    ProductByQrScan_REQUEST: "ProductByQrScan_REQUEST",
    ProductByQrScan_SUCCESS: "ProductByQrScan_SUCCESS",
    ProductByQrScan_FAILURE: "ProductByQrScan_FAILURE",
    ProductByQrScan_Warning: "ProductByQrScan_Warning",


    UPDATE_SCANNED_REQUEST: "UPDATE_SCANNED_REQUEST",

    SAVE_STOCK_INVOICE_RESUEST: "SAVE_STOCK_INVOICE_RESUEST",
    SAVE_STOCK_INVOICE_SUCCESS: "SAVE_STOCK_INVOICE_SUCCESS",
    SAVE_STOCK_INVOICE_FAILURE: "SAVE_STOCK_INVOICE_FAILURE",

    GETALL_REQUEST: "STOCK_INVOICE_GETALL_REQUEST",
    GETALL_SUCCESS: "STOCK_INVOICE_GETALL_SUCCESS",
    GETALL_FAILURE: "STOCK_INVOICE_GETALL_FAILURE",

    REMOVE_ITEM: "REMOVE_ITEM",

    LOADING_REQUEST: "STOCK_INVOICE_LOADING_REQUEST",

    Warning: "Warning",
    AlreadyReceived: "AlreadyReceived",
};