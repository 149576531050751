import React, { Component } from "react";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { dashboardActions, mappingActions } from "../actions";
import tableRequest from "../models/tableRequest.js";
import ReactApexChart from "react-apexcharts";
import { Sidebar } from "primereact/sidebar";

import { MDBContainer } from "mdbreact";
import { HorizontalBar } from "react-chartjs-2";

import "./Sidebar.css";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    // horizontal bar chart

    this.tblResponse = new tableRequest();
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.state = {
      // Initialize state to manage the visibility of the card
      isCardVisible: false,
      globalFilter: null,
      first: 0,
      rows: 5,
      totalRecords: 0,
      sortOrder: "",
      loading: false,
      visibleRight: false,
      series: [
        {
          name: "Desktops",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      options: {
        chart: {
          height: 117,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        // title: {
        //   text: "Product Trends by Month",
        //   align: "left",
        // },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
          ],
        },
      },
    };
  }

  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        }
      }
    }
    this.props.fetchApi(this.tblResponse);
    //this.props.fetchMappingApi(this.tblResponse);
  }

  componentDidUpdate(prevProps) {
    if (this.props.MappingData) {
      if (prevProps.MappingData !== this.props.MappingData) {
        // this.props.myProp has a different value
        if (this.props.MappingData) {
          var e = this.props.MappingData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      ;
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }

    if (prevProps.MaxFromNo !== this.props.MaxFromNo) {
      this.setState({
        FromNo: this.props.MaxFromNo,
      });
    }
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    //this.props.fetchMappingApi(this.tblResponse);
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    //this.props.fetchMappingApi(this.tblResponse);
    this.props.fetchApi(this.tblResponse);
  }

  onPage(event) {
    //imitate delay of a backend call
    this.getLazyLoadingValues(event);
    //this.props.fetchMappingApi(this.tblResponse);
    this.props.fetchApi(this.tblResponse);
  }
  // Function to toggle the visibility
  toggleCardVisibility = () => {
    this.setState((prevState) => ({
      isCardVisible: !prevState.isCardVisible,
    }));
  };
  render() {
    // Sample data
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",

        "December",
      ],
      datasets: [
        {
          label: "Dealerwise Stock",
          data: [2, 5, 7, 9, 7, 6, 4, 5, 7, 9, 7, 6],
          fill: true,
          backgroundColor: "#156082",
          borderColor: "#156082",
        },
      ],
    };

    const chartdata = {
      labels: this.props.Months,
      datasets: [
        {
          label: "Product Mapping",
          data: this.props.MappingChart,
          fill: false,
          borderColor: "#4bc0c0",
        },
        {
          label: "Product De-Mapping",
          data: this.props.DeMappingChart,
          fill: false,
          borderColor: "#9bc0c9",
        },
      ],
    };

    // setVisibleRight = (visible) => {
    //     this.setState({ visibleRight: visible });
    // }

    return (
      <main className="">
        <div className="box-shadowe box-space">
          <div className="containter-fluid">
            <div className="row">
              <div className="col-md-4">
                <div className="cards">
                  <h2>Top Product Scans</h2>
                  <Chart
                    type="doughnut"
                    data={{
                      labels: this.props.ProductNames,
                      datasets: [
                        {
                          // label: "Products",
                          data: this.props.ScanCounts,
                          backgroundColor: [
                            "#a86464",
                            "#98d1d1",
                            "#3c4e4b",
                            "#e27c7c",
                            "#d2980d",
                            "#df979e",
                            "#d7658b",
                            "#c80064",
                          ],
                          hoverOffset: 4,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="cards">
                  <h2>Mappings Trends</h2>
                  <Chart
                    type="bar"
                    data={{
                      labels: this.props.Months,
                      datasets: [
                        {
                          label: "Mappings",
                          data: this.props.MappingChart,
                          backgroundColor: [
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                          ],
                          borderColor: [
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                            "#196b24",
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="cards">
                  <h2>Top Rated Product</h2>
                  <Chart
                    type="pie"
                    data={{
                      labels: this.props.ProductNames,
                      datasets: [
                        {
                          // label: "Products",
                          data: this.props.ScanCounts,
                          backgroundColor: [
                            "#1c62ae",
                            "#fff500",
                            "#3c4e4b",
                            "#e27c7c",
                            "#d2980d",
                            "#df979e",
                            "#d7658b",
                            "#c80064",
                          ],
                          hoverOffset: 4,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
             
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="cards" style={{ height: "225px" }}>
                  <h2>Dealerwise Stock</h2>
                  <MDBContainer>
                    <HorizontalBar data={data} />
                  </MDBContainer>
                </div>
              </div>
              <div className="col-md-4">
                <div className="cards" style={{ height: "225px" }}>
                  <h2>Top loyality Achiever</h2>
                  <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height={117}
                  />
                </div>
              </div>
              <div className="col-md-4 close_card">
                <div className="cards" style={{ height: "225px" }}>
                  <div className="container-fluid">
                  <a href="activation/issue-number">
                    <div className="row">
                      <div className="col-md-2 n_iconscol">
                        <i class="bi bi-list-ol" />
                      </div>
                      <div className="col-md-8 n_issuehcol">Number Issues</div>
                      <div className="col-md-2 n_issuencol">
                        <h4>
                          {" "}
                          {this.props.TotalIssue ? this.props.TotalIssue : 0}
                        </h4>
                      </div>
                    </div>
                    </a>

                    <a href=" transactions/mapping">
                    <div className="row">
                      <div className="col-md-2 mapping_iconscol">
                        <i className="fa fa-cube" />
                      </div>
                      <div className="col-md-8 n_issuehcol">Mapping</div>
                      <div className="col-md-2 mapping_ncol">
                        <h4>
                          {" "}
                          {this.props.TotalMapping
                            ? this.props.TotalMapping
                            : 0}
                        </h4>
                      </div>
                    </div>
                    </a>

                     <a href="/transactions/de-mapping">       
                    <div className="row">
                      <div className="col-md-2 demapping_iconscol">
                        <i className="fa fa-cubes" />
                      </div>
                      <div className="col-md-8 n_issuehcol">De Mapping</div>
                      <div className="col-md-2 demapping_ncol">
                        <h4>
                          {" "}
                          {this.props.TotalDeMapping
                            ? this.props.TotalDeMapping
                            : 0}
                        </h4>
                      </div>
                    </div>
                  </a>
                    {/* <div className="row">
                      <div className="col-md-2 userapproval_iconscol">
                        <i className="fa fa-user" />
                      </div>
                      <div className="col-md-8  n_issuehcol">
                        User Approval Pending{" "}
                      </div>
                      <div className="col-md-2 userapproval_ncol">
                        <h4>
                          {" "}
                          {this.props.TotalDeMapping
                            ? this.props.TotalDeMapping
                            : 0}
                        </h4>
                      </div>
                    </div> */}

                    <a href="transactions/damage">  
                    <div className="row">
                      <div className="col-md-2 damage_iconscol">
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="col-md-8 n_issuehcol">Damage</div>
                      <div className="col-md-2 damage_ncol">
                        <h4>
                          {this.props.TotalDamage ? this.props.TotalDamage : 0}
                        </h4>
                      </div>
                    </div>
                    </a>

                    <div className="row mt-1">
                      <div className="col-md-2 ts_iconscol ">
                        <i className="fa fa-qrcode" />
                      </div>
                      <div className="col-md-8 n_issuehcol">Total Scans</div>
                      <div className="col-md-2 ts_ncol">
                        <h4>
                          {this.props.TotalScans ? this.props.TotalScans : 0}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    DashboardList: state.dashboard?.items?.DashboardListData,
    TotalMapping: state.dashboard?.items?.DashboardCount?.TotalMapping,
    TotalDeMapping: state.dashboard?.items?.DashboardCount?.TotalDeMapping,
    UserPendingApprovel:
      state.dashboard?.items?.DashboardCount?.UserPendingApprovel,
    TotalDamage: state.dashboard?.items?.DashboardCount?.TotalDamage,
    TotalScans: state.dashboard?.items?.DashboardCount?.TotalScans,
    TotalIssue: state.dashboard?.items?.DashboardCount?.TotalIssue,
    DeMappingChart: state.dashboard?.items?.DeMappingList,
    MappingChart: state.dashboard?.items?.MappingList,
    ProductNames: state.dashboard?.items?.ProductNames,
    ScanCounts: state.dashboard?.items?.ScanCounts,
    Months: state.dashboard?.items?.MonthName,
    MappingData: state.mapping.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(dashboardActions.fetchData(obj));
    },
    fetchMappingApi: (obj) => {
      dispatch(mappingActions.getAll(obj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
