import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import { Toast } from "primereact/toast";
import { loyalityschemeActions } from "../../actions/loyalityschemeAction";
import { dealerMasterActions } from "../../actions";
import { productMasterActions } from "../../actions";
import { userMasterActions } from "../../actions";
import tableRequest from "../../models/tableRequest";
import Switch from "@material-ui/core/Switch";
import { Dropdown } from "primereact/dropdown";
import Multiselect from "multiselect-react-dropdown";
import { Calendar } from "primereact/calendar";
import { Chart } from "primereact/chart";
import { alertActions } from "../../actions/alertActions.js";

var minDate = new Date();

class loyalityscheme extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.LocRef = React.createRef();
    this.tblResponse = new tableRequest();
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Availableseries = this.Availableseries.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onSelectState = this.onSelectState.bind(this);
    this.onRemoveState = this.onRemoveState.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.LocationSelectAll = this.LocationSelectAll.bind(this);
    this.getCityList = this.getCityList.bind(this);
    this.GetZoneWisePoint = this.GetZoneWisePoint.bind(this);
    // this.getDistrictList = this.getDistrictList.bind(this);
    this.onHidee = this.onHidee.bind(this);
    this.openDialogue = this.openDialogue.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
    this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.getRoleList = this.getRoleList.bind(this);
    this.UploadSchemeMapping = this.UploadSchemeMapping.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onPage1 = this.onPage1.bind(this);
    this.onSort1 = this.onSort1.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.submitseries = this.submitseries.bind(this);
    this.onChangeDDL = this.onChangeDDL.bind(this);
    this.onHideError = this.onHideError.bind(this);
    this.state = {
      selectedProduct: "",
      productId: "",
      IsAdd: true,
      IsEdit: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      first1: 0,
      rows1: 5,
      totalRecords1: 0,
      globalFilter: null,
      sortOrder: "",
      buttonLabel: "Submit",
      switchAction: false,
      formHeading: "Add Loyality Master",
      UserId: 0,
      RoleId: 0,
      ValidFrm: "",
      ValidTo: "",
      city: "",
      state: "",
      //UserTypeId: '',
      SelectedDynamicId: [],
      Mappseriesdata: [],
      selectedProduct: null,
      SchemeCategoryData: [
        { value: "1", label: "Loyalty Points" },
        // { value: "2", label: "Paytm" },
      ],

      UserRoleData: [
        { value: "2", label: "Contractor" },
        // { value: "2", label: "Paytm" },
      ],
      LoyaltyPointszonewise: [],
      productId: "",
      SchemeName: "",
      stateID: [],
      Schemedescription: "",
      SchemeCategory: "",
      //UserTypeId: '',
      product: "",
      category: "",
      Qty: 0,
      StartDate: new Date(),
      EndDate: new Date(),
      FromNo: 0,
      ToNo: 0,
      Status: "",
      loyalitypnt: "",
      IsActive: true,
      CreatedDate: null,
      UpdatedDate: null,
      isEditing: false,
      formError: {
       // productId: "",
        SchemeName: "",
        // Schemedescription: "",
       // stateID: [],
       // SchemeCategory: "",
        SelectedDynamicId: null,
        // StartDate: "",
        // EndDate: "",
        //FromNo: "",
        //UserTypeId: '',
        //product: "",
        category: "",
        //Qty: "",
        loyalitypnt: "",
        RoleId: "",
        ValidFrm: "",
        ValidTo: "",
        //city: "",
        state: "",
      },
    };
  }
  componentDidMount() {
    const userDetails = sessionStorage.getItem("user");
    const userdetailsObject = JSON.parse(userDetails);
    this.setState({
      UserId: userdetailsObject.UserId,
    });
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          ;
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    var date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 7);
    this.tblResponse.FromDate = date.toLocaleDateString();
    this.tblResponse.ToDate = new Date().toLocaleDateString();

    this.setState({
      StartDate: new Date(),
      EndDate: date,
    });

    this.setState({
      Mappseriesdata: this.props.Mappseries,
    });

    this.props.fetchApi(this.tblResponse);

    const userId = "2";
    this.props.getRoleDDL(userId);
    this.props.getCategoryDDL();
    this.props.getStateDDL(1);
  }

  componentDidUpdate(prevProps) {
    if (this.props.SchememappingData) {
      if (prevProps.SchememappingData !== this.props.SchememappingData) {
        // this.props.myProp has a different value
        if (this.props.SchememappingData) {
          var e = this.props.SchememappingData;
          if (e[0]) {
            this.setState({
              totalRecords: e[0].TotalRows,
            });
          }
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }

    if (this.props.Mappseriesdata) {
      if (prevProps.Mappseriesdata !== this.props.Mappseriesdata) {
        // this.props.myProp has a different value
        if (this.props.Mappseriesdata) {
          var e = this.props.Mappseriesdata;
          this.setState({
            totalRecords1: e[0].this.props.TotalRows1,
          });
        }
      }
    } else {
      if (this.state.totalRecords1 !== 0)
        this.setState({
          totalRecords1: 0,
          first1: 0,
        });
    }
    if (this.props.loyaltypoints != []) {
      if (prevProps.loyaltypoints !== this.props.loyaltypoints) {
        // this.props.myProp has a different value
        this.setState({
          SelectedDynamicId: this.props.loyaltypoints,
          stateID: this.props.loyaltypoints,
        });
      }
    } else {
      this.setState({
        SelectedDynamicId: [],
        stateID: []
      });
    }
  }

  switchToggleConfirm = (e) => {
    this.props.inActiveRow(this.state.SchemeId, this.state.switchAction);
    // setTimeout(() => {
    //   this.props.fetchApi(this.tblResponse);
    // }, 2000);
    // console.log("Toggle Confirm Clicked");
  };

  Availableseries(event) {
    const data = {
      RoleId: this.state.RoleId,
      ProductId: this.state.product,
    };
    setTimeout(() => {
      this.props.GetAvailableseries(data);
    }, 500);
    this.openDialogue();
  }

  onChangeDDL(e) {
    if (e.target.name == "product") {
      this.setState({ product: e.target.value });
      if (this.state.RoleId != "") {
        this.getFromNo(e.target.value, "p");
      }
    } else if (e.target.name == "RoleId") {
      this.setState({ RoleId: e.target.value });
      if (this.state.product != "") {
        this.getFromNo(e.target.value, "r");
      }
    }
  }

  getFromNo(value, name) {
    var data = "";
    if (name == "p") {
      data = {
        RoleId: this.state.RoleId,
        ProductId: value,
      };
    } else if (name == "r") {
      data = {
        RoleId: value,
        ProductId: this.state.product,
      };
    }
    this.props.GetAvailableseries(data);
    setTimeout(() => {
      this.setState({ FromNo: this.props.FromNo });
    }, 500);
  }

  handleChange(event) {

    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  getRoleList(event) {
    this.setState({ UserTypeId: event.target.value });
    this.props.getRoleDDL(event.target.value);
  }

  UpdatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    } else {
    }
  }

  CreatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
    }
  }

  validateForm(value, name) {
    debugger

    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "SchemeName":
        if (value.length < 1) {
          IsValid = false;
          errors.SchemeName = "Please enter Scheme name.";
        } else errors.SchemeName = "";
        break;
      case "SelectedDynamicId":
        if (value.length < 1) {
          IsValid = false;
          errors.SelectedDynamicId = "Please select zone";
        } else errors.SelectedDynamicId = "";
        break;
      // case "stateID":
      //   if (value.length < 1) {
      //     IsValid = false;
      //     errors.stateID = "Please select state.";
      //   } else errors.stateID = "",errors.stateID = "";
      //   break;
      case "state":
        if (value.length < 1) {
          IsValid = false;
          errors.state = "Please select state";
        } else {
          errors.state = "";
        }
        break;
      // case "city":
      //   if (value.length < 1) {
      //     IsValid = false;
      //     errors.SelectedDynamicId = "Please Select Zone";
      //   } else errors.SelectedDynamicId = "";
      //   break;
      // case "SchemeCategory":
      //   if (value && value.length < 1) {
      //     IsValid = false;
      //     errors.SchemeCategory = "Please enter Scheme Category.";
      //   } else errors.SchemeCategory = "";
      //   break;
      case "RoleId":
        if (!value) {
          IsValid = false;
          errors.RoleId = "Please select user role.";
        } else errors.RoleId = "";
        break;

      case "category":
        if (value.length < 1) {
          IsValid = false;
          errors.category = "Please select category.";
        } else errors.category = "";
        break;

      case "ValidFrm":
        if (value == null || !(value instanceof Date)) {
          IsValid = false;
          errors.ValidFrm = "Please select from date";
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          IsValid = false;
          errors.ValidFrm = "Please enter only date.";
        }
        //KREP-51
        else if (
          this.state.SchemeType == 0 &&
          Date.parse(value) < Date.parse(new Date(this.getCurrentDate()))
        ) {
          IsValid = false;
          errors.ValidFrm =
            "From date must be greater than or equal to today date.";
        } else if (
          value instanceof Date &&
          this.state.ValidTo instanceof Date
        ) {
          errors.ValidFrm = null;
          errors.ValidTo = null;

          const startDate = value;
          const endDate = this.state.ValidTo;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            IsValid = false;
            errors.ValidFrm = "From date must be less than to date.";
          }
        } else errors.ValidFrm = null;
        break;
      case "ValidTo":
        if (value == null || !(value instanceof Date)) {
          IsValid = false;
          errors.ValidTo = "Please select to date";
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          IsValid = false;
          errors.ValidTo = "Please enter only date.";
        } else if (
          Date.parse(value) < Date.parse(new Date(this.getCurrentDate()))
        ) {
          IsValid = false;
          errors.ValidTo =
            "To date must be greater than or equal to today date.";
        } else if (
          value instanceof Date &&
          this.state.ValidFrm instanceof Date
        ) {
          errors.ValidFrm = null;
          errors.ValidTo = null;

          const startDate = this.state.ValidFrm;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            IsValid = false;
            errors.ValidTo = "To date must be greater than from date.";
          }
        } else errors.ValidTo = null;

        break;

      // case "loyalitypnt":
      //   value = value === "" ? 0 : value;
      //   if (value === null || parseInt(value) < 1) {
      //     IsValid = false;
      //     errors.loyalitypnt = "Loyalty points must be greater than 0";
      //   } else errors.loyalitypnt = null;
      //   break;
      default:
        break;
    }

    return IsValid;
  } 

  getDropValueOfRole() {
    let roleName = "";
    this.props.UserRoleData.filter((x) => {
      if (x.value === this.state.RoleId.toString()) {
        roleName = x.label;
      }
    });

    return roleName;
  }

  getDropValueOfSchemeCategory() {
    let SchemeCategoryId = "";
    this.state.SchemeCategoryData.filter((x) => {
      if (x.value === this.state.SchemeCategory) {
        SchemeCategoryId = x.label;
      }
    });

    return SchemeCategoryId;
  }

  submitseries(event) {
    event.preventDefault();

    this.props.onHideShoww(false);
  }

  getStateList(event, type) {
    //this.handleChange(event)
    if (type == "B") {
      this.setState({ country: event.target.value });
      this.props.getStateDDL(event.target.value);
    }
  }
  getCityList(event, type, flag) {
    if (flag == "i") {
      if (type == "B") {
        this.setState({ state: event.target.value });
        this.props.getBCityDDL(event.target.value);
      }
    } else {
      if (type == "B") {
        this.setState({ state: event });
        this.props.getBCityDDL(event);
      }
    }
  }

  GetZoneWisePoint(event) {
    this.props.GetZoneWisePointList(event)
  }
  //

  // getDistrictList(event, type, flag) {
  //   if (flag == "i") {
  //     if (type == "D") {
  //       this.setState({ state: event.target.value });
  //       this.props.getDistrictDDL(event.target.value);
  //     }
  //   } else {
  //     if (type == "D") {
  //       this.setState({ state: event });
  //       this.props.getDistrictDDL(event);
  //     }
  //   }
  // }

  submitForm(event) {
    debugger;
    event.preventDefault();
    console.log("Form Data:", this.state);
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      console.log(`Validation Result for ${key}:`, valid);

      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid; 
    });
    
     // Check if Points are not null
  const selectedDynamicIds = this.state.SelectedDynamicId || [];
  const ZoneValid = selectedDynamicIds.every(item => item.label !== null && item.label !== '');
  const pointsValid = selectedDynamicIds.every(item => 
    item.hasOwnProperty('Points') && item.Points !== null && item.Points !== ''
  );
  if (!pointsValid) {
    this.props.showWarn("Please Enter Points Greater than 1");
    return;
   
  }
  if (!ZoneValid) {
    this.props.showWarn("Please Select Zone");
    return;
   
  }

    if (isfrmvalid) {
      const ddlValuRole = this.getDropValueOfRole();
      //const ddlValuSchemeCategory = this.getDropValueOfSchemeCategory();

      const formData = {
        SchemeId: this.state.SchemeId,
        // productId: this.state.productId,
        SchemeName: this.state.SchemeName,
        // stateID: this.state.stateID,
        DynamicIds: this.state.SelectedDynamicId,
        // Schemedescription: this.state.Schemedescription,
        SchemeCategory: this.state.SchemeCategory,
        SchemeBeneficiary: this.state.RoleId,
        // product: this.state.product,
        GroupId: this.state.category,
        ValidFrm: this.state.ValidFrm.toLocaleDateString(),
        ValidTo: this.state.ValidTo.toLocaleDateString(),
        // Status: this.state.Status,
        // loyalitypnt: this.state.loyalitypnt,
        IsActive: this.state.IsActive,
        CreatedDate:
          this.state.UserId > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.UserId > 0 ? this.getCurrentDateMMDDYYY() : null,
        RoleName: ddlValuRole != null ? ddlValuRole : "",
        TotalRows: this.state.totalRecords,
        // city: this.state.city,
        CreatedBy: this.state.UserId,
        stateID: this.state.state,
      };

      if (formData.SchemeId > 0) {
        this.props.updateScheme(formData);
        event.preventDefault();
        setTimeout(() => {
          this.props.fetchApi(this.tblResponse);
        }, 500);
      } else {
        if (this.props.SchememappingData !== null) {
          console.log(
            "SchememappingData is not null:",
            this.props.SchememappingData
          );
        }
        this.props.createScheme(formData);
        event.preventDefault();
        setTimeout(() => {
          this.props.fetchApi(this.tblResponse);
        }, 500);
      }

      if (!this.props.showDialog) {
        this.resetForm();
      }
    }
  }

  // getRoleDDL(event) {
  //     this.props.getRoleDDL();
  // }
  resetForm() {
    this.setState({
      productId: "",
      SchemeId: 0,
      FileName: "",
      SchemeName: "",
      stateID: [],
      Schemedescription: "",
      SchemeCategory: "",
      SelectedDynamicId: [],
      //UserTypeId: '',
      RoleId: "",
      product: "",
      category: "",
      Qty: 0,
      StartDate: "",
      EndDate: "",
      FromNo: 0,
      ToNo: 0,
      Status: "",
      loyalitypnt: "",
      ValidFrm: "",
      ValidTo: "",
      city: "",
      state: "",
      IsActive: true,
      formError: {
        productId: "",
        RoleId: "",
        // SchemeName: "",
        // Schemedescription: "",
        //SchemeCategory: "",
        stateID: [],
        //FromNo: "",
        //ToNo: "",
        // StartDate: "",
        // EndDate: "",
        Status: "",
        ValidFrm: "",
        ValidTo: "",
        //UserTypeId: '',
        product: "",
        category: "",
        //Qty: "",
        loyalitypnt: "",
        city: "",
        state: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  getLazyLoadingValues1(event) {
    this.setState({ rows1: event.rows, first1: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
    this.minDate = new Date(rowData.ValidFrm);
  }

  onPage1(event) {
    //this.props.loadingData(true);
    //imitate delay of a backend call
    // setTimeout(() => {
    //     this.getLazyLoadingValues1(event);
    //     this.props.GetAvailableseries(this.tblResponse);
    // }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  UploadSchemeMapping(event) {
    event.preventDefault();
    let isfrmvalid =
      document.querySelector('input[type="file"]').files &&
        document.querySelector('input[type="file"]').files[0] != null
        ? true
        : false;
    var file = document.querySelector('input[type="file"]').files[0];

    if (isfrmvalid) {
      ;
      var data = new FormData();
      data.append("Inputfile", file);
      data.append("CreatedBy", this.state.UserId);
      this.props.UploadSchemeMapping(data, this.tblResponse);
      setTimeout(() => {
        this.resetForm();
      }, 1000);
    } else {
    }
  }

  onSort1(event) {
    //this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    //this.props.GetAvailableseries(this.tblResponse);
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }
  openDialog() {
    this.resetForm();
    this.props.onHideShow(true);
    this.setState({
      buttonLabel: "Submit",
      formHeading: "Scheme Master",
      isEditing: false,
    });
  }
  onHidee() {
    this.props.onHideShoww(false);
  }

  openDialogue() {
    this.props.onHideShoww(true);
    this.setState({ buttonLabel: "Submit", formHeadingg: "Available Series" });
    //this.props.GetAvailableseries(this.tblResponse);
  }
  onRowSelect(event) {
    //this.toast.show({ severity: 'info', summary: 'Series Selected', detail: `FromNo: ${event.data.FromNo}`, detail: `ToNo: ${event.data.ToNo}`, life: 3000 });
    this.setState({
      FromNo: event.data.FromNo,
      ToNo: event.data.ToNo,
      Qty: event.data.ToNo - event.data.FromNo + 1,
    });
    this.onHidee();
  }
  statusBodyTemplate(rowData) {
    if (rowData) {
      return (
        <Checkbox
          disabled
          checked={rowData.IsActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      );
    }
  }
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    // today = mm + '-' + dd + '-' + yyyy;
    // today = mm + '/' + dd + '/' + yyyy;
    // today = dd + '-' + mm + '-' + yyyy;
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  onHideError() {
    this.props.onHideShowError(false);
  }
  onSelectState(selectedList, selectedItem) {
    debugger
    this.setState({
      stateID: [...this.state.stateID, selectedItem],
    });
  }
  onRemoveState(selectedList, removedItem) {
    ;
    this.setState({
      stateID: this.state.stateID.filter((id) => id.value != removedItem.value),
    });
  }
  onSelect(selectedList, selectedItem) {
    debugger
    this.setState({
      SelectedDynamicId: [...this.state.SelectedDynamicId, selectedItem],
    });
    //this.getMappings(selectedItem.value);
  }
  onRemove(selectedList, removedItem) {
    this.setState({
      SelectedDynamicId: this.state.SelectedDynamicId.filter(
        (item) => item.value != removedItem.value
      ),
    });
    // if (this.state.selectedMapping != null) {
    //   this.setState({
    //     selectedMapping: this.state.selectedMapping.filter(
    //       (mapping) => mapping.Id != removedItem.value
    //     ),
    //   });
    // }
  }

  handleDropdownChange = (e) => {
    // First, call the handleChange method
    this.handleChange(e);

    // Then, call the getCityList method with the required parameters
    this.getCityList(e, "B", "i");
  };
  LocationSelectAll(e, selectedItem) {
    ;
    if (e.target.checked) {
      this.setState({
        SelectedDynamicId: [...this.state.SelectedDynamicId, selectedItem],
      });
    } else {
      this.setState({
        SelectedDynamicId: [],
      });
      this.LocRef.current.resetSelectedValues();
    }
  }

  actionBodyTemplate(rowData) {
    const editMode = (e) => {
      debugger
      this.props.onHideShow(true);
      this.getCityList(rowData.State, "B", "");
      this.GetZoneWisePoint(rowData.SchemeId)
      // this.getDistrictList(rowData.State, "D", "");
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Scheme",
        SchemeId: rowData.SchemeId,
        SchemeName: rowData.SchemeName,
        stateID: rowData.stateID,
        Schemedescription: rowData.Schemedescription,
        SchemeCategory: rowData.SchemeCategory,
        //UserTypeId: rowData.UserTypeId,
        RoleId: rowData.RoleId,
        product: rowData.product,
        category: rowData.GroupId,
        Qty: rowData.Qty,
        city: rowData.City,
        state: rowData.State,
        SelectedDynamicId: this.props.loyaltypoints || [],
        // StartDate: new Date(rowData.StartDate),
        // EndDate: new Date(rowData.EndDate),
        ValidFrm: new Date(rowData.ValidFrm),
        ValidTo: new Date(rowData.ValidTo),
        FromNo: rowData.FromNo,
        ToNo: rowData.ToNo,
        Status: rowData.Status,
        IsActive: rowData.IsActive,
        loyalitypnt: rowData.loyalitypnt,
        CreatedDate: rowData.CreatedDate,
        isEditing: true,
      });
    };
    const switchHandleChange = (e) => {
      this.setState({
        switchAction: !rowData.IsActive,
        SchemeId: rowData.SchemeId,
      });
      this.props.onHideShowIsActive(true);
    };

    if (rowData) {

      return (
        <>
          {this.state.IsEdit === true ? (
            <div>

              {/* <Switch
                checked={rowData.IsActive}
                color="primary"
                name={rowData.SchemeId}
                onChange={switchHandleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              /> */}

              <Button icon="pi pi-pencil" onClick={editMode} />
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }


  render() {
    const renderFooter = () => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={() => this.switchToggleConfirm()}
            autoFocus
          />
        </div>
      );
    };
    const header = (
      <div className="table-header row my-3">
        <div className="col-md-4">
          <h4>Loyalty Scheme Master</h4>
        </div>

        <div className="col-md-4 layoutright">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>

          {this.state.IsAdd === true ? (
            <Button
              className="mr-2"
              label="Add Scheme"
              icon="pi pi-plus-circle"
              onClick={this.openDialog}
            />
          ) : (
            <Button
              className="mr-2"
              label="Add Scheme"
              icon="pi pi-plus-circle"
              style={{ display: "none" }}
              onClick={this.openDialog}
            />
          )}
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />

        <div className="card mb-5">
          <DataTable
            className="mb-2"
            value={this.props.SchememappingData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20, parseInt(this.state.totalRecords)]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="SchemeName" header="Scheme Name" sortable></Column>
            <Column field="GroupName" header="Group Name" sortable></Column>
            <Column field="ValidFrm" header="Valid From" sortable></Column>
            <Column field="ValidTo" header="Valid To" sortable></Column>
            <Column field="Zones" header="Zone" sortable></Column>
            {this.state.IsEdit === true ? (
              <Column
                className="text-right"
                field="Action"
                header="Action"
                style={{ width: "100px" }}
                body={this.actionBodyTemplate}
              ></Column>
            ) : (
              <Column
                field="Action"
                header="Action"
                style={{ width: "120px", display: "none" }}
              ></Column>
            )}
          </DataTable>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          {/* <form onSubmit={this.submitForm} autocomplete="off"> */}
          <div className="row">
            <div className="col-md-6 pt-3">
              <TextField
                disabled={this.state.isEditing}
                onChange={this.handleChange}
                name="SchemeName"
                autoComplete="false"
                value={this.state.SchemeName}
                size="small"
                variant="outlined"
                className="fullwidth"
                label="Scheme Name*"
              />
              {this.state.formError.SchemeName !== "" ? (
                <div className="error">{this.state.formError.SchemeName}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 pt-3">
              <Dropdown
                disabled={this.state.isEditing}
                onChange={this.handleChange}
                name="category"
                options={this.props.CategoryDDL}
                optionLabel="label"
                optionValue="value"
                value={this.state.category}
                filter
                className="form-control custom-select"
                placeholder="Select Group*"
              ></Dropdown>
              {this.state.formError.category !== "" ? (
                <div className="error">{this.state.formError.category}</div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-2 pt-3 text-right">
              <label>
                Valid From<span>*</span>:
              </label>
            </div>

            <div className="col-md-4 pt-3">
              <Calendar
                // minDate={minDate}
                // disabled={this.state.isEditing}
                // disabled={this.state.isEdit}
                value={this.state.ValidFrm}
                onChange={this.handleChange}
                placeholder={this.state.ValidFrm}
                showIcon={true}
                appendTo={document.getElementById("root")}
                name="ValidFrm"
              />
              {/* <input type="text" value={this.state.ValidFrm} onChange={this.handleChange} className="form-control" name="ValidFrm" /> */}
              {this.state.formError.ValidFrm !== null ? (
                <div className="error">{this.state.formError.ValidFrm}</div>
              ) : null}
            </div>

            <div className="col-md-2 pt-3 text-right">
              <label>
                Valid To<span>*</span>:
              </label>
            </div>
            <div className="col-md-4 pt-3">
              <Calendar
                minDate={this.state.ValidFrm}
                //className="custom-calender"
                value={this.state.ValidTo}
                placeholder={this.state.ValidTo}
                appendTo={document.getElementById("root")}
                onChange={this.handleChange}
                showIcon={true}
                name="ValidTo"
              />
              {/* <input type="text" value={this.state.ValidTo} onChange={this.handleChange} className="form-control" name="ValidTo" /> */}
              {this.state.formError.ValidTo !== null ? (
                <div className="error">{this.state.formError.ValidTo}</div>
              ) : null}
            </div>

            <div className="col-md-12 pt-3">
              <FormControl
                variant="outlined"
                size="small"
                className="formControl col-md-12"
              >
                <Dropdown
                  disabled={this.state.isEditing}
                  appendTo={document.getElementById("root")}
                  value={this.state.RoleId}
                  onChange={(e) => this.handleChange(e)}
                  options={this.state.UserRoleData}
                  optionValue="value"
                  optionLabel="label"
                  className="form-control"
                  placeholder="Scheme Beneficiary*"
                  name="RoleId"
                  filter
                />
              </FormControl>
              {this.state.formError.RoleId !== "" ? (
                <div className="error">{this.state.formError.RoleId}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 pt-3">
              <FormControl
                variant="outlined"
                size="small"
                className="formControl col-md-12"
              >
                <Dropdown
                  disabled={this.state.isEditing}
                  appendTo={document.getElementById("root")}
                  value={this.state.state}
                  //value={this.props.ProductData}
                  options={this.props.StateData}
                  onChange={this.handleDropdownChange}
                  // onChange={(e) => this.handleChange (e)}
                  optionValue="value"
                  optionLabel="label"
                  className="form-control custom-select"
                  placeholder={"Select State*"}
                  name="state"
                  // onChange={(e) => {
                  //   this.getCityList(e, "B", "i");
                  //   //this.getDistrictList(e, "D", "i");
                  // }}
                  filter
                />
                {/* <label className="ml-2" for="all">
                  {" "}
                  Select All Locations
                </label>
                <input
                  id="all"
                  disabled={this.state.isEditing}
                  onChange={this.LocationSelectAll}
                  type="checkbox"
                /> */}
              </FormControl>
              {this.state.formError.state !== "" ? (
                <div className="error">{this.state.formError.state}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 pt-3">
              <FormControl
                variant="outlined"
                size="small"
                className="formControl col-md-12"
              >
                {
                  this.state.isEditing ? (
                    <div style={{ pointerEvents: 'none', opacity: 0.6 }}>
                      <Multiselect
                        hidePlaceholder="true"
                        ref={this.LocRef}
                        onChange={this.handleDropdownChange}
                        appendTo={document.getElementById("root")}
                        value={this.state.SelectedDynamicId}
                        onSelect={this.onSelect}
                        onRemove={this.onRemove}
                        displayValue="label"
                        selectedValues={this.state.stateID}
                        placeholder="Select Zone*"
                        name="SelectedDynamicId"
                        options={this.props.UserBCityData}
                      />
                    </div>
                  ) : (
                    <Multiselect
                      hidePlaceholder="true"
                      ref={this.LocRef}
                      onChange={this.handleDropdownChange}
                      appendTo={document.getElementById("root")}
                      value={this.state.SelectedDynamicId}
                      onSelect={this.onSelect}
                      onRemove={this.onRemove}
                      displayValue="label"
                      selectedValues={this.state.stateID}
                      placeholder="Select Zone*"
                      name="SelectedDynamicId"
                      options={this.props.UserBCityData}
                    />
                  )
                }

               
              </FormControl>
              {this.state.formError.SelectedDynamicId !== "" ? (
                <div className="error">{this.state.formError.SelectedDynamicId}</div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-6 pt-3">
              <Checkbox
                name="IsActive"
                checked={this.state.IsActive}
                onClick={this.handleChange}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />{" "}
              <label>Is Active</label>
            </div>

            <div className="col-md-6 pt-3">
              <DataTable rowData value={this.state.SelectedDynamicId}>
                <Column
                  field="label"
                  header="Category Zone"
                  style={{ width: "50px" }}
                />
                <Column
                  rowSpan="row"
                  body={(rowdata) => {
                    return (
                      <input
                        type="number"
                        disabled={this.state.isEditing}
                        onChange={(event) => {
                          debugger
                          if (parseInt(event.target.value) < 1) {
                            window.alert("Enter Points Greater than 0");
                          }
                          var ele =
                            event.target.parentNode.parentNode.textContent;
                          var tmp = this.state.SelectedDynamicId;
                          tmp = tmp.map((element) => {
                            if (element.label == ele) {
                              element.Points = event.target.value;
                            }
                            return element;
                          });
                          this.setState({
                            SelectedDynamicId: tmp,
                          });
                        }}
                        value={rowdata.Points}
                        placeholder="Enter Points"
                      />
                    );
                  }}
                  header="Loyalty Points"
                  style={{ width: "50px" }}
                />
              </DataTable>
            </div>

            {/* <div className="col-md-12 mt-3 mb-3 text-center">
                                {this.state.IsEdit === true ?
                                    <Button label={this.state.buttonLabel} icon="pi pi-check" />
                                    : ""}
                            </div> */}
            <div className="col-md-12 mt-4 text-center">
              <div className="mb-2">Please Click Below Button To Submit*</div>

              <Button
                label={this.state.buttonLabel}
                onClick={this.submitForm}
                icon="pi pi-check"
              />
            </div>

            <div className="col-md-12">
              Note: Fields marked as (*) are required
            </div>
          </div>
          {/* </form> */}
        </Dialog>
        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>

        <Dialog
          header={"Failed Mappings Data"}
          modal
          dismissableMask={false}
          visible={this.props.showErrorDialog}
          style={{ width: "50vw" }}
          onHide={this.onHideError}
        >
          <div className="datatable-selection-demo">
            <div className="card">
              <Toast ref={(el) => (this.toast = el)} />

              {this.props.ErrorData ? (
                <DataTable
                  value={this.props.ErrorData}
                  scrollable
                  responsive={true}
                  dataKey="id"
                  responsiveLayout="scroll"
                >
                  <Column
                    field="ProductCode"
                    header="Product Code"
                    sortable
                  ></Column>
                  <Column field="RoleName" header="Role Name" sortable></Column>
                  <Column
                    field="LoyaltyPoints"
                    header="Loyalty Points"
                    sortable
                  ></Column>
                  <Column field="Reason" header="Reason" sortable></Column>
                </DataTable>
              ) : (
                <h4 className="text-center"> No Data Available </h4>
              )}
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    StateData: state.dealer.stateddl,
    SchememappingData: state.loyalityscheme.items,
    UserRoleData: state.role.rollddl,
    UserBCityData: state.dealer.bcityddl,
    DistrictData: state.dealer.Districtddl,
    FromNo:
      state.loyalityscheme.Mappseries != null
        ? state.loyalityscheme.Mappseries[0].FromNo
        : "",
    //UserRoleData: state.role.rollddl,
    Mappseries: state.loyalityscheme.Mappseries,
    showDialogue: state.loyalityscheme.showDialogue,
    // productData: state.product.prdctddl,
    CategoryDDL: state.product.prdctddl,
    showDialog: state.role.showDialog,
    loading: state.company.loding,
    showIsActiveDialog: state.loyalityscheme.showIsActiveDialog,
    showErrorDialog: state.loyalityscheme.showErrorDialog,
    ErrorData: state.loyalityscheme.ErrorData,
    //Points:state.loyalityscheme.loyalityscheme
    loyaltypoints: state.loyalityscheme.loyaltypoints || [],
    loyalityschemeActions: state.loyalityscheme.data || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(loyalityschemeActions.getAll(obj));
    },
    getCategoryDDL: () => {
      dispatch(productMasterActions.getCategoryDDL());
    },
    GetAvailableseries: (data) => {
      dispatch(loyalityschemeActions.GetAvailableseries(data));
    },
    createScheme: (user) => dispatch(loyalityschemeActions.createScheme(user)),
    updateScheme: (user) => dispatch(loyalityschemeActions.updateScheme(user)),
    inActiveRow: (SchemeId, isActive) =>
      dispatch(loyalityschemeActions.inActiveRow(SchemeId, isActive)),
    onHideShow: (isbool) => dispatch(loyalityschemeActions.onHideShow(isbool)),
    onHideShoww: (isbool) =>
      dispatch(loyalityschemeActions.onHideShoww(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(loyalityschemeActions.onHideShowIsActive(isbool)),
    onHideShowError: (isbool) =>
      dispatch(loyalityschemeActions.onHideShowError(isbool)),
    getRoleDDL: (data) => dispatch(userMasterActions.getRoleDDL(data)),
    loadingData: (isLoding) =>
      dispatch(loyalityschemeActions.loadingData(isLoding)),
    UploadSchemeMapping: (obj, tblResponse) => {
      dispatch(loyalityschemeActions.UploadSchemeMapping(obj, tblResponse));
    },
    getStateDDL: (data) => dispatch(dealerMasterActions.getStateDDL(data)),
    getBCityDDL: (data) => dispatch(dealerMasterActions.getBCityDDL(data)),
    showWarn: (message) => dispatch(alertActions.warning(message)),
    GetZoneWisePointList: (data) => dispatch(loyalityschemeActions.GetZoneWisePointList(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(loyalityscheme);
