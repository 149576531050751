import { offerMasterConstants } from "../constants";

export function offer(state = {}, action) {
  switch (action.type) {
    case offerMasterConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool,
      };
    case offerMasterConstants.DIALOG_REQUEST:
      return {
        ...state,
        showDialog: action.isbool,
      };
    case offerMasterConstants.DIALOG_ISACTIVE_REQUEST:
      return {
        ...state,
        showIsActiveDialog: action.isbool,
      };
    case offerMasterConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case offerMasterConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case offerMasterConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case offerMasterConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case offerMasterConstants.CREATE_SUCCESS:
      return {
        ...state,
        items: [action.data, ...state.items],
      };
    case offerMasterConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case offerMasterConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: state.items.map((item) =>
          item.CompanyId == action.data.CompanyId
            ? { ...item, updating: true }
            : item
        ),
      };
    case offerMasterConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.CompanyId === action.data.CompanyId ? action.data : item
        ),
      };
    case offerMasterConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case offerMasterConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case offerMasterConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.CompanyId === action.data
            ? {
                CompanyId: item.CompanyId,
                CompanyName: item.CompanyName,
                CompanyDisplayName: item.CompanyDisplayName,
                Prefix: item.Prefix,
                Email: item.Email,
                Mobile: item.Mobile,
                WebsiteURL: item.WebsiteURL,
                Address: item.Address,
                CompanyLogo: item.CompanyLogo,
                FakeMessage: item.FakeMessage,
                FakeAudio: item.FakeAudio,
                IsActive: false,
                CreatedDate: item.CreatedDate,
                UpdatedDate: item.UpdatedDate,
              }
            : item
        ),
      };
    case offerMasterConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case offerMasterConstants.ISACTIVE_REQUEST:
      return {
        ...state,
        activating: true,
      };
    case offerMasterConstants.ISACTIVE_SUCCESS:
      return {
        ...state,
        items: state.items,
      };
    case offerMasterConstants.ISACTIVE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case offerMasterConstants.GETEXPORT_REQUEST:
      return {
        ...state,
        exporting: true,
      };
    case offerMasterConstants.GETEXPORT_SUCCESS:
      return {
        ...state,
        exportitems: action.datas,
      };
    case offerMasterConstants.GETEXPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
