import React, { Component } from "react";
import Avtar from "./../assets/images/avatar.jpg";
import { Menubar } from "primereact/menubar";
import { BrowserRouter as Router, Link } from "react-router-dom";
import logo from "./../assets/images/avwoodlogo.png";
import { connect } from "react-redux";
import { menuActions } from "../../src/actions";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: false,
      UserName: "",
    };

    this.navigation = [
      {
        label: "Dashboard",
        icon: "pi pi-palette",
        url: "/",
      },
      {
        label: "Masters",
        icon: "pi pi-user",
        items: [
          {
            label: "Company Master",
            url: "/masters/company-master",
            icon: "pi pi-angle-right",
          },
          {
            label: "User Master",
            url: "/masters/user-master",
            icon: "pi pi-angle-right",
          },
          {
            label: "Product Master",
            url: "/masters/product-master",
            icon: "pi pi-angle-right",
          },
          {
            label: "Product Configuration",
            url: "/masters/product-configuration",
            icon: "pi pi-angle-right",
          },
          {
            label: "Role Master / Access Right",
            url: "/masters/role-master",
            icon: "pi pi-angle-right",
          },
          {
            label: "Loyality Scheme Master",
            url: "/masters/loyalityscheme-master",
            icon: "pi pi-angle-right",
          },
          {
            label: "SQL Editor",
            url: "/masters/sql-editor",
			  icon: "pi pi-angle-right",
          },
          {
            label: "Category Master",
            url: "/masters/category-master",
            icon: "pi pi-angle-right",
          },
        ],
      },
      {
        label: "Activation",
        icon: "pi pi-fw pi-pencil",
        items: [
          {
            label: "Issue Number",
            url: "/activation/issue-number",
            icon: "pi pi-angle-right",
          },
        ],
      },
      {
        label: "Transactions",
        icon: "pi pi-fw pi-pencil",
        items: [
          {
            label: "Mapping",
            url: "/transactions/mapping",
            icon: "pi pi-angle-right",
          },
          {
            label: "De-mapping",
            url: "/transactions/de-mapping",
            icon: "pi pi-angle-right",
          },
          {
            label: "Damage",
            url: "/transactions/damage",
            icon: "pi pi-angle-right",
          },
          {
            label: "Redemption Approval",
            url: "/transactions/RedemptionApproval",
            icon: "pi pi-angle-right",
          },
        ],
      },
      {
        label: "Reports",
        icon: "pi pi-chart-bar",
        items: [
          {
            label: "Verification Log",
            url: "/reports/verification-log",
            icon: "pi pi-angle-right",
          },
          {
            label: "Mapping Report",
            url: "/reports/mapping-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Demapping Report",
            url: "/reports/Demapping-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Damage Report",
            url: "/reports/Damage-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Loyalty Scheme Report",
            url: "/reports/Loyalty-Scheme-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Redemption Log Report",
            url: "/reports/Redemption-Log-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Feedback Report",
            url: "/reports/",
            icon: "pi pi-angle-right",
          },
          {
            label: "User Loyality Report",
            url: "/reports/Userloyality-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Product Purchase Report",
            url: "/reports/ProductPurchaseRpt-report",
            icon: "pi pi-angle-right",
          },
        ],
      },
    ];
  }
  listener = null;

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    let roleId = sessionStorage.getItem("UserRole");
    this.props.fetchApi(roleId);
    this.props.getAccessRight(roleId);
    let userNameCollector = sessionStorage.getItem("UserNameData");
    this.setState({ UserName: userNameCollector });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", null);
  }

  handleScroll = () => {
    if (window.pageYOffset > 74) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  };

  render() {
    return (
      <div className="row header-bg">
        <div className="col-md-2 mobiletop logo_bg">
          <a href="/">
            {" "}
            <span className="co-name">
              <img src={logo}  className=""/>
            </span>
          </a>
        </div>
        <div className="col-md-10 user_bg" style={{ background: "" }}>
          <div className="user-area dropdown text-right">
            <span className="dropdown-toggle active">
              {" "}
              Welcome, {this.state.UserName}
              <img
                alt="User Avatar"
                className="user-avatar rounded-circle"
                src={Avtar}
              />
            </span>
            <div className="user-menu">
              <div
                className="nav-link"
                onClick={() => (window.location = "/profile")}
              >
                <i className="fa fa-lock"></i>Change Password
              </div>
              <div
                className="nav-link"
                onClick={(e) => {
                  sessionStorage.removeItem("LoggedInUser");
                  window.location = "/login";
                }}
              >
                <i className="fa fa-sign-out" aria-hidden="true"></i>Logout
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    MenuData: state.menu.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (roleId) => {
      dispatch(menuActions.getMenu(roleId));
    },
    getAccessRight: (roleId) => {
      dispatch(menuActions.getAccessRight(roleId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
