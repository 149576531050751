export const MobileUserTDSReportConstants ={
    GETALL_REQUEST: 'USERTDS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERTDS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERTDS_GETALL_FAILURE',

    GETEXPORT_REQUEST: 'USERTDS_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'UUSERTDS_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'USERTDS_GETEXPORT_FAILURE',

    LOADING_REQUEST: 'LOADING_REQUEST'
}