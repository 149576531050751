import { authHeader, authHeaderFormData, handleResponse } from "../helpers";
export const listMasterService = {
  CreateList,
  UpdateList,
  fetchListData,
  inActiveRow,
  GetAvailableseries,
  UploadSchemeMapping,
  fetchListDataDLL,
};

function CreateList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/CreateList`,
    requestOptions
  ).then(handleResponse);
}

function UpdateList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/CreateList`,
    requestOptions
  ).then(handleResponse);
}

function fetchListData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/GetListData`,
    requestOptions
  ).then(handleResponse);
}

function inActiveRow(SchemeId, isActive) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/InActiveRow/${SchemeId}/${isActive}`,
    requestOptions
  ).then(handleResponse);
}

function GetAvailableseries(proddata) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(proddata),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/GetAvailableseries`,
    requestOptions
  ).then(handleResponse);
}

function UploadSchemeMapping(formData) {
  ;
  const requestOptions = {
    method: "POST",
    headers: authHeaderFormData(),
    body: formData,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/UploadSchemeMapping`,
    requestOptions
  ).then(handleResponse);
}

function fetchListDataDLL() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}Loyalityscheme/GetListDataDLL`,
    requestOptions
  ).then(handleResponse);
}
