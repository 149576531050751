import { MobileUserTDSReportService } from "../services/MobileUserTDSReportService";
import { MobileUserTDSReportConstants } from "../constants/MobileUserTDSReportConstants";
import { alertActions } from ".";

export const MobileUserTDSReportActions = {
  getAll,
  loadingData,
  exportData,
};

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());

    MobileUserTDSReportService.fetchTDSData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: MobileUserTDSReportConstants.GETALL_REQUEST };
  } //Changes
  function success(datas) {
    return { type: MobileUserTDSReportConstants.GETALL_SUCCESS, datas };
  }
  function successExport(datas) {
    return { type: MobileUserTDSReportConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: MobileUserTDSReportConstants.GETALL_FAILURE, error };
  }
}

function exportData(obj) {
  return (dispatch) => {
    dispatch(request());
    MobileUserTDSReportService.fetchTDSData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        ;
        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.CreatedBy;
            delete v.DocURL;
            delete v.UserloyalityLogId;
            delete v.ProductID;
            delete v.LoyalityTrnsId;
            delete v.SchemeId;
            delete v.UserId;
            delete v.RoleId;
            delete v.SchemeMapingId;
            delete v.ScratchNo;
            delete v.VerificationId;
            delete v.RedeemReqId;
            delete v.Schemecategory;
            delete v.IsApproved;
            delete v.TransId;
            delete v.UpdatedBy;
            delete v.UpdatedDt;
            delete v.isMechanic;
            delete v.GstCertificate;
            delete v.Country	;
            delete v.State;
            delete v.UserCode;
            delete v.PanNoDoc;
            delete v.District;
            delete v.AadharDoc1;	
            delete v.AadharDoc2
	



          });
        }
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: MobileUserTDSReportConstants.GETEXPORT_REQUEST };
  }
  function success(datas) {
    return { type: MobileUserTDSReportConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: MobileUserTDSReportConstants.GETEXPORT_FAILURE, error };
  }
}

function loadingData(isbool) {
  return {
    type: MobileUserTDSReportConstants.LOADING_REQUEST,
    isbool,
  };
}
