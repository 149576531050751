import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TextField from '@material-ui/core/TextField';
import { Calendar } from 'primereact/calendar';
import { alertActions, mappingRptActions } from "../../actions";
import { connect } from 'react-redux';
import tableRequest from '../../models/tableRequest.js';
import { Toast } from 'primereact/toast';
import { CSVLink } from "react-csv";
import Loader from '../../templates/Loader.js';
class MappingRpt extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.FilterData = this.FilterData.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.state = {
            loading: false,
            FromDate: new Date(),
            ToDate: new Date(),
            first: 0,
            headers: [{
                key: "ProductName",
                label: "Product Name"
            }, {
                key: "FromNo",
                label: "FromNo"
            }, {
                key: "ToNo",
                label: "ToNo"
            }, {
                key: "Qty",
                label: "Qty"
            }, {
                key: "BatchNo",
                label: "Batch No"
            }, {
                key: "loyalitypnt",
                label: "loyality Point"
            }, {
                key: "CreatedDate",
                label: "Created Date"
            }],
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            visible: false,
            sortOrder: '',
            datalist: [],
        }
    }
    componentDidMount() {
        var date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() - 7);
        this.tblResponse.FromDate = date.toLocaleDateString();
        this.tblResponse.ToDate = new Date().toLocaleDateString();
        this.setState({
            FromDate: date,
            ToDate: new Date()
        });
        this.props.fetchApi(this.tblResponse);
        this.props.exportingData(this.tblResponse);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mappingList !== this.props.mappingList) {
            // this.props.myProp has a different value
            if (this.props.mappingList) {
                var e = this.props.mappingList;
                this.setState({
                    totalRecords: e[0].TotalRows
                });
            }
        }
    }

    GetMapping() {
        let ToDt = new Date();
        let FrmDt = new Date();
        FrmDt.setDate(ToDt.getDate() - 7);

        const myFmDt = this.getCurrentDateYYYYMMDD(FrmDt);
        const myToDt = this.getCurrentDateYYYYMMDD(ToDt);

        this.tblResponse.FromDate = myFmDt;
        this.tblResponse.ToDate = myToDt;
        this.props.fetchApi(this.tblResponse);
        this.props.exportingData(this.tblResponse);
    }

    FilterData() {
        this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
        this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
        this.props.exportingData(this.tblResponse);
        this.props.fetchApi(this.tblResponse);
    }

    getCurrentDateYYYYMMDD(dt) {
        var dd = String(dt.getDate()).padStart(2, '0');
        var mm = String(dt.getMonth() + 1).padStart(2, '0');
        var yyyy = dt.getFullYear();

        dt = yyyy + '/' + mm + '/' + dd;
        return dt;
    }

    handleChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case 'FromDate':
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    // this.refs.Messchild.showError("Please select from date.");
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select from date.', life: 3000 });

                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
                    // this.refs.Messchild.showError("Please enter only date.");
                    return;
                }
                else if ((value instanceof Date) && (this.state.ToDate instanceof Date)) {
                    const startDate = value;
                    const endDate = this.state.ToDate;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'From date must be less than to date.', life: 3000 });
                        // this.refs.Messchild.showError("From date must be less than to date.");
                        return;
                    }
                }

                break;
            case 'ToDate':
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select to date.', life: 3000 });
                    // this.refs.Messchild.showError("Please select to date.");
                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
                    // this.refs.Messchild.showError("Please enter only date.");
                    return;
                }
                else if ((value instanceof Date) && (this.state.FromDate instanceof Date)) {
                    const startDate = this.state.FromDate;
                    const endDate = value;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'To date must be greater than from date.', life: 3000 });
                        // this.refs.Messchild.showError("To date must be greater than from date.");
                        return;
                    }
                }

                break;
            default:
                break;
        }

        this.setState({ [name]: value });
    }
    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
        this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
        this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
    }

    onPage(event) {
        this.props.loadingData(true);

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
            this.props.exportingData(this.tblResponse);
        }, 250);
    }

    //to search
    searchFilter(e) {
        // this.setState({
        //     loading: true
        // });
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
        this.props.exportingData(this.tblResponse);
    }

    //to sort
    onSort(event) {
        this.props.loadingData(true);;
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDate" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
        this.props.exportingData(this.tblResponse);
    }

    render() {

        return (
            <div className="datatable-filter-demo">
                <Toast ref={(el) => this.toast = el} />        
        <Loader loader={this.props.loading} />
                <div className="row mb-3">
                    <div className="col-md-3">
                        <h4>Mapping Report</h4>
                    </div>
                    <div className="col-md-9">
                        <div className="row text-right">
                            <div className="col-md-3">
                                <Calendar placeholder="From Date" name="FromDate" showIcon="true" value={this.state.FromDate} onChange={this.handleChange}></Calendar></div>
                            <div className="col-md-3">
                                <Calendar placeholder="To Date" name="ToDate" showIcon="true" value={this.state.ToDate} onChange={this.handleChange}></Calendar>
                            </div>
                            <div className="col-md-3 text-center ">
                                <button type="button" onClick={this.FilterData} className="btn btn-primary p-2 ml-3 buttone">Submit</button>
                                &nbsp;
                                <CSVLink data={this.props.List} headers={this.state.headers} filename={"Mapping report.csv"}
                                    target="_blank"><button className="btn btn-success p-2 mr-2 buttone">Export</button></CSVLink>
                            </div>
                            <div className="col-md-3">
                                <TextField id="standard-basic" size="small" variant="outlined" className="fullwidth" label="Search" onInput={this.searchFilter} />
                            </div>

                        </div>

                    </div>
                </div>
                <div className="card">
                    <DataTable value={this.props.mappingList}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter}
                        emptyMessage="No Data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[10, 100, 200,parseInt(this.state.totalRecords) ]}
                        totalRecords={this.state.totalRecords}
                        lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort} ref={(el) => { this.dt = el; }}>
                        <Column field="ProductName" disabled header="Product Name" sortable></Column>
                        <Column field="FromNo" disabled header="From No" sortable></Column>
                        <Column field="ToNo" disabled header="To No" sortable></Column>
                        <Column field="Qty" disabled header="Qty" sortable></Column>
                        <Column field="BatchNo" disabled header="BatchNo" sortable></Column>
                        <Column field="loyalitypnt" header="loyality Point" sortable></Column>
                        {/* <Column field="BatchNo" disabled header="Batch No" sortable></Column> */}
                        <Column field="CreatedDate" header="Mapped On" sortable></Column>
                        {/* <Column field="Remark" header="Remark" sortable></Column> */}
                    </DataTable>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        mappingList: state.mappingRpt.items,
        List: state.mappingRpt.exportitems == undefined ? [] : state.mappingRpt.exportitems,
        loading: state.verification.loading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(mappingRptActions.getAll(obj))
        },
        loadingData: (isLoding) => dispatch(mappingRptActions.loadingData(isLoding)),
        exportingData: (obj) => dispatch(mappingRptActions.exportData(obj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MappingRpt)
