

import { dashboardService } from '../services';
import { dashboardConstants } from '../constants';
import { alertActions } from '.';

export const dashboardActions = {
    
    fetchData,
    loadingData
};

function fetchData(obj) {
    return dispatch => {
        dispatch(request());

        dashboardService.fetchData(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: dashboardConstants.GETALL_REQUEST } }
    function success(datas) { return { type: dashboardConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: dashboardConstants.GETALL_FAILURE, error } }
}
function loadingData(isbool) {
    return {
        type: dashboardConstants.LOADING_REQUEST, isbool
    }
}