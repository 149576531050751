import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./../views/Dashboard";
import CompanyMaster from "./../components/masters/companyMaster";
import ProductMaster from "./../components/masters/productMaster";
import roleMaster from "./../components/masters/roleMaster";
import loyalityscheme from "./../components/masters/loyalityScheme";
import listMaster from "./../components/masters/listMaster";
import SqlEditor from "../components/masters/SqlEditor";
import Profile from "./../views/Profile";
import IssueNumber from "../components/mapping/IssueNumber";
import Mapping from "./../components/transactions/Mapping";
import DeMapping from "./../components/transactions/DeMapping";
import Damage from "./../components/transactions/Damage";
import UserMaster from "./../components/masters/userMaster";
import VerificationLog from "./../components/reports/VerificationLog";
import MappingRpt from "./../components/reports/MappingRpt";
import DemappingRpt from "./../components/reports/DemappingRpt";
import DamageRpt from "../components/reports/DamageRpt";
import LoyaltySchemeRpt from "./../components/reports/LoyaltySchemeRpt";
import FeedbackRpt from "./../components/reports/FeedbackRpt";
import UnscanRpt from "./../components/reports/UnscanRpt";
import ProductConfiguration from "../components/masters/ProductConfiguration";
import RedemptionApproval from "../components/transactions/RedemptionApproval";
import LoyaltyRedemption from "../components/transactions/LoyaltyRedemption";
import RedemptionLogRpt from "./../components/reports/RedemptionLogRpt";
import UserloyalityLogRpt from "../components/reports/UserloyalityLogRpt";
import ProductPurchaseRpt from "../components/reports/ProductPurchaseRpt";
import offerMaster from "../components/masters/offerMaster";
import userApproval from "../components/masters/userApproval";
// import dealerMaster from "../components/masters/dealerMaster";

import ProductRatingRpt from "../components/reports/ProductRatingRpt";
import categortMaster from "../components/masters/categoryMaster";
import ScannedHistory from "../components/reports/ScannedHistory";
import DealerDataRpt from "../components/reports/DealerDataRpt";
import uploadMapping from "../components/transactions/uploadMapping";
import IssuePage from "../components/transactions/issuePage";
import dealerMaster from "../components/masters/dealerMaster";
import AllDealerDataRpt from "../components/reports/AllDealerDataRpt";
import DistributorDataRpt from "./../components/reports/DistributorDataRpt";
import MobileUserTDSReport from "../components/reports/MobileUserTDSReport";
import UserAccountBalanceRpt from "../components/reports/UserAccountBalanceRpt";
import DealerStockInvoice from "../components/transactions/DealerStockInvoice";

export default class Template extends Component {
  render() {
    return (
      <div className="container-fluid ">
        <Header />
        <div className="row">
          <div className="col-md-2 sidebar-bg">
            <Sidebar />
          </div>
          <div className="col-md-10 body-part ">
            <Router>
              <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/profile" component={Profile} />
                <Route
                  exact
                  path="/masters/company-master"
                  component={CompanyMaster}
                />
                <Route
                  exact
                  path="/masters/product-master"
                  component={ProductMaster}
                />
                <Route
                  exact
                  path="/masters/role-master"
                  component={roleMaster}
                />
                <Route
                  exact
                  path="/masters/loyalityscheme-master"
                  component={loyalityscheme}
                />
                <Route
                  exact
                  path="/masters/list-master"
                  component={listMaster}
                />
                <Route
                  exact
                  path="/masters/user-master"
                  component={UserMaster}
                />
                <Route
                  exact
                  path="/masters/Category-Master"
                  component={categortMaster}
                />
                <Route
                  exact
                  path="/masters/product-configuration"
                  component={ProductConfiguration}
                />
                <Route exact path="/masters/sql-editor" component={SqlEditor} />
                <Route
                  exact
                  path="/masters/offer-master"
                  component={offerMaster}
                />
                <Route
                  exact
                  path="/masters/mobile-user-master"
                  component={dealerMaster}
                />
                <Route
                  exact
                  path="/masters/user-approval"
                  component={userApproval}
                />
                <Route
                  exact
                  path="/activation/issue-number"
                  component={IssueNumber}
                />
                <Route exact path="/transactions/damage" component={Damage} />
                <Route exact path="/transactions/mapping" component={Mapping} />
                <Route
                  exact
                  path="/transactions/de-mapping"
                  component={DeMapping}
                />
                <Route
                  exact
                  path="/transactions/uploadMapping"
                  component={uploadMapping}
                />
                <Route
                  exact
                  path="/transactions/issue-page"
                  component={IssuePage}
                />
                <Route
                  exact
                  path="/transactions/RedemptionApproval"
                  component={RedemptionApproval}
                />
                <Route
                  exact
                  path="/transactions/LoyaltyRedemption"
                  component={LoyaltyRedemption}
                />
                <Route
                  exact
                  path="/transactions/dealerStockInvoice"
                  component={DealerStockInvoice}
                />
                <Route
                  exact
                  path="/reports/verification-log"
                  component={VerificationLog}
                />
                <Route
                  exact
                  path="/reports/mapping-report"
                  component={MappingRpt}
                />
                <Route
                  exact
                  path="/reports/DistributorDataRpt"
                  component={DistributorDataRpt}
                />
                <Route
                  exact
                  path="/reports/Demapping-report"
                  component={DemappingRpt}
                />
                <Route
                  exact
                  path="/reports/Damage-report"
                  component={DamageRpt}
                />
                <Route
                  exact
                  path="/reports/Loyalty-Scheme-report"
                  component={LoyaltySchemeRpt}
                />
                <Route
                  exact
                  path="/reports/Redemption-Log-report"
                  component={RedemptionLogRpt}
                />
                <Route
                  exact
                  path="/reports/feedback-report"
                  component={FeedbackRpt}
                />
                <Route
                  exact
                  path="/reports/unscanrpt"
                  component={UnscanRpt}
                />
                <Route
                  exact
                  path="/reports/Userloyality-report"
                  component={UserloyalityLogRpt}
                />
                <Route
                  exact
                  path="/reports/ProductPurchaseRpt-report"
                  component={ProductPurchaseRpt}
                />
                <Route
                  exact
                  path="/reports/ScannedHistory-report"
                  component={ScannedHistory}
                />
                a
                <Route
                  exact
                  path="/reports/ProductRatingRpt"
                  component={ProductRatingRpt}
                />
                <Route
                  exact
                  path="/reports/mechanic-report"
                  component={DealerDataRpt}
                />

                <Route
                  exact
                  path="/reports/points-ledger"
                  component={UserAccountBalanceRpt}
                />

                <Route
                  exact
                  path="/reports/Mobile-TDS-report"
                  component={MobileUserTDSReport}
                />


                <Route
                  exact
                  path="/reports/AllDealerDataRpt"
                  component={AllDealerDataRpt}
                />
                <Route path="*" component={Dashboard} />
              </Switch>
            </Router>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
