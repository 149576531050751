import React, { Component } from "react";
import loaderPic from './../assets/images/garden-loader.gif';

class Loader extends Component {
    ;
    render() {
        return (
            <>
                {!this.props.loader ? null : <div className={this.props.classType === 'small' ? 'loader-small' : 'loader'}><img src={loaderPic} alt="Loading..." /></div>}
            </>
        )
    }
}

export default Loader; // Don't forget to export your Loader component
