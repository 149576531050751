export const ScannedHistoryConstants ={
    GETALL_REQUEST: 'SCANNEDHISTORY_GETALL_REQUEST',
    GETALL_SUCCESS: 'SCANNEDHIDTORY_GETALL_SUCCESS',
    GETALL_FAILURE: 'SCANNEDHISTORY_GETALL_FAILURE',

    GETEXPORT_REQUEST: 'SCANNEDHISTORY_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'SCANNEDHISTORY_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'SCANNEDHISTORY_GETEXPORT_FAILURE',

    LOADING_REQUEST: 'LOADING_REQUEST'
}