import { LoyaltyRedemptionService } from '../services';
import { LoyaltyRedemptionConstants } from '../constants';
import { alertActions } from '.';

export const LoyaltyRedemptionActions = {
    LoyaltyRedempation,
    getAll,
    loadingData
};


function LoyaltyRedempation(data, obj) {
    return dispatch => {
        dispatch(request(data));

        LoyaltyRedemptionService.LoyaltyRedemption(data).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(getAll(obj))
                        // dispatch(onHideShow(false));
                        // dispatch(onHideShoww(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: LoyaltyRedemptionConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: LoyaltyRedemptionConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: LoyaltyRedemptionConstants.CREATE_FAILURE, error } }
};

function getAll(obj) {
    return dispatch => {
        dispatch(request());

        LoyaltyRedemptionService.FetchLoyaltyRedemption(obj).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: LoyaltyRedemptionConstants.GETALL_REQUEST } }
    function success(datas) { return { type: LoyaltyRedemptionConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: LoyaltyRedemptionConstants.GETALL_FAILURE, error } }
}


function loadingData(isbool) {
    return {
        type: LoyaltyRedemptionConstants.LOADING_REQUEST, isbool
    }
}