import { mappingService } from "../services";
import { mappingConstants } from "../constants";
import { alertActions } from ".";
import { history } from "../helpers";

export const mappingActions = {
  getAll,
  createMapping,
  updateMapping,
  GetAvailableseries,
  GetToNo,
  onHideShow,
  onHideShoww,
  onHideShowError,
  loadingData,
  UploadMapping,
};

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());
    mappingService.fetchMappingData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: mappingConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: mappingConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: mappingConstants.GETALL_FAILURE, error };
  }
}

function createMapping(data) {
  return (dispatch) => {
    dispatch(request(data));

    mappingService.createMapping(data).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200")
        {
          
            dispatch(success(response.ResponseData));
            dispatch(onHideShow(false));
            dispatch(onHideShoww(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: mappingConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: mappingConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: mappingConstants.CREATE_FAILURE, error };
  }
}

function updateMapping(data) {
  return (dispatch) => {
    dispatch(request(data));

    mappingService.createMapping(data).then(
      (response) => {
        dispatch(success(data));
        //history.push('/');
        if (response && response.ResponseCode) {
          response.ResponseCode == "200"
            ? dispatch(alertActions.success(response.ResponseMessage))
            : response.ResponseCode == "400"
            ? dispatch(alertActions.warning(response.ResponseMessage))
            : dispatch(alertActions.error(response.ResponseMessage));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: mappingConstants.UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: mappingConstants.UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: mappingConstants.UPDATE_FAILURE, error };
  }
}

function GetAvailableseries(data) {
  return (dispatch) => {
    dispatch(request(data));

    mappingService.GetAvailableseries(data).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: mappingConstants.GETALL_AVAILABLE_REQUEST };
  }
  function success(datas) {
    return { type: mappingConstants.GETALL_AVAILABLE_SUCCESS, datas };
  }
  function failure(error) {
    return { type: mappingConstants.GETALL_AVAILABLE_FAILURE, error };
  }
}

function GetToNo() {
  return (dispatch) => {
    dispatch(request());
    mappingService.fetchToNo().then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: mappingConstants.GETALL_TONO_REQUEST };
  }
  function success(datas) {
    return { type: mappingConstants.GETALL_TONO_SUCCESS, datas };
  }
  function failure(error) {
    return { type: mappingConstants.GETALL_TONO_FAILURE, error };
  }
}

function UploadMapping(formData, tblResponse) {
  ;
  return (dispatch) => {
    dispatch(request());
    mappingService.UploadMapping(formData).then(
      (response) => {
        dispatch(success(response));
        if (response.ResponseCode == "200") {
          dispatch(alertActions.success(response.ResponseMessage.toString()));
          dispatch(getAll(tblResponse));
        } else if (response.ResponseCode == "201") {
          dispatch(alertActions.warning(response.ResponseMessage.toString()));
          dispatch(partial_success(response.ResponseData));
          dispatch(getAll(tblResponse));
        } else
          dispatch(alertActions.error(response.ResponseMessage.toString()));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: mappingConstants.UPLOAD_SO_REQUEST };
  }
  function success(datas) {
    return { type: mappingConstants.UPLOAD_SO_SUCCESS, datas };
  }
  function partial_success(datas) {
    return { type: mappingConstants.UPLOAD_SO_PARTIAL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: mappingConstants.UPLOAD_SO_FAILURE, error };
  }
}

function onHideShow(isbool) {
  return {
    type: mappingConstants.DIALOG_REQUEST,
    isbool,
  };
}

function onHideShoww(isbool) {
  return {
    type: mappingConstants.DIALOGUE_REQUEST,
    isbool,
  };
}

function onHideShowError(isbool) {
  return {
    type: mappingConstants.ERROR_DIALOG_REQUEST,
    isbool,
  };
}

function loadingData(isbool) {
  return {
    type: mappingConstants.LOADING_REQUEST,
    isbool,
  };
}
