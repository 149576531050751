import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { ToastContainer, toast } from 'react-toastify';
import { SqlEditorActions } from '../../actions/index';
import { TabView, TabPanel } from 'primereact/tabview';
import Switch from '@material-ui/core/Switch';
import Moment from 'react-moment';
import tableRequest from '../../models/tableRequest.js';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';




class SqlEditor extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);

        this.getHeader = this.getHeader.bind(this);
        this.getRowsData = this.getRowsData.bind(this);
        this.getKeys = this.getKeys.bind(this);
        this.state = {
            Script: '',
            ScriptType: '',
            formError: {
                Script: '',
                ScriptType: ''
            }
        };
    }

    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }

    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        switch (name) {
            case 'Script':
                if (!value || value.length < 1) {
                    IsValid = false;
                    errors.Script = "Please enter Sql query";
                } else errors.Script = ''
                break;
            case 'ScriptType':
                if (!value || value.length < 1) {
                    IsValid = false;
                    errors.ScriptType = "Please enter Sql query";
                } else errors.ScriptType = ''
                break;
            default:
                break;
        }

        return IsValid;
    }

    resetForm() {
        this.setState({
            Script: '',
            ScriptType: '',
            formError: {
                Script: '',
                ScriptType: ''
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }

    submitForm(event) {
        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
            return isfrmvalid;
        });

        if (isfrmvalid) {
            const formData = {
                Script: this.state.Script,
                ScriptType: this.state.ScriptType
            }
            this.props.runScript(formData);
        }
    }

    getKeys = function () {
        return Object.keys(this.props.data ? this.props?.data[0] : "");
    }

    getHeader = function () {
        var keys = this.getKeys();
        return keys.map((key, index) => {
            return <th key={key}>{key.toUpperCase()}</th>
        })
    }

    getRowsData = function () {
        const RenderRow = (props) => {
            return props.keys.map((key, index) => {
                return <td key={props.data[key]}>{props.data[key]}</td>
            })
        }

        var items = this.props.data ? this.props.data : null;
        var keys = this.getKeys();
        return items ? items.map((row, index) => {
            return <tr scope="row" key={index}><RenderRow key={index} data={row} keys={keys} /></tr>
        }) : null
    }

    render() {


        return (
            <div className="datatable-filter-demo">
                <ToastContainer />

                <div className="row">
                    <div className="col-md-6 pt-2">

                        <FormControl variant="outlined" size="small" className="formControl col-md-12">
                            <InputLabel id="demo-simple-select-outlined-label">Select Script Type*</InputLabel>
                            <Select onChange={this.handleChange} name="ScriptType" value={this.state.ScriptType} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Script Type">
                                <MenuItem>
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Query">Query</MenuItem>
                                <MenuItem value="ExecuteNonQuery">ExecuteNonQuery</MenuItem>
                            </Select>
                        </FormControl>
                        {this.state.formError.ScriptType !== '' ?
                            <div className='error'>{this.state.formError.ScriptType}</div> : ''}

                        <div className="my-3">Sql Editor</div>
                        <form onSubmit={this.submitForm}>
                            <TextField id="outlined-multiline-static" onChange={e => { this.handleChange(e) }}
                                multiline rows={15} value={this.state.Script} variant="outlined" name="Script" />
                            {this.state.formError.Script !== '' ?
                                <div className='error'>{this.state.formError.Script}</div> : ''}

                            <div className="col-md-6 pt-3">
                                <Button label="Submit" style={{ marginLeft: "45vw", marginTop: "5vh" }} iconPos="right" icon="pi pi-send" ></Button>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-6 pt-2">
                        <div className="table-header"><div className="col-md-12"><h4>Result Details</h4></div></div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover ">
                                <thead className="thead-dark">
                                    <tr>{this.getHeader()}</tr>
                                </thead>
                                <tbody>
                                    {this.getRowsData()}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div >
        )

    }

}


const mapStateToProps = (state) => {
    //console.log('state.SqlEditor.items', state.SqlEditor.items);
    return {
        data: state.SqlEditor.items
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        runScript: script => dispatch(SqlEditorActions.executeScript(script))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SqlEditor)

