import { authHeader, handleResponse } from "../helpers";
export const loginService = {
  checkUser,
  DeleteAccountUser
};

function checkUser(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Login/CheckUser`,
    requestOptions
  ).then(handleResponse);
}

function DeleteAccountUser(data) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${process.env.REACT_APP_API_URL}Login/AccountDeleteUserMobile`, requestOptions)
    .then(handleResponse);
}
