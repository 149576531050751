import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import { FileUpload } from "primereact/fileupload";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Dropdown } from "primereact/dropdown";
import { ToastContainer, toast } from "react-toastify";
import { roleMasterActions, dealerMasterActions, userMasterActions } from "../../actions";
import tableRequest from "../../models/tableRequest";
import Switch from "@material-ui/core/Switch";
import { Calendar } from "primereact/calendar";
import { CSVLink } from "react-csv";
import { event } from "jquery";
// import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
// import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
// import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

class dealerMaster extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.exportCSV = this.exportCSV.bind(this);
    this.FilterData = this.FilterData.bind(this);
    this.tblResponse = new tableRequest();
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
    this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.getStateList = this.getStateList.bind(this);
    //    this.getFirmList = this.getFirmList.bind(this);
    this.getCityList = this.getCityList.bind(this);
    this.getDistrictList = this.getDistrictList.bind(this);
    this.setImagePath = this.setImagePath.bind(this);
    this.setImage = this.setImage.bind(this);
    this.setImagePath2 = this.setImagePath2.bind(this);
    this.setImage1 = this.setImage1.bind(this);
    // this.setImgPassbook = this.setImgPassbook.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);

    this.state = {
      FromDate: new Date(),
      ToDate: new Date(),
      IsAdd: true,
      IsEdit: true,
      first: 0,
      isImage: false,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
      buttonLabel: "Submit",
      switchAction: false,
      formHeading: "Add Dealer Master",
      GstCertificate: "",
      AadharDoc1: "",
      PanNoDoc: "",
      WorkshopImg: "",
      DealerId: 0,
      pdf: "",
      RoleId: "3",
      NatureFirmId: 0,
      PanNo: "",
      AadharNo: "",
      UpiAddress: "",
      IfscCode: "",
      AccountNo: "",
      WorkshopName: "",
      District: 0,
      Address: "",
      BillingSameAsShipping: false,
      CityId: 0,
      isEditing: false,
      compId: 0,
      UserTypeId: "",
      TransportationModeId: 0,
      CountryData: [{ value: 1, label: "India" }],
      UserFirmData: [
        { value: 1, label: "Private Ltd" },
        { value: 2, label: "Ltd" },
        { value: 3, label: "Proprietorship" },
        { value: 4, label: "Partnership" },
      ],
      DealerName: "",
      FirmName: "",
      DistFirmName: "",
      UserName: "",
      CompanyName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      Nature_Firm: "",
      GstNo: "",
      //GstCertificate: '',
      address: "",
      Pincode: "",
      country: "",
      state: "",
      city: "",
      //CityShip: [],
      CityBill: [],
      IsActive: true,
      CreatedDate: null,
      UpdatedDate: null,
      formError: {
        PanNo: "",
        AadharNo: "",
        UpiAddress: "",
        IfscCode: "",
        AccountNo: "",
        WorkshopName: "",
        District: "",
        FirmName: "",
        DistFirmName: "",
        UserName: "",
        CompanyName: "",
        UserTypeId: "",
        Address: "",
        country: "",
        state: "",
        city: "",
        Pincode: "",
        GstCertificate: "",
        AadharDoc1: "",
        PanNoDoc: "",
        WorkshopImg: "",
        // PassbookDoc: "",
        GstNo: "",
        Email: "",
        MobileNo: "",
        Password: "",
        ConfirmPassword: "",
        RoleId: "",
        CityId: "",
        NatureFirmId: "",
        TransportationModeId: "",
      },
    };
  }
  componentDidMount() {
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - 30);
    this.tblResponse.FromDate = date.toLocaleDateString();
    this.tblResponse.ToDate = new Date().toLocaleDateString();
    this.setState({
      FromDate: date,
      ToDate: new Date(),
    });
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    this.props.fetchApi(this.tblResponse);
    this.props.getRoleDDL(2);
    // this.props.getTransportationModeDDL();
    // const userId = JSON.parse(sessionStorage.getItem("UserData"));
    // this.props.getRoleDDL(userId);
    // this.props.getDistrictDDL
  }

  //
  // set Image Path
  setImagePath(event) {
    let errors = this.state.formError;
    errors.GstCertificate = "";
    // errors.AadharDoc1 = "";
    // errors.PanNoDoc= "";

    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              GstCertificate: response.Source,
              // AadharDoc1: response.Source,
              // PanNoDoc: response.Source,
              pdf: "",
              isImage: true,
            });
            if (response.Source.toString().includes(".pdf")) {
              this.setState({
                pdf: event.files[0].name,
                isImage: false,
              });
            }
          } else if (response.ResponseCode === "400") {
            errors.GstCertificate = "Only image format is accepted.";
            // errors.AadharDoc1= "Only image format is accepted.";
            // errors.PanNoDoc="Only image format is accepted.";
            this.setState({
              GstCertificate: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }
  setImage(event) {
    let errors = this.state.formError;

    errors.AadharDoc1 = "";

    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              AadharDoc1: response.Source,

              pdf: "",
              isImage: true,
            });
            if (response.Source.toString().includes(".pdf")) {
              this.setState({
                pdf: event.files[0].name,
                isImage: false,
              });
            }
          } else if (response.ResponseCode === "400") {
            errors.AadharDoc1 = "Only image format is accepted.";

            this.setState({
              AadharDoc1: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }
  setImage1(event) {
    let errors = this.state.formError;

    errors.WorkshopImg = "";

    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              WorkshopImg: response.Source,
              pdf: "",
              isImage: true,
            });
            if (response.Source.toString().includes(".pdf")) {
              this.setState({
                pdf: event.files[0].name,
                isImage: false,
              });
            }
          } else if (response.ResponseCode === "400") {
            errors.WorkshopImg = "Only image format is accepted.";
            this.setState({
              WorkshopImg: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  // setImgPassbook(event) {
  //   let errors = this.state.formError;

  //   errors.PassbookDoc = "";

  //   if (event.xhr) {
  //     if (event.xhr.response) {
  //       const response = JSON.parse(event.xhr.response);
  //       if (response) {
  //         if (response.ResponseCode === "200") {
  //           this.setState({
  //             PassbookDoc: response.Source,
  //             pdf: "",
  //             isImage: true,
  //           });
  //           if (response.Source.toString().includes(".pdf")) {
  //             this.setState({
  //               pdf: event.files[0].name,
  //               isImage: false,
  //             });
  //           }
  //         } else if (response.ResponseCode === "400") {
  //           errors.PassbookDoc = "Only image format is accepted.";
  //           this.setState({
  //             PassbookDoc: "",
  //           });
  //         } else {
  //           // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
  //         }
  //       } else {
  //         //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
  //       }
  //     } else {
  //       //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
  //     }
  //   } else {
  //     //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
  //   }
  // }

  setImagePath2(event) {
    let errors = this.state.formError;

    errors.PanNoDoc = "";

    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              PanNoDoc: response.Source,
              pdf: "",
              isImage: true,
            });
            if (response.Source.toString().includes(".pdf")) {
              this.setState({
                pdf: event.files[0].name,
                isImage: false,
              });
            }
          } else if (response.ResponseCode === "400") {
            errors.PanNoDoc = "Only image format is accepted.";
            this.setState({
              PanNoDoc: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  ///end image
  componentDidUpdate(prevProps) {
    if (this.props.UserData) {
      if (prevProps.UserData !== this.props.UserData) {
        // this.props.myProp has a different value
        if (this.props.UserData) {
          var e = this.props.UserData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    this.props.inActiveRow(this.state.UserId, this.state.switchAction);
  };

  FilterData() {
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
    this.props.fetchApi(this.tblResponse);
  }

  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;

      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  handleChangeDate(event) {
    debugger
    const { name, value } = event.target;
    switch (name) {
      case 'FromDate':
        if (value === null || !(value instanceof Date)) {
            this.formIsValid = false;
            // this.refs.Messchild.showError("Please select from date.");
            this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select from date.', life: 3000 });

            return;
        }
        else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
            this.formIsValid = false;
            this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
            // this.refs.Messchild.showError("Please enter only date.");
            return;
        }
        else if ((value instanceof Date) && (this.state.ToDate instanceof Date)) {
            const startDate = value;
            const endDate = this.state.ToDate;
            if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                this.formIsValid = false;
                this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'From date must be less than to date.', life: 3000 });
                // this.refs.Messchild.showError("From date must be less than to date.");
                return;
            }
        }

        break;
    case 'ToDate':
        if (value === null || !(value instanceof Date)) {
            this.formIsValid = false;
            this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select to date.', life: 3000 });
            // this.refs.Messchild.showError("Please select to date.");
            return;
        }
        else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
            this.formIsValid = false;
            this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
            // this.refs.Messchild.showError("Please enter only date.");
            return;
        }
        else if ((value instanceof Date) && (this.state.FromDate instanceof Date)) {
            const startDate = this.state.FromDate;
            const endDate = value;
            if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                this.formIsValid = false;
                this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'To date must be greater than from date.', life: 3000 });
                // this.refs.Messchild.showError("To date must be greater than from date.");
                return;
            }
        }

        break;
    default:
        break;
    }

    this.setState({ [name]: value });
  }

  getStateList(event, type) {
    //this.handleChange(event)
    if (type == "B") {
      this.setState({ country: event.target.value });
      this.props.getStateDDL(event.target.value);
    }
  }
  getCityList(event, type, flag) {
    if (flag == "i") {
      if (type == "B") {
        this.setState({ state: event.target.value });
        this.props.getBCityDDL(event.target.value);
      }
    } else {
      if (type == "B") {
        this.setState({ state: event });
        this.props.getBCityDDL(event);
      }
    }
  }

  //

  getDistrictList(event, type, flag) {
    if (flag == "i") {
      if (type == "D") {
        this.setState({ state: event.target.value });
        this.props.getDistrictDDL(event.target.value);
      }
    } else {
      if (type == "D") {
        this.setState({ state: event });
        this.props.getDistrictDDL(event);
      }
    }
  }

  // getFirmList(event) {
  //   this.setState({ NatureFirmId: event.target.value });
  //   this.props.getFirmDDL(event.target.value);
  // }

  UpdatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    }
  }

  CreatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
    }
  }
  validateForm(value, name) {
    debugger
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "DealerName":
        if (value.length < 1 && this.state.RoleId == 5) {
          IsValid = false;
          errors.DealerName = "Please enter Dealer Name.";
        } else errors.DealerName = "";
        break;
      // case "FirmName":
      //   if (!value || (value.length < 1 && this.state.RoleId == 2)) {
      //     IsValid = false;
      //     errors.FirmName = "Please enter Firm Or Shop Name.";
      //   } else errors.FirmName = "";
      //   break;
      // case "DistFirmName":
      //   if (!value || (value.length < 1 && this.state.RoleId == 2)) {
      //     IsValid = false;
      //     errors.DistFirmName = "Please enter Distributor Firm Or Shop Name.";
      //   } else errors.DistFirmName = "";
      //   break;
      case "UserName":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.UserName = "Please enter User Name.";
        } else errors.UserName = "";
        break;
      // case "GstCertificate":
      //   if (!value && this.state.RoleId == 2) {
      //     IsValid = false;
      //     errors.GstCertificate = "Please select GST Certificate.";
      //   } else errors.GstCertificate = "";
      //   break;
      // case "GstNo":
      //   if (!value && this.state.RoleId == 2) {
      //     IsValid = false;
      //     errors.GstNo = "Please select GST No.";
      //   } else errors.GstNo = "";
      //   break;
      case "AadharDoc1":
        if (!value && this.state.RoleId == 2) {
          IsValid = false;
          errors.AadharDoc1 = "Please select Aadhar Image.";
        } else errors.AadharDoc1 = "";
        break;

      case "PanNoDoc":
        if (!value && (this.state.RoleId == 5 || this.state.RoleId == 2)) {
          IsValid = false;
          errors.PanNoDoc = "Please select PAN Image.";
        } else errors.PanNoDoc = "";
        break;
      // case "WorkshopImg":
      //   if (!value) {
      //     IsValid = false;
      //     errors.WorkshopImg = "Please select WorkShop Image.";
      //   } else errors.WorkshopImg = "";
      //   break;
      // case "PassbookDoc":
      //   if (!value) {
      //     IsValid = false;
      //     errors.PassbookDoc = "Please select Passbook Image.";
      //   } else errors.PassbookDoc = "";
      //   break;

      // case "TransportationModeId":
      //   if (!value) {
      //     IsValid = false;
      //     errors.TransportationModeId = "Please select Transportation Mode.";
      //   } else errors.TransportationModeId = "";
      //   break;

      // case "NatureFirmId":
      //   if (!value) {
      //     IsValid = false;
      //     errors.NatureFirmId = "Please select Nature Firm .";
      //   } else errors.NatureFirmId = "";
      //   break;

      // case "GstNo":
      //   var gstinformat = new RegExp(
      //     "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
      //   );
      //   if (!value && value.length < 1) {
      //     IsValid = false;
      //     errors.GstNo = "Please enter GST Number.";
      //   } else if (!value && value.length >= 1 && !gstinformat.test(value)) {
      //     IsValid = false;
      //     errors.GstNo =
      //       "GST Identification Number is not valid. It should be in this 11AAAAA1111Z1A1 format.";
      //   } else errors.GstNo = "";
      //   break;
      case "PanNo":
        debugger;
        var panFormat = new RegExp("^[A-Z]{5}[0-9]{4}[A-Z]{1}$");
        if (!value && this.state.RoleId != 3) {
          IsValid = false;
          errors.PanNo = "Please enter PAN Number.";
        } else if (value && value.length >= 1 && !panFormat.test(value)) {
          IsValid = false;
          errors.PanNo =
            "PAN Number is not valid. It should be in this format: AAAAA1111A";
        } else {
          errors.PanNo = "";
        }
        break;
      case "AadharNo":
        var aadharFormat = new RegExp("^[2-9]{1}[0-9]{11}$");
        if (!value && value.length < 1 && this.state.RoleId == 2) {
          IsValid = false;
          errors.AadharNo = "Please enter Aadhaar Number.";
        } else if (!value && value.length >= 1 && !aadharFormat.test(value)) {
          IsValid = false;
          errors.AadharNo =
            "Aadhaar Number is not valid. It should be a 12-digit number starting with a digit from 2 to 9.";
        } else {
          errors.AadharNo = "";
        }
        break;
      // case "UPiAddress":
      //   var upiFormat = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$");
      //   if (value.length < 1) {
      //     IsValid = false;
      //     errors.UPiAddress = "Please enter UPI ID.";
      //   } else if (value.length >= 1 && !upiFormat.test(value)) {
      //     IsValid = false;
      //     errors.UPiAddress =
      //       "UPI ID is not valid. It should be in email format (e.g., example@upi).";
      //   } else {
      //     errors.UPiAddress = "";
      //   }
      //   break;

      case "Email":
        if (!value && value.length < 1) {
          IsValid = false;
          errors.Email = "Please enter email-id.";
        } else if (!value && value.length >= 1) {
          /* eslint-disable */
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.Email = "Please enter valid email-id.";
          } else errors.Email = "";
          /* eslint-enable */
        } else errors.Email = "";
        break;
      case "MobileNo":
        if (!value && value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please enter mobile no.";
        } else if (!value && value.length >= 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = true;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = "";
        } else errors.MobileNo = "";
        break;
      case "Password":
        if (!value && value.length < 1) {
          IsValid = false;
          errors.Password = "Please enter password.";
        } else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,20}$/)) {
          IsValid = false;
          errors.Password =
            "Password should be minimum 4 characters long, 1 uppercase & 1 lowercase character with 1 number.";
        } else if (
          !value &&
          value.length >= 1 &&
          this.state.ConfirmPassword.length >= 1
        ) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (!(value === this.state.ConfirmPassword)) {
            IsValid = false;
            errors.Password = "password does not match";
          } else errors.Password = "";
        } else errors.Password = "";
        break;

      case "ConfirmPassword":
        if (!value && value.length < 1) {
          IsValid = false;
          errors.ConfirmPassword = "Please enter confirm password.";
        } else if (
          !value &&
          value.length >= 1 &&
          this.state.Password.length >= 1
        ) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (value !== this.state.Password) {
            IsValid = false;
            errors.ConfirmPassword =
              "New password and Confirm password should be same.";
          }
        } else if (
          !value &&
          value.length >= 1 &&
          this.state.Password.length >= 1
        ) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (!(value === this.state.Password)) {
            IsValid = false;
            errors.ConfirmPassword = "Confirm password does not match";
          } else errors.ConfirmPassword = "";
        } else errors.ConfirmPassword = "";
        break;

      case "city":
        if (!value && (this.state.RoleId == 5 || this.state.RoleId == 2)) {
          IsValid = false;
          errors.city = "Please select city";
        } else errors.city = "";
        break;
      case "state":
        if (!value && (this.state.RoleId == 5 || this.state.RoleId == 2)) {
          IsValid = false;
          errors.state = "Please select state";
        } else {
          errors.state = "";
        }
        break;

      case "Address":
        if (!value && value.length < 1 && (this.state.RoleId == 5 || this.state.RoleId == 2)) {
          IsValid = false;
          errors.Address = "Please enter Address";
        } else errors.Address = "";
        break;
      case "Pincode":
        if (!value && value.length < 1 && (this.state.RoleId == 5 || this.state.RoleId == 2)) {
          IsValid = false;
          errors.Pincode = "Please enter Pincode";
        } else errors.Pincode = "";
        break;

      case "country":
        if (!value && (this.state.RoleId == 5 || this.state.RoleId == 2)) {
          IsValid = false;
          errors.country = "Please select Country";
        } else errors.country = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  getDropValueOfRole() {
    let role = "";
    this.props.UserRoleData.filter((x) => {
      if (x.value === this.state.RoleId) {
        role = x.label;
      }
    });

    return role;
  }
  getDropValueOfCity() {
    let city = "";
    this.props.UserCityData.filter((x) => {
      if (x.value === this.state.CityId.toString()) {
        city = x.label;
      }
    });

    return city;
  }

  // getDropValueOfFirm() {
  //   let firmName = "";
  //   this.props.UserFirmData.filter((x) => {
  //     if (x.value === this.state.NatureFirmId.toString()) {
  //       firmName = x.label;
  //     }
  //   });

  //   return firmName;
  // }
  getDropValueOfUserType() {
    let usertype = "";
    this.state.CountryData.filter((x) => {
      if (x.value === this.state.Ship_Country) {
        usertype = x.label;
      }
    });

    return usertype;
  }

  submitForm(event) {
    ;
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });
    if (isfrmvalid) {
      // ;
      // let roleId = this.getDropValueOfRole();
      const formData = {
        UserId: this.state.UserId,
        UserType: "2",
        RoleId: this.state.RoleId,
        //UserName: this.state.DealerName,
        FirmName: this.state.FirmName,
        DistFirmName: this.state.DistFirmName,
        UserName: this.state.UserName,
        Email: this.state.Email,
        MobileNo: this.state.MobileNo,
        Password: this.state.Password,
        IsActive: this.state.IsActive,
        CreatedDate:
          this.state.DealerId > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.DealerId > 0 ? this.getCurrentDateMMDDYYY() : null,
        DealerId: this.state.DealerId,
        NatureFirmId: this.state.NatureFirmId,
        PanNo: this.state.PanNo,
        AadharNo: this.state.AadharNo,
        UpiAddress: this.state.UpiAddress,
        IfscCode: this.state.IfscCode,
        AccountNo: this.state.AccountNo,
        WorkshopName: this.state.WorkshopName,
        District: this.state.District,
        GstNo: this.state.GstNo,
        GstCertificate: this.state.GstCertificate,
        PanNoDoc: this.state.PanNoDoc,
        WorkshopImg: this.state.WorkshopImg,
        // PassbookDoc: this.state.PassbookDoc,
        AadharDoc1: this.state.AadharDoc1,
        Address: this.state.Address,
        Pincode: this.state.Pincode,
        country: this.state.country,
        state: this.state.state,
        city: this.state.city,

        // TransportationModeId: this.state.TransportationModeId,
      };
      ;
      if (formData.UserId > 0) {
        this.props.updateDealer(formData);
      } else {
        //console.log(formData);
        this.props.createDealer(formData);
      }
      if (!this.props.showDialog) {
        this.resetForm();
      }
      setTimeout(() => {
        this.props.fetchApi(this.tblResponse);
      }, 3000);
    }
  }

  // getRoleDDL(event) {
  //     this.props.getRoleDDL();
  // }
  resetForm() {
    this.setState({
      isEditing: false,
      UserId: 0,
      DealerId: 0,
      NatureFirmId: "",
      PanNo: "",
      AadharNo: "",
      UpiAddress: "",
      WorkshopName: "",
      District: "",
      GstNo: "",
      GstCertificate: "",
      AadharDoc1: "",
      PanNoDoc: "",
      WorkshopImg: "",
      // PassbookDoc: "",
      Address: "",
      Pincode: "",
      country: "",
      state: "",
      city: "",
      CreatedDate: "",
      UpdatedDate: "",
      compId: "",
      UserTypeId: "",
      DealerName: "",
      FirmName: "",
      DistFirmName: "",
      UserName: "",
      CompanyName: "",
      RoleId: "2",
      CityId: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      IsActive: true,
      formError: {
        DealerName: "",
        FirmName: "",
        DistFirmName: "",
        UserName: "",
        CompanyName: "",
        UserTypeId: "",
        Email: "",
        MobileNo: "",
        Password: "",
        ConfirmPassword: "",
        PanNo: "",
        AadharNo: "",
        UpiAddress: "",
        WorkshopName: "",
        District: "",
        Address: "",
        Pincode: "",
        country: "",
        state: "",
        city: "",
        GstCertificate: "",
        AadharDoc1: "",
        PanNoDoc: "",
        WorkshopImg: "",
        // PassbookDoc: "",
        GstNo: "",
        RoleId: "",
        CityId: "",
        NatureFirmId: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
    this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
  }

  onPage(event) {

    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }
  photoBodyTemplate(e) {
    return <img src={e.GstCertificate} width="50px" />;
    // return <img src={e.PanNoDoc} width="50px" />;
    // return <img src={e.AadharDoc1} width="50px" />;
    // return <img src={e.WorkshopImg} width="50px" />;
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }
  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add User" });
  }
  statusBodyTemplate(rowData) {
    if (rowData) {
      return (
        <Checkbox
          disabled
          checked={rowData.IsActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      );
    }
  }
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }
  actionBodyTemplate(rowData) {
    const editMode = (e) => {
      debugger;
      this.props.onHideShow(true);
      this.props.getStateDDL(1);
      this.props.getRoleDDL(2);
      this.getCityList(rowData.State, "B", "");
      this.getDistrictList(rowData.State, "D", "");
      this.setState({
        isImage: true,
        UserId: rowData.UserId,
        isEditing: true,
        buttonLabel: "Update",
        formHeading: "Update User",
        NatureFirmId: rowData.NatureFirmId,
        TransportationModeId: rowData.TransportationModeId,
        IsActive: rowData.IsActive,
        Password: rowData.Password,
        ConfirmPassword: rowData.Password,
        CreatedDate: rowData.CreatedDate,
        DealerId: rowData.DealerId,
        //DealerName: rowData.DealerName,
        UserName: rowData.UserName,
        FirmName: rowData.FirmName,
        DistFirmName: rowData.DistFirmName,
        //UserName: rowData.UserName,
        AadharNo: rowData.AadharNo,
        UpiAddress: rowData.UpiAddress,
        IfscCode: rowData.IfscCode,
        AccountNo: rowData.AccountNo,
        WorkshopName: rowData.WorkshopName,
        District: rowData.District,
        Nature_Firm: rowData.Nature_Firm,
        GstNo: rowData.GstNo,
        GstCertificate: rowData.GstCertificate,
        WorkshopImg: rowData.WorkshopImg,
        // PassbookDoc: rowData.PassbookDoc,
        AadharDoc1: rowData.AadharDoc1,
        PanNo: rowData.PanNo,
        PanNoDoc: rowData.PanNoDoc,
        Email: rowData.Email,
        MobileNo: rowData.MobileNo,
        Address: rowData.Address,
        Pincode: rowData.Pincode,
        country: 1,
        state: rowData.State,
        city: rowData.City,
        RoleId: rowData.RoleId.toString(),
      });
    };
    const switchHandleChange = (e) => {
      ;
      this.setState({
        switchAction: !rowData.IsActive,
        UserId: rowData.UserId,
      });
      this.props.onHideShowIsActive(true);
    };

    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true && rowData.RoleId !== 5 ? (
            <div>
              <Switch
                checked={rowData.IsActive}
                color="primary"
                name={rowData.UserId}
                onChange={switchHandleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Button icon="pi pi-pencil" onClick={editMode} />
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  exportCSV() {
    ;
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
    this.tblResponse.First = -1;
    this.tblResponse.PageSize = -1;
    this.props.exportData(this.tblResponse);
    setTimeout(() => {
      this.csvLink.current.element.click();
    }, 4000);
  }

  render() {
    const renderFooter = (name) => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={this.switchToggleConfirm}
            autoFocus
          />
        </div>
      );
    };

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />
        <div className="table-header row mb-3">
          <div className="col-md-2">
            <h4>Mobile User Master</h4>
          </div>
          <div className="col-md-2 text-right">
            <div>
              <Calendar
                placeholder="From Date"
                name="FromDate"
                showIcon="true"
                value={this.state.FromDate}
                onChange={this.handleChange}
              ></Calendar>
            </div>
          </div>
          <div className="col-md-2 text-center">
            <div>
              <Calendar
                placeholder="To Date"
                name="ToDate"
                showIcon="true"
                value={this.state.ToDate}
                onChange={this.handleChange}
                minDate={this.state.FromDate} // Disable all previous dates based on From Date
              ></Calendar>
            </div>
          </div>
          <div className="col-md-2 more-button1">
            <button
              type="button"
              onClick={this.FilterData}
              className="btn btn-primary p-2 pl-3 pr-3 mr-2"
            >
              Submit
            </button>
            {/* <button
              onClick={this.exportCSV}
              className="btn btn-success p-2 pl-3 pr-3 ml-2 buttone1"
            >
              Export
            </button> */}
            {/* <ExcelFile
                  filename={"UserMasterReport"}
                  element={<Button hidden ref={this.csvLink} />}
                >
                  <ExcelSheet
                    data={this.props.List}
                    name={"Delar_Master_Report"}
                  >
                    <ExcelColumn label="Role Name" value="RoleName" />
                    <ExcelColumn label="Dealer" value="isDealer" />
                    <ExcelColumn label="Dealer Id" value="DealerId" />
                    <ExcelColumn label="Country " value="CountryId" />
                    <ExcelColumn label="UserName" value="DealerName" />
                    <ExcelColumn label="Email" value="Email" />
                    <ExcelColumn label="Mobile No" value="MobileNo" />
                    <ExcelColumn label="Pan No" value="PanNo" />
                    <ExcelColumn label="Gst No." value="GstNo" />
                    <ExcelColumn label="GstCertificate." value="GstCertificate" formula />

                    <ExcelColumn label="country" value="country" />
                    <ExcelColumn label="state" value="state" />
                    <ExcelColumn label="Pincode" value="Pincode" />
                    
                    <ExcelColumn label="District Name" value="DistrictName" />
                    
                    <ExcelColumn label="StateName" value="StateName" />
                    <ExcelColumn label="AadharNo" value="AadharNo" />
                    <ExcelColumn label="WorkshopName" value="WorkshopName" />
                    <ExcelColumn label="Dealer FirmName" value="FirmName" />
                    <ExcelColumn label="DistFirmName" value="DistFirmName" />
                    <ExcelColumn label="UpiAddress" value="UpiAddress" />

                    <ExcelColumn label="address" value="address" />


                  </ExcelSheet>
                </ExcelFile> */}
            <CSVLink
              hidden
              data={this.props.List}
              headers={this.state.headers}
              filename={"Mobile User Report.csv"}
              target="_blank"
            >
              <Button hidden ref={this.csvLink} />
            </CSVLink>
          </div>

          <div className="col-md-4 text-right">
            <div>
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  type="search"
                  onInput={(e) => this.searchFilter(e)}
                  placeholder="Global Search"
                />
              </span>

              {this.state.IsAdd === true ? (
                <Button
                  className="ml-3"
                  label="Add User"
                  icon="pi pi-plus-circle"
                  onClick={this.openDialog}
                />
              ) : (
                <Button
                  className="ml-3"
                  label="Add User"
                  icon="pi pi-plus-circle"
                  // style={{ display: "none" }}
                  onClick={this.openDialog}
                />
              )}
            </div>
          </div>
        </div>

        <div className="card">
          <DataTable
            value={this.props.UserData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="UserName" header="Name" sortable></Column>
            <Column field="RoleName" header="User Type" sortable></Column>

            <Column field="Email" header="Email" sortable></Column>
            <Column field="MobileNo" header="Mobile Number" sortable></Column>
            {/* <Column field="GstNo" header="GST Number" sortable></Column> */}
            <Column field="StateName" header="State" sortable></Column>
            <Column field="DistrictName" header="District" sortable></Column>
            <Column field="CityName" header="City" sortable></Column>
            <Column field="Pincode" header="Pincode" sortable></Column>

            {/* <Column field="GstCertificate" header="GstCertificate" style={{ width: '200px' }} body={this.photoBodyTemplate}></Column> */}
            {this.state.IsEdit === true ? (
              <Column
                field="Action"
                header="Action"
                className="rolemaster-button1"
                body={this.actionBodyTemplate}
              ></Column>
            ) : (
              <Column
                field="Action"
                header="Action"
                style={{ width: "120px" }}
                style2={{ display: "none" }}
              ></Column>
            )}
          </DataTable>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          <form autoComplete="off">
            <div className="row">
              <div className="col-md-6 pt-3">
                <FormControl
                  variant="outlined"
                  size="small"
                  className="formControl col-md-12"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Type*
                  </InputLabel>
                  <Select
                    disabled={this.state.isEditing}
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                    name="RoleId"
                    value={this.state.RoleId}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Select Type*"
                  >
                    <MenuItem>
                      <em>Select Type</em>
                      <span>*</span>
                    </MenuItem>
                    {this.props.UserRoleData &&
                      this.props.UserRoleData.map((key) => (
                        <MenuItem value={key.value}>{key.label}</MenuItem>
                      ))}
                  </Select>
                  {this.state.formError.RoleId !== "" ? (
                    <div className="error">{this.state.formError.RoleId}</div>
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>

              <div className="col-md-6 pt-3">
                <TextField
                  onChange={this.handleChange}
                  disabled={this.state.isEditing}
                  name="UserName"
                  autoComplete="false"
                  value={this.state.UserName}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="User Name*"
                />
                {this.state.formError.UserName !== "" ? (
                  <div className="error">{this.state.formError.UserName}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6 pt-3">
                <TextField
                  onChange={this.handleChange}
                  disabled={this.state.isEditing}
                  name="Email"
                  value={this.state.Email}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Email*"
                />
                {this.state.formError.Email !== "" ? (
                  <div className="error">{this.state.formError.Email}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  disabled={this.state.isEditing}
                  id="standard-basic"
                  size="small"
                  
                  onChange={this.handleChange}
                  variant="outlined"
                  name="MobileNo"
                  value={this.state.MobileNo}
                  className="fullwidth"
                  label="Mobile Number*"
                  type="number" 
                  inputProps={{ maxLength: 10 }} 
                  onInput={(e) => e.target.value = e.target.value.slice(0, 10)} // Further limit length if necessary
                />
                {this.state.formError.MobileNo !== "" ? (
                  <div className="error">{this.state.formError.MobileNo}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  input
                  type="password"
                  autoComplete="new-password"
                  onChange={this.handleChange}
                  name="Password"
                  value={this.state.Password}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Password*"
                />
                {this.state.formError.Password !== "" ? (
                  <div className="error">{this.state.formError.Password}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  input
                  type="password"
                  onChange={this.handleChange}
                  name="ConfirmPassword"
                  value={this.state.ConfirmPassword}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Confirm Password*"
                />
                {this.state.formError.ConfirmPassword !== "" ? (
                  <div className="error">
                    {this.state.formError.ConfirmPassword}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {this.state.RoleId === "2" ||
                this.state.RoleId === "5" ? (
                <>
                  {/* <div className="col-md-6 pt-3">
                    <TextField
                      onChange={this.handleChange}
                      disabled={this.state.isEditing}
                      name="FirmName"
                      autoComplete="false"
                      value={this.state.FirmName}
                      size="small"
                      variant="outlined"
                      className="fullwidth"
                      label="Dealer Firm Name*"
                    />
                    {this.state.formError.FirmName !== "" ? (
                      <div className="error">
                        {this.state.formError.FirmName}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    hidden={
                      this.state.RoleId === "2" || this.state.RoleId === "5"
                        ? false
                        : true
                    }
                    className="col-md-6 pt-3"
                  >
                    <TextField
                      disabled={this.state.isEditing}
                      onChange={this.handleChange}
                      name="DistFirmName"
                      autoComplete="false"
                      value={this.state.DistFirmName}
                      size="small"
                      variant="outlined"
                      className="fullwidth"
                      label="Distributor Firm Name*"
                    />
                    {this.state.formError.DistFirmName !== "" ? (
                      <div className="error">
                        {this.state.formError.DistFirmName}
                      </div>
                    ) : (
                      ""
                    )}
                  </div> */}

                  <div className="col-md-6 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="PanNo"
                      onChange={this.handleChange}
                      value={this.state.PanNo}
                      className="fullwidth"
                      label="Pan Number*"
                    />
                    {this.state.formError.PanNo !== "" ? (
                      <div className="error">{this.state.formError.PanNo}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="AadharNo"
                      onChange={this.handleChange}
                      disabled={this.state.isEditing}
                      value={this.state.AadharNo}
                      className="fullwidth"
                      label="Aadhar Number*"
                      type="number" 
                  inputProps={{ maxLength: 12 }} 
                  onInput={(e) => e.target.value = e.target.value.slice(0, 12)}
                    />
                    {this.state.formError.AadharNo !== "" ? (
                      <div className="error">
                        {this.state.formError.AadharNo}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    hidden={this.state.RoleId === "5" ? false : true}
                    className="col-md-6 pt-3"
                  >
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="UpiAddress"
                      onChange={this.handleChange}
                      value={this.state.UpiAddress}
                      className="fullwidth"
                      label="UPI ID*"
                    />
                    {this.state.formError.UpiAddress !== "" ? (
                      <div className="error">
                        {this.state.formError.UpiAddress}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* Ifscode */}
                  <div
                    hidden={this.state.RoleId === "5" ? false : true}
                    className="col-md-6 pt-3"
                  >
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="IfscCode"
                      onChange={this.handleChange}
                      value={this.state.IfscCode}
                      className="fullwidth"
                      label="IFSC Code*"
                    />
                    {this.state.formError.IfscCode !== "" ? (
                      <div className="error">
                        {this.state.formError.IfscCode}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* AccountNo */}
                  <div
                    hidden={this.state.RoleId === "5" ? false : true}
                    className="col-md-6 pt-3"
                  >
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="AccountNo"
                      onChange={this.handleChange}
                      value={this.state.AccountNo}
                      className="fullwidth"
                      label="Account Number *"
                    />
                    {this.state.formError.AccountNo !== "" ? (
                      <div className="error">
                        {this.state.formError.AccountNo}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* //Add Shop Name */}
                  {/* <div
                    hidden={this.state.RoleId === "5" ? false : true}
                    className="col-md-6 pt-3"
                  >
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="WorkshopName"
                      onChange={this.handleChange}
                      disabled={this.state.isEditing}
                      value={this.state.WorkshopName}
                      className="fullwidth"
                      label="WorkShop Name *"
                    />
                    {this.state.formError.WorkshopName !== "" ? (
                      <div className="error">
                        {this.state.formError.WorkshopName}
                      </div>
                    ) : (
                      ""
                    )}
                  </div> */}

                  {/* ///Shop Name close   */}

                  {/* <div
                    hidden={this.state.RoleId !== "3" ? false : true}
                    className="col-md-6 pt-3"
                  >
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="GstNo"
                      onChange={this.handleChange}
                      disabled={this.state.isEditing}
                      value={this.state.GstNo}
                      className="fullwidth"
                      label="GST Number*"
                    />
                    {this.state.formError.GstNo !== "" ? (
                      <div className="error">{this.state.formError.GstNo}</div>
                    ) : (
                      ""
                    )}
                  </div> */}

                  {/* <div
                    hidden={this.state.RoleId !== "3" ? false : true}
                    className="col-md-6 pt-3"
                  >
                    <label>
                      Upload GST certificate<span>*</span>:
                    </label>
                    <FileUpload
                      name="GstCertificate"
                      mode="basic"
                      url={
                        process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"
                      }
                      auto={true}
                      onUpload={this.setImagePath}
                      disabled={this.state.isEditing}
                      onError={this.onErrorImage}
                      accept="image/*"
                    />
                    {this.state.pdf != "" ? <b>{this.state.pdf}</b> : null}
                    <div className="col-md-12 mb-3">
                      {this.state.GstCertificate && this.state.isImage ? (
                        <div
                          className="p-fileupload-content mt-3 p-1"
                          style={{ display: "table", border: "1px solid #ccc" }}
                        >
                          <div>
                            <img
                              alt="img doc"
                              role="presentation"
                              src={this.state.GstCertificate}
                              width="100"
                            />
                          </div>
                        </div>
                      ) : null}

                      {this.state.formError.GstCertificate !== null ? (
                        <div className="error">
                          {this.state.formError.GstCertificate}
                        </div>
                      ) : null}
                    </div>
                  </div> */}

                  <div className="col-md-6 pt-3">
                    <label>
                      Upload Aadhar Number<span>*</span>:
                    </label>

                    <FileUpload
                      name="AadharDoc1"
                      mode="basic"
                      url={
                        process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"
                      }
                      auto={true}
                      onUpload={this.setImage}
                      onError={this.onErrorImage}
                      accept="image/*"
                    />
                    {this.state.pdf != "" ? <b>{this.state.pdf}</b> : null}
                    <div className="col-md-12 mb-3">
                      {this.state.AadharDoc1 && this.state.isImage ? (
                        <div
                          className="p-fileupload-content mt-3 p-1"
                          style={{ display: "table", border: "1px solid #ccc" }}
                        >
                          <div>
                            <a
                              href={
                                this.state.AadharDoc1 == ""
                                  ? true
                                  : this.state.AadharDoc1
                              }
                              target="_blank"
                            >
                              <img
                                alt="img doc"
                                role="presentation"
                                src={this.state.AadharDoc1}
                                width="200px"
                                height={200}
                              />
                            </a>
                          </div>
                        </div>
                      ) : null}

                      {this.state.formError.AadharDoc1 !== null ? (
                        <div className="error">
                          {this.state.formError.AadharDoc1}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6 pt-3">
                    <label>
                      Upload PAN Number<span>*</span>:
                    </label>
                    <FileUpload
                      name="PanNoDoc"
                      mode="basic"
                      url={
                        process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"
                      }
                      auto={true}
                      onUpload={this.setImagePath2}
                      onError={this.onErrorImage}
                      accept="image/*"
                    />
                    {this.state.pdf != "" ? <b>{this.state.pdf}</b> : null}
                    <div className="col-md-12 mb-3">
                      {this.state.PanNoDoc && this.state.isImage ? (
                        <div
                          className="p-fileupload-content mt-3 p-1"
                          style={{ display: "table", border: "1px solid #ccc" }}
                        >
                          <div>
                            <a
                              href={
                                this.state.PanNoDoc == ""
                                  ? true
                                  : this.state.PanNoDoc
                              }
                              target="_blank"
                            >
                              <img
                                alt="img doc"
                                role="presentation"
                                src={this.state.PanNoDoc}
                                width="200"
                                height={200}
                              />
                            </a>
                          </div>
                        </div>
                      ) : null}
  
                      {this.state.formError.PanNoDoc !== null ? (
                        <div className="error">
                          {this.state.formError.PanNoDoc}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* <div className="col-md-6 pt-3">
                    <label hidden={this.state.RoleId != "3" ? false : true}>
                      Upload Firm Image<span>*</span>:
                    </label>

                    <label hidden={this.state.RoleId === "5" ? false : true}>
                      Upload WorkShop Image<span>*</span>:
                    </label>

                    <FileUpload
                      name="WorkshopImg"
                      mode="basic"
                      url={
                        process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"
                      }
                      auto={true}
                      onUpload={this.setImage1}
                      onError={this.onErrorImage}
                      accept="image/*"
                    />
                    {this.state.pdf != "" ? <b>{this.state.pdf}</b> : null}
                    <div className="col-md-12 mb-3">
                      {this.state.WorkshopImg && this.state.isImage ? (
                        <div
                          className="p-fileupload-content mt-3 p-1"
                          style={{ display: "table", border: "1px solid #ccc" }}
                        >
                          <div>
                            <a
                              href={
                                this.state.WorkshopImg == ""
                                  ? true
                                  : this.state.WorkshopImg
                              }
                              target="_blank"
                            >
                              <img
                                alt="img doc"
                                role="presentation"
                                src={this.state.WorkshopImg}
                                width="200px"
                                height="200px"
                              />
                            </a>
                          </div>
                        </div>
                      ) : null}

                      {this.state.formError.WorkshopImg !== null ? (
                        <div className="error">
                          {this.state.formError.WorkshopImg}
                        </div>
                      ) : null}
                    </div>
                  </div> */}

                  {/*Account No */}
                  {/* <div
                    hidden={this.state.RoleId === "5" ? false : true}
                    className="col-md-6 pt-3"
                  >
                    <label hidden={this.state.RoleId === "5" ? false : true}>
                      Upload Passbook Image<span>*</span>:
                    </label>

                    <FileUpload
                      name="PassbookDoc"
                      mode="basic"
                      url={
                        process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"
                      }
                      auto={true}
                      onUpload={this.setImgPassbook}
                      onError={this.onErrorImage}
                      accept="image/*"
                    />
                    {this.state.pdf != "" ? <b>{this.state.pdf}</b> : null}
                    <div className="col-md-12 mb-3">
                      {this.state.PassbookDoc && this.state.isImage ? (
                        <div
                          className="p-fileupload-content mt-3 p-1"
                          style={{ display: "table", border: "1px solid #ccc" }}
                        >
                          <div>
                            <a
                              href={
                                this.state.PassbookDoc == ""
                                  ? true
                                  : this.state.PassbookDoc
                              }
                              target="_blank"
                            >
                              <img
                                alt="img doc"
                                role="presentation"
                                src={this.state.PassbookDoc}
                                width="200px"
                                height="200px"
                              />
                            </a>
                          </div>
                        </div>
                      ) : null}

                      {this.state.formError.PassbookDoc !== null ? (
                        <div className="error">
                          {this.state.formError.PassbookDoc}
                        </div>
                      ) : null}
                    </div>
                  </div> */}
                  {/* Account close */}

                  <div hidden className="col-md-6 pt-3">
                    <Checkbox
                      name="IsActive"
                      checked={this.state.IsActive}
                      onClick={this.handleChange}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />{" "}
                    <label>Is Active</label>
                  </div>

                  <div className="col-md-12 bordercolor">
                    <fieldset>
                      <div className="row">
                        <legend className="col-md-12 billing-add">
                          Address*
                        </legend>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <FormControl
                                variant="outlined"
                                size="small"
                                className="formControl col-md-12"
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Select Country*
                                </InputLabel>
                                <Select
                                  onChange={(e) => {
                                    this.getStateList(e, "B");
                                  }}
                                  name="country"
                                  value={this.state.country}
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  label="Select Country"
                                >
                                  <MenuItem>
                                    <em>Select Country</em>
                                    <span>*</span>
                                  </MenuItem>
                                  {this.state.CountryData.map((key) => (
                                    <MenuItem value={key.value}>
                                      {key.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {this.state.formError.country !== "" ? (
                                <div className="error">
                                  {this.state.formError.country}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-12">
                              <label>
                                {" "}
                                <span></span>
                              </label>
                              <FormControl
                                variant="outlined"
                                size="small"
                                className="formControl col-md-12 custom-select"
                              >
                                <Dropdown
                                  appendTo={document.getElementById("root")}
                                  value={this.state.city}
                                  //value={this.props.ProductData}
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                  options={this.props.UserBCityData}
                                  optionValue="value"
                                  optionLabel="label"
                                  className="form-control custom-select"
                                  placeholder={"Select City*"}
                                  name="city"
                                  filter
                                />
                              </FormControl>

                              {this.state.formError.city !== "" ? (
                                <div className="error">
                                  {this.state.formError.city}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-12">
                              <label>
                                <span></span>
                              </label>
                              <TextField
                                id="standard-basic"
                                name="Pincode"
                                value={this.state.Pincode}
                                type="number"
                                inputProps={{ maxLength: 6 }} 
                                variant="outlined"
                                size="small"
                                className="fullwidth"
                                onChange={this.handleChange}
                                label="Pincode*"
                                onInput={(e) => e.target.value = e.target.value.slice(0, 6)} // Further limit length if necessary
                              />
                              {this.state.formError.Pincode !== null ? (
                                <div className="error">
                                  {this.state.formError.Pincode}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <FormControl
                                variant="outlined"
                                size="small"
                                className="formControl col-md-12 custom-select"
                              >
                                <Dropdown
                                  appendTo={document.getElementById("root")}
                                  value={this.state.state}
                                  //value={this.props.ProductData}
                                  options={this.props.StateData}
                                  optionValue="value"
                                  optionLabel="label"
                                  className="form-control custom-select"
                                  placeholder={"Select State*"}
                                  name="state"
                                  onChange={(e) => {
                                    this.getCityList(e, "B", "i");
                                    this.getDistrictList(e, "D", "i");
                                  }}
                                  filter
                                />
                              </FormControl>

                              {this.state.formError.state !== "" ? (
                                <div className="error">
                                  {this.state.formError.state}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-12"
                            hidden={this.state.RoleId === "2" ? true : false}>
                              <label>
                                {" "}
                                <span></span>
                              </label>
                              <FormControl
                                variant="outlined"
                                size="small"
                                className="formControl col-md-12 custom-select"
                              >
                                <Dropdown
                                  appendTo={document.getElementById("root")}
                                  value={this.state.District}
                                  //value={this.props.ProductData}
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                  hidden={this.state.RoleId === "2" ? false : true}
                                  options={this.props.DistrictData}
                                  optionValue="value"
                                  optionLabel="label"
                                  className="form-control custom-select"
                                  placeholder={"Select District*"}
                                  name="District"
                                  filter
                                />
                              </FormControl>

                              {this.state.formError.District !== "" ? (
                                <div className="error">
                                  {this.state.formError.District}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-12">
                              <label>
                                <span></span>
                              </label>
                              <TextField
                                id="standard-basic"
                                name="Address"
                                value={this.state.Address}
                                type="text"
                                variant="outlined"
                                size="small"
                                className="fullwidth"
                                onChange={this.handleChange}
                                label="Address*"
                              />
                              {this.state.formError.Address !== null ? (
                                <div className="error">
                                  {this.state.formError.Address}
                                </div>
                              ) : null}
                            </div>

                            {/* distice */}
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-md-12 mt-3 mb-3 text-center">
                {this.state.IsEdit === true ? (
                  <Button
                    onClick={(e) => this.submitForm(e)}
                    label={this.state.buttonLabel}
                    icon="pi pi-check"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12">
                Note: Fields marked as (*) are required
              </div>
            </div>
          </form>
        </Dialog>

        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("data:",state.dealer.Districtddl);
  console.log("data2:", state.dealer.exportitems);

  return {
    UserRoleData: state.user.rollddl,
    UserData: state.dealer.items,
    StateData: state.dealer.stateddl,
    UserBCityData: state.dealer.bcityddl,
    DistrictData: state.dealer.Districtddl,
    //UserSCityData: state.dealer.scityddl,
    UserFirmData: state.role.firmddl,
    // TransportationModeData:
    //   state.dealer.transportationModeddl == undefined
    //     ? []
    //     : state.dealer.transportationModeddl,
    showDialog: state.role.showDialog,
    loading: state.company.loding,
    showIsActiveDialog: state.user.showIsActiveDialog,
    List:
      state.dealer.exportitems == (null || undefined)
        ? []
        : state.dealer.exportitems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => { dispatch(dealerMasterActions.getAll(obj)); },
    createDealer: (user) => dispatch(dealerMasterActions.createDealer(user)),
    updateDealer: (user) => dispatch(dealerMasterActions.updateDealer(user)),
    inActiveRow: (user, isActive) =>
      dispatch(dealerMasterActions.inActiveRow(user, isActive)),
    onHideShow: (isbool) => dispatch(dealerMasterActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(dealerMasterActions.onHideShowIsActive(isbool)),
    getStateDDL: (data) => dispatch(dealerMasterActions.getStateDDL(data)),
    getFirmDDL: (data) => dispatch(dealerMasterActions.getFirmDDL(data)),
    getBCityDDL: (data) => dispatch(dealerMasterActions.getBCityDDL(data)),
    getDistrictDDL: (data) =>
      dispatch(dealerMasterActions.getDistrictDDL(data)),
    getTransportationModeDDL: () =>
      dispatch(dealerMasterActions.getTransportationModeDDL()),

    loadingData: (isLoding) =>
      dispatch(dealerMasterActions.loadingData(isLoding)),
    getRoleDDL: (data) => dispatch(userMasterActions.getRoleDDL(data)),
    exportData: (obj) => {
      dispatch(dealerMasterActions.exportData(obj));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(dealerMaster);
