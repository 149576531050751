import { authHeader, handleResponse } from '../helpers';
export const offerMasterService = {
    createOffer,
    updateOffer,
    fetchOfferData,
    inActiveRow
};

function createOffer(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}OfferMaster/CreateOffer`, requestOptions)
        .then(handleResponse);
}

function updateOffer(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}OfferMaster/UpdateOffer`, requestOptions)
        .then(handleResponse);
}

function fetchOfferData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}OfferMaster/GetOffers`, requestOptions)
        .then(handleResponse);
}

function inActiveRow(OfferId, isActive) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}OfferMaster/InActiveRow/${OfferId}/${isActive}`, requestOptions)
        .then(handleResponse);
}


