import { RedemptionApprovalConstants } from "../constants/RedemptionApprovalConstants";

export function RedemptionApproval(state = {}, action) {
    switch (action.type) {
        case RedemptionApprovalConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        case RedemptionApprovalConstants.DIALOG_REQUEST:
            return {
                ...state,
                showDialog: action.isbool
            };
        case RedemptionApprovalConstants.DIALOGUE_REQUEST:
            return {
                ...state,
                showDialogue: action.isbool
            };
        case RedemptionApprovalConstants.REJECT_DIALOGUE_REQUEST:
            return {
                ...state,
                canceldialog: action.isbool,
            };
        case RedemptionApprovalConstants.DIALOG_ISACTIVE_REQUEST:
            return {
                ...state,
                showIsActiveDialog: action.isbool,
                approvedialog: action.isbool,
                canceldialog: action.isbool,
            };
        case RedemptionApprovalConstants.UPDATE_PAGE_STORE:
            return {
                ...state,
                pages: action.pageList
            };
        case RedemptionApprovalConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case RedemptionApprovalConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case RedemptionApprovalConstants.GETALL_USERPT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case RedemptionApprovalConstants.GETALL_USERPT_SUCCESS:
            return {
                ...state,
                Userpoints: action.data
                //Userpoints: state.Userpoints == null ? [action.data] : [action.data, ...state.Userpoints]

            };
        case RedemptionApprovalConstants.GETALL_USERPT_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case RedemptionApprovalConstants.GETALL_USER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case RedemptionApprovalConstants.GETALL_USER_SUCCESS:
            return {
                ...state,
                Userdataa: action.datas,

            };
        case RedemptionApprovalConstants.GETALL_USER_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case RedemptionApprovalConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case RedemptionApprovalConstants.APPROVE_REQUEST:
            return {
                ...state,
                creating: true,
                activating: true,
                //approvedialog : action.isbool,
            };
        case RedemptionApprovalConstants.APPROVE_SUCCESS:
            return {
                ...state,
                itemss: state.items == null ? [action.data] : [action.data, ...state.items]
            };
        case RedemptionApprovalConstants.APPROVE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case RedemptionApprovalConstants.CANCEL_REQUEST:
            return {
                ...state,
                itemss: state.items.map(item =>
                    item.UserId === action.data.UserId ? { ...item, updating: true } : item
                ),
                activating: true,

                canceldialog: action.isbool,
            };
        case RedemptionApprovalConstants.CANCEL_SUCCESS:
            return {
                ...state,
                itemss: state.items.map(item =>
                    item.UserId === action.data.UserId ? action.data : item
                )
            };
        case RedemptionApprovalConstants.CANCEL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case RedemptionApprovalConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case RedemptionApprovalConstants.DELETE_SUCCESS:
            return {
                ...state,
                itemss: state.items.map(item =>
                    item.RoleId === action.data ? {
                        CompanyId: item.CompanyId,
                        CompanyName: item.CompanyName,
                        roleId: item.roleId,
                        RoleName: item.roleName,
                        RoleDesc: item.RoleDesc,
                        isActive: item.isActive,
                        CreatedDate: item.CreatedDate
                    } : item
                )
            };
        case RedemptionApprovalConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case RedemptionApprovalConstants.GETROLEDDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case RedemptionApprovalConstants.GETROLEDDL_SUCCESS:
            return {
                ...state,
                rollddl: action.datas
            };
        case RedemptionApprovalConstants.GETROLEDDL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case RedemptionApprovalConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case RedemptionApprovalConstants.GET_PAGES_REQUEST:
            return {
                ...state,
                getpages: true
            };
        case RedemptionApprovalConstants.GET_PAGES_SUCCESS:
            return {
                ...state,
                pages: action.pages
            };
        case RedemptionApprovalConstants.GET_PAGES_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case RedemptionApprovalConstants.ISACTIVE_REQUEST:
            return {
                ...state,
                activating: true
            };
        case RedemptionApprovalConstants.ISACTIVE_SUCCESS:
            return {
                ...state,
                itemss: state.items.map(item =>
                    item.RoleId === action.data.RoleId ? {
                        CompanyId: item.CompanyId,
                        CompanyName: item.CompanyName,
                        RoleId: item.RoleId,
                        RoleName: item.RoleName,
                        RoleDesc: item.RoleDesc,
                        TotalRows: item.TotalRows,
                        IsActive: action.data.IsActive,
                        CreatedDate: item.CreatedDate
                    } : item
                )
            };
        case RedemptionApprovalConstants.ISACTIVE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case RedemptionApprovalConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case RedemptionApprovalConstants.CREATE_SUCCESS:
            return {
                ...state,
                itemss: action.data
            };
        case RedemptionApprovalConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }
}