export const offerMasterConstants = {
    CREATE_REQUEST: 'OFFER_CREATE_REQUEST',
    CREATE_SUCCESS: 'OFFER_CREATE_SUCCESS',
    CREATE_FAILURE: 'OFFER_CREATE_FAILURE',

    UPDATE_REQUEST: 'OFFER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'OFFER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'OFFER_UPDATE_FAILURE',

    GETALL_REQUEST: 'OFFER_GETALL_REQUEST',
    GETALL_SUCCESS: 'OFFER_GETALL_SUCCESS',
    GETALL_FAILURE: 'OFFER_GETALL_FAILURE',

    DELETE_REQUEST: 'OFFER_DELETE_REQUEST',
    DELETE_SUCCESS: 'OFFER_DELETE_SUCCESS',
    DELETE_FAILURE: 'OFFER_DELETE_FAILURE',
    
    ISACTIVE_REQUEST: 'OFFER_REQUEST',
    ISACTIVE_SUCCESS: 'OFFER_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'OFFER_ISACTIVE_FAILURE',

    GETEXPORT_REQUEST: 'GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'GETEXPORT_FAILURE',

    DIALOG_REQUEST:'DIALOG_REQUEST',

    LOADING_REQUEST:'LOADING_REQUEST',

    DIALOG_ISACTIVE_REQUEST:'DIALOG_ISACTIVE_REQUEST'
};