import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import VerifyProduct from "../models/VerifyProduct";
import { productAuthenticationActions, productMasterActions } from "../actions";
import { handleResponse } from "../helpers";
import toyo from "./../assets/images/avwoodlogo.png";
import reviewIcon from "./../assets/images/write-review-icon.png";
import shareIcon from "./../assets/images/share-icon.png";
import sucessIcon from "./../assets/images/sucess-codeg.png";
import fakeIcon from "./../assets/images/wrong-code.png";
import { Rating } from "primereact/rating";
import Lightbox from "react-image-lightbox";
import logo from "./../assets/images/login_logo.png";
import InstaIcon from "./../assets/images/instagram.png";
import LinkedinIcon from "./../assets/images/linkedIn.png";
import OtherLinkIcon from "./../assets/images/link.png";
import Logo from "./../assets/images/login_logo.png";
import Carousel from "react-material-ui-carousel";
import PinterestIcon from "./../assets/images/pinterest.png";
import ReactPlayer from "react-player";
import { Paper, Card } from "@mui/material";
import androiddownloadbutton from '../../src/assets/images/download_button_android.png';
import iphonedownloadbutton from '../../src/assets/images/download_button_iphone.png';
import checkedicn from '../../src/assets/images/check.gif'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { colors } from "@material-ui/core";

export class ProductAuthentication extends Component {
  constructor(props) {
    super(props);
    this.clsVerifyProduct = new VerifyProduct();
    this.state = {
      formIsValid: false,
      IsLocation: false,
      ratingBox: false,
      shareBox: false,
      VerifyProduct: {},
      rating: null,
      showDialog: false,
      VerificationMode: "Web",
      QrData: window.location.href,
      VerificationType: 2,
      VerificationId: 0,
      displayBasic: false,
      UserId: "",
      SeqNo: "",
      Name: "",
      email: "",
      MobileNo: "",
      UserFeedback: "",
      showVideo: true,
      photoIndex: 0,
      isOpen: false,
      socialLinks: "",
      formError: {
        Name: null,
        email: null,
        MobileNo: null,
        UserFeedback: null,
      },
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onHide = this.onHide.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.updateRating = this.updateRating.bind(this);
    this.ImgModal = this.ImgModal.bind(this);
    this.reverseGeocodeLatLngAndVerifyProduct =
      this.reverseGeocodeLatLngAndVerifyProduct.bind(this);
    const urlParams = new URLSearchParams(window.location.search);
    const parmUid = urlParams.get("uid");
    //const curl = window.location.href;
    this.clsVerifyProduct.QrData = this.state.QrData;
    this.clsVerifyProduct.VerificationType = this.state.VerificationType;
    this.findMe();
  }

  //get reverse geo code
  reverseGeoCode(lat, long) {
    // tslint:disable-next-line:max-line-length
    const requestOptions = {
      method: "GET",
    };
    return fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`,
      requestOptions
    ).then(handleResponse);
    //   return fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`);
  }

  //to trigger api on page call
  componentDidMount() {
    const caches = window.caches;
    if (window.caches) {
      window.caches.keys().then(async function (names) {
        await Promise.all(names.map((name) => window.caches.delete(name)));
      });
    }

    // const urlParams = new URLSearchParams(window.location.search);
    // const parmUid = urlParams.get('uid');

    // const curl = window.location.href;

    // this.clsVerifyProduct.verificationMode = this.state.verificationMode;
    // this.clsVerifyProduct.qrData = this.state.qrData;
    // this.clsVerifyProduct.verificationType = this.state.verificationType;
    // this.clsVerifyProduct.UID = parmUid;
    this.props.getAllProductsData();

    // setTimeout(() => {
    //     // this.findMe();
    // }, 1000);
    // this.findMe();
  }

  // get verify product list data
  GetVerifyProduct(productAuthReq) {
    this.setState({
      IsLocation: false,
    });

    this.props.verifyProduct(productAuthReq);
  }
  //to hide function
  hideFunc() {
    this.setState({
      displayBasic: false,
    });
  }

  //to validate feedback form
  validateFeedback(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "Name":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Name = "Please enter your name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
            IsValid = false;
            errors.Name = "Please enter only alphabet.";
          } else errors.Name = "";
        } else errors.Name = "";
        break;
      case "email":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.email = "Please enter your email-id.";
        } else if (value && value.length > 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.email = "Please enter valid email-id.";
          } else errors.email = "";
        } else errors.email = "";
        break;
      case "MobileNo":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please enter your mobile no.";
        } else if (value && value.length > 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = null;
        } else errors.MobileNo = "";
        break;
      case "UserFeedback":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.UserFeedback = "Please enter your feedback.";
        } else errors.UserFeedback = "";
        break;

      default:
        break;
    }

    return IsValid;

    // let errors = this.state.formError;
    // let formFeedIsValid = true;

    // switch (name) {
    //     case 'Name':
    //         if (value === null || value.length < 1) {
    //             formFeedIsValid = false;
    //             errors.Name = "Please enter your name.";
    //         } else if (value.length >= 1) {
    //             if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
    //                 formFeedIsValid = false;
    //                 errors.Name = "Please enter only alphabet.";
    //             } else errors.Name = null
    //         } else errors.Name = null
    //         break;
    //     case 'email':
    //         if (value === null || value === '' || value.length >= 1) {
    //             /* eslint-disable */
    //             if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    //                 formFeedIsValid = false;
    //                 errors.email = "Please enter valid email-id.";
    //             } else errors.email = null
    //             /* eslint-enable */
    //         } else errors.email = null
    //         break;
    //     case 'MobileNo':
    //         if (value === null || value.length < 1) {
    //             formFeedIsValid = false;
    //             errors.MobileNo = "Please enter your mobile no.";
    //         }
    //         else if (value.length >= 1) {
    //             if (!value.match(/^[6-9]\d{9}$/)) {
    //                 formFeedIsValid = false;
    //                 errors.MobileNo = "Please enter valid mobile no.";
    //             } else errors.MobileNo = null
    //         } else errors.MobileNo = null
    //         break;
    //     case 'UserFeedback':
    //         if (value === null || value.length < 1) {
    //             formFeedIsValid = false;
    //             errors.UserFeedback = "Please enter your feedback.";
    //         } else errors.UserFeedback = null
    //         break;
    //     default:
    //         break;
    // }

    // return formFeedIsValid;
  }

  ImgModal() {
    var modal = document.getElementById("myModal");
    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    // var captionText = document.getElementById("caption");
    img.onclick = function () {
      modal.style.display = "block";
      modalImg = document.getElementById("img01");
      // captionText.innerHTML = this.alt;
    };

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = "none";
    };
  }

  submitForm(event) {
    // const { name, value } = event.target;
    event.preventDefault();

    let isfeedfrmvalid = true;
    let checkerror = this.state.formError;
    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateFeedback(val, key);
      if (!valid) {
        this.setState({ checkerror: isfeedfrmvalid });
        isfeedfrmvalid = false;
      }

      return isfeedfrmvalid;
    });

    const feedbackData = {
      Name: this.state.Name,
      email: this.state.email,
      MobileNo: this.state.MobileNo,
      UserFeedback: this.state.UserFeedback,
      VerificationId: this.props.ProductAuthenticationData.VerificationId,
    };

    if (isfeedfrmvalid) {
      this.props.UpdateFeedback(feedbackData);
    }

    this.setState({ [feedbackData]: "" });
  }

  formReset() {
    this.setState({
      Name: "",
      email: "",
      MobileNo: "",
      UserFeedback: "",
      formError: {
        Name: null,
        email: null,
        MobileNo: null,
        UserFeedback: null,
      },
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  updateRating(productId, verificationId, event) {
    this.setState({ rating: event.value });

    var data = {
      ProductId: productId,
      ProductRating: event.value,
      SeqNo: event.value,
      VerificationId: verificationId,
    };

    if (productId) {
      this.props.updateRating(data);
    } else {
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.scratchError;

    this.validateFeedback(value, name);
    this.setState({ errors, [name]: value });
  }
  onHide() {
    this.props.onHideShow(false);
  }

  openDialog() {
    this.props.onHideShow(true);
  }

  //to find  location
  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.showPosition(position);
        },
        (denied) => {
          // alert(JSON.stringify(denied));
          this.GetVerifyProduct(this.clsVerifyProduct);
        },
        { enableHighAccuracy: true, maximumAge: 10000 }
      );
    } else {
      this.GetVerifyProduct(this.clsVerifyProduct);
    }
  }
  //to get exact longitude and latitude
  showPosition(position) {
    this.clsVerifyProduct.Userlongitude = position.coords.longitude;
    this.clsVerifyProduct.Userlatitude = position.coords.latitude;
    this.reverseGeocodeLatLngAndVerifyProduct();
  }
  //to get location
  reverseGeocodeLatLngAndVerifyProduct() {
    // tslint:disable-next-line: max-line-length
    this.reverseGeoCode(
      this.clsVerifyProduct.Userlatitude,
      this.clsVerifyProduct.Userlongitude
    ).then(
      (response) => {
        const res = response;
        this.clsVerifyProduct.UserCity = res.address.state_district;
        this.clsVerifyProduct.UserState = res.address.state;
        this.clsVerifyProduct.UserCountry = res.address.country;
        this.clsVerifyProduct.UserZip = res.address.postcode;
        this.clsVerifyProduct.UserAddress = res.display_name;
        this.setState({
          Location: res.display_name,
        });
        this.GetVerifyProduct(this.clsVerifyProduct);
      },
      (error) => {
        // alert(JSON.stringify(error))
        this.GetVerifyProduct(this.clsVerifyProduct);
      }
    );
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    let links = null;
    let share = null;
    return (
     
      <>
      <div className="container-fluid">
        <div className="row auth_toprow">
          <div className="col-md-6">
            <h5 className="appinstallh">
               AVwood App Install
            </h5>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <ul className="dbuttonul">
              <li>
                <img src={androiddownloadbutton} />
              </li>
              <li>
                <img src={iphonedownloadbutton} />
              </li>
            </ul>
          </div>
        </div>
      </div>
        <div className="container-fluid pauthemtication_content_container">
          <div className="row">
            <div className="col-md-12">    
                <div className="pa-outer">
                  {this.props.ProductAuthenticationData != null && this.props.ProductAuthenticationData.ResponseCode == "200" ? (
                    <div className="container mt-2 mb-2" style={{ zIndex: 0 }}>
                      {(this.props.ProductAuthenticationData.GenuineAudio != null) ===
                      true ? (
                        <audio
                          controls
                          autoPlay
                          src={this.props.ProductAuthenticationData.GenuineAudio}
                          id="pm3paly"
                          hidden
                        />
                      ) : (
                        ""
                      )}
                      <div className="container">
                      <div className="row">
                        <div className="col-md-6">
                          <h4 className="Productauthentication_heading mt-4">
                              Product Authentication
                          </h4>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end mt-3">
                          <img src={toyo} />
                        </div>  
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                              <div className="success-message mt-3">
                              <h2>
                                <img src={checkedicn} />{" "}
                                <div className="pr_title mt-3">Thanks for buying genuine product</div>
                              </h2>
                            </div>
                        </div>
                      </div> 
                      <div className="row">
                        <div className="col-md-12  produt_imgcol">
                        <div className="text-center PaProductimg">
                          <img
                            className="productimg  mt-4"
                            src={this.props.ProductAuthenticationData.ProductImage==''? Logo:this.props.ProductAuthenticationData.ProductImage}
                          />
                        
                        </div>
                        </div>
                      </div> 
                      <div className="row pdetailrow">
                      <div className="col-md-12 pdetails">
                        <div className="productdetailheading">
                              <h1>{this.props.ProductAuthenticationData.ProductName}</h1>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="pdetailoptionul">
                              <li className="rating ">
                                      {this.props.ProductAuthenticationData.IsRating != null &&
                                      this.props.ProductAuthenticationData.IsRating != "0" ? (
                                        <span
                                          onClick={() =>
                                            this.setState({ ratingBox: !this.state.ratingBox })
                                          }
                                        >
                                          <span className="starticn mr-1" style={{color:'gold'}}><i className="bi bi-star-fill"></i></span>
                                          Rating &nbsp;
                                          {this.props.Rating && this.props.Rating.ProductRating
                                            ? this.props.Rating.ProductRating
                                            : this.props.ProductAuthenticationData.ProductRating}
                                          (
                                          {this.props.Rating && this.props.Rating.TotalRating
                                            ? this.props.Rating.TotalRating
                                            : this.props.ProductAuthenticationData.TotalRating ==
                                              null
                                            ? 0
                                            : this.props.ProductAuthenticationData.TotalRating}
                                          )
                                        </span>
                                      ) : null}

                                      {this.state.ratingBox ? (
                                        <div className="rating-wraper">
                                          {this.props.ProductAuthenticationData.isRated == 0 ? (
                                            <Rating
                                              cancel={false}
                                              value={this.state.rating}
                                              onChange={(e) =>
                                                this.updateRating(
                                                  this.props.ProductAuthenticationData.ProductId,
                                                  this.props.ProductAuthenticationData
                                                    .VerificationId,
                                                  e
                                                )
                                              }
                                            />
                                          ) : (
                                            <Rating
                                              cancel={false}
                                              readonly
                                              value={
                                                this.props.ProductAuthenticationData.ProductRating
                                              }
                                              onChange={(e) =>
                                                this.updateRating(
                                                  this.props.ProductAuthenticationData.ProductId,
                                                  this.props.ProductAuthenticationData
                                                    .VerificationId,
                                                  e
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      ) : null}
                              </li>
                              <li>
                                  {this.props.ProductAuthenticationData.IsFeedback != null &&
                                  this.props.ProductAuthenticationData.IsFeedback != "0" ? (
                                    <span onClick={() => this.openDialog()}>
                                      <i
                                        className="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                      ></i>
                                      Feedback
                                    </span>
                                  ) : null}
                              </li>
                              <li className="shareli">
                                    {this.props.ProductAuthenticationData.IsShare != null &&
                                    this.props.ProductAuthenticationData.IsShare != "0" ? (
                                      <span
                                        onClick={() =>
                                          this.setState({ shareBox: !this.state.shareBox })
                                        }
                                      >
                                        <span className="shareicn mr-2"> <i className="bi bi-share"></i></span>
                                        Share
                                      </span>
                                    ) : null}
                              </li>

                            </ul>
                          </div>
                        </div>
                        </div>
                      </div>
                      </div>
                    
                      <div
                        id="testId"
                        className="text-center image-wraper col-md-12 mt-2"
                      >
                        <div className="logo-wraper">
                          <div className="logo-c" onClick={this.ImgModal}>
                            <img
                              id="myImg"
                              src={this.props.ProductAuthenticationData.CompanyLogo}
                              alt="Trolltunga, Norway"
                              width="300"
                              height="200"
                            />

                            <div
                              id="myModal"
                              className="modal"
                              style={{ display: "none" }}
                            >
                              <span className="close mt-3 mr-3">&times;</span>
                              <img className="modal-content" src={Logo} id="img01" />
                              <div id="caption"></div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      

                      {(this.props.ProductAuthenticationData.PromotionalMessage !=
                        null) ===
                      true ? (
                        <div
                          className="product-detail mt-3 text-center product-bg"
                          style={{ paddingLeft: "0px" }}
                        >
                          <h2>
                            {this.props.ProductAuthenticationData.PromotionalMessage}
                          </h2>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <h2>{this.props.ProductAuthenticationData.PromotionalMessage}</h2> */}

                      <div className="row table_row mt-2" style={{ background: "#fff" }}>
                        {this.props.ProductAuthenticationData.ProductSpecification !=
                        null
                          ? this.props.ProductAuthenticationData.ProductSpecification.map(
                              (x, i) => {
                                if (x.Key == "Website") {
                                  return (
                                    <>
                                      <div className="col-md-6 py-2 row_data">
                                        {x.Key}
                                      </div>
                                      <div className="col-md-6 py-2 row_data1">
                                        <a href={x.Value} target="_blank">
                                          {" "}
                                          {x.Value}{" "}
                                        </a>
                                      </div>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <div className="col-md-6 py-2 row_data">
                                        {x.Key}
                                      </div>
                                      <div className="col-md-6 py-2 row_data1">
                                        {x.Value}
                                      </div>
                                    </>
                                  );
                                }
                              }
                            )
                          : null}

                        
                      </div>
                    </div>
                  ) : (
                    // <div className="container-fluid p-0">{
                    //     (this.props.ProductAuthenticationData != null && this.props.ProductAuthenticationData.FakeAudio) === true
                    //         ?
                    //         <audio controls autoPlay src={this.props.ProductAuthenticationData.FakeAudio} id="pm3paly" hidden />
                    //         : ""}

                    //     <div className="success-message">
                    //         {
                    //             this.props.ProductAuthenticationData != null ?
                    //                 <div className="text-center p-3"> <img src={fakeIcon} className="mb-3" />
                    //                     <h2 className="mb-4">{this.props.ProductAuthenticationData.ResponseMessage}</h2>
                    //                 </div>
                    //                 : ""
                    //         }
                    //     </div>
                    // </div>

                    <div className="container-fluid p-0">
                      {(this.props.ProductAuthenticationData != null &&
                        this.props.ProductAuthenticationData.FakeAudio) === true ? (
                        <audio
                          controls
                          autoPlay
                          src={this.props.ProductAuthenticationData.FakeAudio}
                          id="pm3paly"
                          hidden
                        />
                      ) : (
                        ""
                      )}

                      <div className="success-message box">
                        {this.props.ProductAuthenticationData != null ? (
                          <div className="text-center p-3">
                            {" "}
                            <img src={fakeIcon} className="mb-3" />
                            <h2 className="mb-4">
                              {this.props.ProductAuthenticationData.ResponseMessage}
                            </h2>
                          </div>
                        ) : (
                          ""
                        )}

                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                          onClick={() => this.openDialog()}
                        >
                          Feedback
                        </button>
                      </div>
                    
                  
                  
                      
                    </div>
                    
                  )}

                  <Dialog
                    header="Feedback Form"
                    modal
                    dismissableMask={true}
                    visible={this.props.ShowDialogFeedback}
                    className="popup2"
                    onHide={this.onHide}
                  >
                    <form onSubmit={this.submitForm}>
                      <div className="row">
                        <div className="col-md-6 pt-2">
                          <TextField
                            id="standard-basic"
                            size="small"
                            onChange={this.handleChange}
                            variant="outlined"
                            name="Name"
                            value={this.state.Name}
                            className="fullwidth"
                            label="Name*"
                          />
                          {this.state.formError.Name !== "" ? (
                            <div className="error">{this.state.formError.Name}</div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6 pt-2">
                          <TextField
                            id="standard-basic"
                            size="small"
                            onChange={this.handleChange}
                            variant="outlined"
                            name="email"
                            value={this.state.email}
                            className="fullwidth"
                            label="Email*"
                          />
                          {this.state.formError.email !== "" ? (
                            <div className="error">{this.state.formError.email}</div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6 pt-2">
                          <TextField
                            id="standard-basic"
                            size="small"
                            onChange={this.handleChange}
                            variant="outlined"
                            name="MobileNo"
                            value={this.state.MobileNo}
                            className="fullwidth"
                            label="Mobile number*"
                          />
                          {this.state.formError.MobileNo !== "" ? (
                            <div className="error">{this.state.formError.MobileNo}</div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6 pt-2">
                          <TextField
                            id="standard-basic"
                            size="small"
                            onChange={this.handleChange}
                            variant="outlined"
                            name="UserFeedback"
                            value={this.state.UserFeedback}
                            className="fullwidth"
                            label="Feedback*"
                          />
                          {this.state.formError.UserFeedback !== "" ? (
                            <div className="error">
                              {this.state.formError.UserFeedback}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-12 mt-3 text-center">
                          <Button label="Submit" icon="pi pi-check" />
                        </div>
                      </div>
                    </form>
                  </Dialog>
                </div>

            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state.product.items);
  //console.log('state.productAuthentication.feedback', state.productAuthentication.showDialogFeedback);
  //console.log('state.productAuthentication.items', state.productAuthentication.items);
  //console.log('state.productAuthentication.items[0]', (state.productAuthentication.items && state.productAuthentication.items.length) > 0 ? state.productAuthentication.items[0] : null);
  return {
    ProductsData: state.product.items,
    Rating: state.productAuthentication.rating,
    ProductAuthenticationData:
      (state.productAuthentication.items &&
        state.productAuthentication.items.length) > 0
        ? state.productAuthentication.items[0]
        : null,
    ShowDialogFeedback: state.productAuthentication.showDialogFeedback,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyProduct: (productAuth) =>
      dispatch(productAuthenticationActions.verifyProduct(productAuth)),
    UpdateFeedback: (feedback) =>
      dispatch(productAuthenticationActions.UpdateFeedback(feedback)),
    updateRating: (ratingData) =>
      dispatch(productAuthenticationActions.updateRating(ratingData)),
    onHideShow: (isbool) =>
      dispatch(productAuthenticationActions.onHideShow(isbool)),
    getAllProductsData: () =>
      dispatch(productMasterActions.getAllProductsData()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductAuthentication);
