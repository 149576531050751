import { authHeader, handleResponse } from "../helpers";
export const dealerService = {
  createDealer,
  fetchStateDLL,
  fetchCityDLL,
  fetchFirmDLL,
  updateDealer,
  fetchDealerData,
  inActiveRow,
  //fetchRoleDLL,
  fetchTransportationModeDDL,
  fetchDealerDataforExport,
  fetchDistrictDLL,
};

async function createDealer(data) {
  console.log(data);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/CreateDealer`,
    requestOptions
  );
  return handleResponse(response);
}

async function updateDealer(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/UpdateDealer`,
    requestOptions
  );
  return handleResponse(response);
}

async function fetchStateDLL(countryId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetStateDLL/${countryId}`,
    requestOptions
  );
  return handleResponse(response);
}

async function fetchCityDLL(userId) {

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetCityDLL/${userId}`,
    requestOptions
  );
  return handleResponse(response);
}

async function fetchDistrictDLL(userId) {

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetDistrictDDL/${userId}`,
    requestOptions
  );
  return handleResponse(response);
}

async function fetchFirmDLL(userId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetFirmDLL/${userId}`,
    requestOptions
  );
  return handleResponse(response);
}
// async function fetchRoleDLL(userId) {
//   const requestOptions = {
//       method: 'GET',
//       headers: authHeader()
//   };

//   const response = await fetch(`${process.env.REACT_APP_API_URL}RoleMaster/GetRoleDLL/${2}`, requestOptions);
//   return handleResponse(response);
// }

async function fetchTransportationModeDDL() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetTransportationModeDLL`,
    requestOptions
  );
  return handleResponse(response);
}

async function fetchDealerData(data) {
  ;
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetDealers`,
    requestOptions
  );
  return handleResponse(response);
}

async function fetchDealerDataforExport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetDealersForExport`,
    requestOptions
  );
  return handleResponse(response);
}

async function inActiveRow(UserId, isActive) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/InActiveRow/${UserId}/${isActive}`,
    requestOptions
  );
  return handleResponse(response);
}
