import { MechanicLoyaltyRptService } from "../services";
import { MechanicLoyaltyRptConstants } from "../constants";
import { alertActions } from ".";

export const MechanicLoyaltyRptActions = {
  getAll,
  loadingData,
  exportData,
};

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());

    MechanicLoyaltyRptService.fetchMappingData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));

        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.CreatedBy;
            delete v.DocURL;
            delete v.MappingId;
            delete v.ProductID;
          });
        }
        dispatch(successExport(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: MechanicLoyaltyRptConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: MechanicLoyaltyRptConstants.GETALL_SUCCESS, datas };
  }
  function successExport(datas) {
    return { type: MechanicLoyaltyRptConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: MechanicLoyaltyRptConstants.GETALL_FAILURE, error };
  }
}

function exportData(obj) {
  return (dispatch) => {
    dispatch(request());
    MechanicLoyaltyRptService.fetchMappingData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.CreatedBy;
            delete v.DocURL;
            delete v.MappingId;
            delete v.ProductID;
          });
        }
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: MechanicLoyaltyRptConstants.GETEXPORT_REQUEST };
  }
  function success(datas) {
    return { type: MechanicLoyaltyRptConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: MechanicLoyaltyRptConstants.GETEXPORT_FAILURE, error };
  }
}

function loadingData(isbool) {
  return {
    type: MechanicLoyaltyRptConstants.LOADING_REQUEST,
    isbool,
  };
}
