import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TextField from "@material-ui/core/TextField";
import { Calendar } from "primereact/calendar";
import {
  alertActions,
  LoyaltyRedemptionActions,
  mappingRptActions,
} from "../../actions";
import { connect } from "react-redux";
import tableRequest from "../../models/tableRequest.js";
import { Toast } from "primereact/toast";
import { CSVLink } from "react-csv";

class MappingRpt extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.tblResponse.SortColumn = "VerifiedDate";
    this.tblResponse.SortOrder = "DESC";
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.submitForm = this.submitForm.bind(this);

    this.searchFilter = this.searchFilter.bind(this);
    this.state = {
      loading: false,
      aadharNo: "",
      SequenceNo: "",
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      visible: false,
      sortOrder: "",
      datalist: [],

      formError: {
        aadharNo: "",
        SequenceNo: "",
      },
    };
  }

  componentDidMount() {
    const userDetails = sessionStorage.getItem("user");
    const userdetailsObject = JSON.parse(userDetails);
    this.setState({
      UserId: userdetailsObject.UserId,
    });
    this.props.fetchApi(this.tblResponse);
  }

  componentDidUpdate(prevProps) {
    if (this.props.LoyaltyData) {
      if (prevProps.LoyaltyData !== this.props.LoyaltyData) {
        // this.props.myProp has a different value
        if (this.props.LoyaltyData) {
          var e = this.props.LoyaltyData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  GetMapping() {
    this.props.fetchApi(this.tblResponse);
    this.props.exportingData(this.tblResponse);
  }

  resetForm() {
    this.setState({
      aadharNo: "",
      SequenceNo: "",
    });
  }
  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });
    if (isfrmvalid) {
      const formData = {
        aadharNo: this.state.aadharNo,
        seqNo: this.state.SequenceNo,
        CreatedBy: this.state.UserId,
      };

      if (
        this.state.aadhLoyaltyRedempationarNo != "" &&
        this.state.SequenceNo != ""
      ) {
        this.props.LoyaltyRedempation(formData, this.tblResponse);
        //console.log(formData);
      }
      this.resetForm();
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.validateForm(value, name);
  }

  validateForm(value, name) {
    value = value.trim();
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "aadharNo":
        if (value.length < 1) {
          IsValid = false;
          errors.aadharNo = "Please enter Aadhar No.";
        } else errors.aadharNo = "";
        break;
      case "SequenceNo":
        if (value.length < 1) {
          IsValid = false;
          errors.SequenceNo = "Please enter Sequence No";
        } else errors.SequenceNo = "";
        break;
      default:
        break;
    }

    return IsValid;
  }
  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }
  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
    this.props.exportingData(this.tblResponse);
  }

  render() {
    return (
      <div className="datatable-filter-demo">
        <Toast ref={(el) => (this.toast = el)} />
        <div className="row mb-3">
          <div className="col-md-3">
            <h4>Loyalty Redemption</h4>
          </div>
          <div className="col-md-9">
            <div className="row text-right">
              <div className="col-md-3">
                <TextField
                  input
                  type="text"
                  onChange={this.handleChange}
                  name="aadharNo"
                  value={this.state.aadharNo}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Aadhar No.*"
                />
                {this.state.formError.aadharNo !== "" ? (
                  <div className="error">{this.state.formError.aadharNo}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-3">
                <TextField
                  input
                  type="text"
                  onChange={this.handleChange}
                  name="SequenceNo"
                  value={this.state.SequenceNo}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Sequence No*"
                />
                {this.state.formError.SequenceNo !== "" ? (
                  <div className="error">{this.state.formError.SequenceNo}</div>
                ) : (
                  ""
                )}{" "}
              </div>
              <div className="col-md-3 text-center ">
                <button
                  type="button"
                  onClick={this.submitForm}
                  className="btn btn-danger p-2 ml-3 buttone"
                >
                  Submit
                </button>
              </div>
              <div className="col-md-3">
                <TextField
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Search"
                  onInput={this.searchFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <DataTable
            value={this.props.LoyaltyData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            emptyMessage="No Data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20, parseInt(this.state.totalRecords)]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="UserName" disabled header="Name" sortable></Column>
            <Column
              field="ProductName"
              disabled
              header="Product Name"
              sortable
            ></Column>
            <Column
              field="SeqNo"
              disabled
              header="Sequence No"
              sortable
            ></Column>
            <Column
              field="AadharNo"
              disabled
              header="Aadhar No"
              sortable
            ></Column>
            <Column
              field="RequestedBy"
              disabled
              header="Requested By"
              sortable
            ></Column>
            <Column
              field="LoyalityPoints"
              disabled
              header="Loyality Points"
              sortable
            ></Column>
            <Column
              field="VerifiedDate"
              disabled
              header="Date"
              sortable
            ></Column>

            {/* <Column field="Location" header="Location" sortable></Column> */}
            {/* <Column field="BatchNo" disabled header="Batch No" sortable></Column> */}
            {/* <Column field="CreatedDate" header="Mapped On" sortable></Column> */}
            {/* <Column field="Remark" header="Remark" sortable></Column> */}
          </DataTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    LoyaltyData:
      state.LoyaltyRedemption.data == (null || undefined)
        ? null
        : state.LoyaltyRedemption.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(LoyaltyRedemptionActions.getAll(obj));
    },
    LoyaltyRedempation: (machine, obj) =>
      dispatch(LoyaltyRedemptionActions.LoyaltyRedempation(machine, obj)),
    loadingData: (isLoding) =>
      dispatch(LoyaltyRedemptionActions.loadingData(isLoding)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MappingRpt);
