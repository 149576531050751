import { authHeader, handleResponse } from '../helpers';
export const RedemptionApprovalService = {
    ApproveRedeem,
    CancelRedeem,
    createRedemption,
    fetchUserData,
    GetRedemptions,
    inActiveRow,
    GetUserPoints
};

function ApproveRedeem(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}RedemptionApproval/ApproveRedeem`, requestOptions)
        .then(handleResponse);
}

function CancelRedeem(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}RedemptionApproval/CancelRedeem`, requestOptions)
        .then(handleResponse);
}

function createRedemption(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    //console.log('requestOptions',requestOptions)
    return fetch(`${process.env.REACT_APP_API_URL}RedemptionApproval/createRedemption`, requestOptions)
        .then(handleResponse);
}

function fetchUserData(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        //body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}RedemptionApproval/GetUserDDL`, requestOptions)
        .then(handleResponse);
}

function GetRedemptions(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}RedemptionApproval/GetRedemptions`, requestOptions)
        .then(handleResponse);
}

function inActiveRow(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}RedemptionApproval/InActiveRow/$`, requestOptions)
        .then(handleResponse);
}

function GetUserPoints(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}RedemptionApproval/GetUserPoints`, requestOptions)
        .then(handleResponse);
}




