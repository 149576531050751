import { combineReducers } from "redux";
import { product } from "./productReducer";
import { user } from "./userReducer";
import { alert } from "./alertReducer";
import { role } from "./roleMasterReducer";
import { dashboard } from "./dashboardReducer";
import { login } from "./loginReducer";
import { menu } from "./menuReducer";
import { changePassword } from "./changePasswordReducer";
import { mapping } from "./mappingReducer";
import { issueNo } from "./issueNoReducer";
import { damage } from "./damageReducer";
import { verification } from "./verificationLogReducer";
import { mappingRpt } from "./mappingRptReducer";
import { FeedbackRpt } from "./FeedbackRptReducer";
import { UnscanRpt } from "./UnscanRptReducer";
import { DemappingRpt } from "./DemappingRptReducer";
import { DamageRpt } from "./DamageRptReducer";
import { LoyaltySchemeRpt } from "./LoyaltySchemeRptReducer";
import { RedemptionLogRpt } from "./RedemptionLogRptReducer";
import { UserloyalityLogRpt } from "./UserloyalityLogRptReducer";
import { SqlEditor } from "./SqlEditorReducer";
import { demapping } from "./demappingReducer";
import { productAuthentication } from "./productAuthenticationReducer";
import { productConfiguration } from "./productConfigurationReducer";
import { company } from "./companyReducer";
import { loyalityscheme } from "./loyalityschemeReducer";
import { ProductPurchaseRpt } from "./ProductPurchaseRptReducer";
import { RedemptionApproval } from "./RedemptionApprovalReducer";
import { offer } from "./offerMasterReducer";
import { LoyaltyRedemption } from "./LoyaltyRedemptionReducer";
import { category } from "./categoryReducer";
import { ProductRatingRpt } from "./ProductRatingRptReducer";
import { ScannedHistory } from "./ScannedHistoryReducer";
import { issuePage } from "./issuePageReducers";
import { dealer } from "./dealerReducer";
import { dealerdata } from "./dealerdataReducer";
import { MechanicLoyaltyRpt } from "./MechanicLoyaltyRptReducer";
import { MobileUserTDSReport } from "./MobileUserTDSReportReducer";
import { UserAccountBalanceRpt } from "./UserAccountBalanceRptReducer";
import { listMaster } from "./listMasterReducer";
import { DealerStock } from "./DealerStockInvoiceReducer";
const rootReducer = combineReducers({
  user,
  alert,
  product,
  role,
  dashboard,
  login,
  menu,
  changePassword,
  mappingRpt,
  DemappingRpt,
  DamageRpt,
  FeedbackRpt,
  UnscanRpt,
  SqlEditor,
  issueNo,
  damage,
  verification,
  mapping,
  verification,
  demapping,
  productAuthentication,
  productConfiguration,
  company,
  LoyaltySchemeRpt,
  RedemptionLogRpt,
  UserloyalityLogRpt,
  RedemptionApproval,
  ProductPurchaseRpt,
  loyalityscheme,
  offer,
  LoyaltyRedemption,
  category,
  ScannedHistory,
  ProductRatingRpt,
  issuePage,
  dealer,
  dealerdata,
  MechanicLoyaltyRpt,
  MobileUserTDSReport,
  UserAccountBalanceRpt,
  listMaster,
  DealerStock,

});

export default rootReducer;
