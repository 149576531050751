import { listMasterService } from "../services/listMasterService";
import { listMasterConstants } from "../constants/listMasterConstants";
import { userService } from "../services";
import { userConstants } from "../constants";
import { alertActions } from ".";
import { history } from "../helpers";
import React from "react";

export const listMasterAction = {
  getAll,
  getRoleDDL,
  CreateList,
  UpdateList,
  inActiveRow,
  onHideShow,
  onHideShoww,
  loadingData,
  onHideShowIsActive,
  onHideShowError,
  getListDataDLL,
};

function getAll(data) {
  return (dispatch) => {
    dispatch(request());
    listMasterService.fetchListData(data).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: listMasterConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: listMasterConstants.GETALL_REQUEST, datas };
  }
  function failure(error) {
    return { type: listMasterConstants.GETALL_REQUEST, error };
  }
}

function getRoleDDL(data) {
  return (dispatch) => {
    dispatch(request());

    userService.fetchRoleDLL(data).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.GETROLEDDL_REQUEST };
  }
  function success(datas) {
    return { type: userConstants.GETROLEDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: userConstants.GETROLEDDL_FAILURE, error };
  }
}



function CreateList(data) {
  return (dispatch) => {
    dispatch(request(data));

    listMasterService.CreateList(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(response.ResponseData));
            dispatch(onHideShow(false));
            dispatch(onHideShoww(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: listMasterConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: listMasterConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: listMasterConstants.CREATE_FAILURE, error };
  }
}



function UpdateList(data) {
  return (dispatch) => {
    dispatch(request(data));

    listMasterService.UpdateList(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(<div>{new Date(data).toString()}</div>));
            dispatch(onHideShow(false));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: listMasterConstants.UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: listMasterConstants.UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: listMasterConstants.UPDATE_FAILURE, error };
  }
}

function inActiveRow(SchemeId, isActive) {
  var data = {
    SchemeId: SchemeId,
    IsActive: isActive,
  };
  return (dispatch) => {
    dispatch(request());
    listMasterService.inActiveRow(SchemeId, isActive).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: listMasterConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: listMasterConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: listMasterConstants.ISACTIVE_FAILURE, error };
  }
}

function onHideShow(isbool) {
  return {
    type: listMasterConstants.DIALOG_REQUEST,
    isbool,
  };
}
function onHideShoww(isbool) {
  return {
    type: listMasterConstants.DIALOGUE_REQUEST,
    isbool,
  };
}

function loadingData(isbool) {
  return {
    type: listMasterConstants.LOADING_REQUEST,
    isbool,
  };
}

function onHideShowError(isbool) {
  return {
    type: listMasterConstants.ERROR_DIALOG_REQUEST,
    isbool,
  };
}

function onHideShowIsActive(isbool) {
  return {
    type: listMasterConstants.DIALOG_ISACTIVE_REQUEST,
    isbool,
  };
}


function getListDataDLL(data) {
  return (dispatch) => {
    dispatch(request());
    listMasterService.fetchListDataDLL(data).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: listMasterConstants.GETDATALISTDDL_REQUEST };
  }
  function success(datas) {
    return { type: listMasterConstants.GETDATALISTDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: listMasterConstants.GETDATALISTDDL_FAILURE, error };
  }
}