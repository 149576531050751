import { userService } from "../services";
import { userConstants } from "../constants";
import { alertActions } from ".";
import { history } from "../helpers";

export const userMasterActions = {
  getAll,
  getAllForApproval,
  getRoleDDL,
  createUser,
  updateUser,
  inActiveRow,
  onHideShow,
  loadingData,
  onHideShowIsActive,
  approveUser,
  rejectUser,
  exportData,
};

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());

    userService.fetchUserData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: userConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function getAllForApproval(obj) {
  return (dispatch) => {
    dispatch(request());

    userService.fetchUserApprovalData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: userConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function getRoleDDL(data) {
   
  return (dispatch) => {
    dispatch(request());

    userService.fetchRoleDLL(data).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GETROLEDDL_REQUEST };
  }
  function success(datas) {
    return { type: userConstants.GETROLEDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: userConstants.GETROLEDDL_FAILURE, error };
  }
}

function createUser(data) {
  return (dispatch) => {
    dispatch(request(data));

    userService.createUser(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(response.ResponseData));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: userConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: userConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.CREATE_FAILURE, error };
  }
}

function updateUser(data) {
  
  return (dispatch) => {
    dispatch(request(data));

    userService.updateUser(data).then(
      (response) => {
        
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: userConstants.UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: userConstants.UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE, error };
  }
}

function inActiveRow(user, isActive) {
  var data = {
    UserId: user,
    IsActive: isActive,
  };
  return (dispatch) => {
    dispatch(request());

    userService.inActiveRow(user, isActive).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.ISACTIVE_FAILURE, error };
  }
}

function approveUser(user) {
  var data = {
    UserId: user,
  };
  return (dispatch) => {
    dispatch(request());

    userService.approveUser(user).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.ISACTIVE_FAILURE, error };
  }
}

function rejectUser(user) {
  var data = {
    UserId: user,
  };
  return (dispatch) => {
    dispatch(request());

    userService.rejectUser(user).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.ISACTIVE_FAILURE, error };
  }
}

function exportData(obj) {
  return (dispatch) => {
    dispatch(request());
    userService.fetchUserData(obj).then(
      (response) => {
        ;
        dispatch(loadingData(false));
        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.FirstName;
            delete v.LastName;
            delete v.TotalRows;
            delete v.UserId;
            delete v.RoleId;
            delete v.UserType;
            delete v.IsActive;
            delete v.IsResetPassword;
            delete v.Password;
            delete v.Token;
            delete v.RefreshToken;
            delete v.Salt;
            delete v.CreatedDate;
            delete v.CreatedBy;
            delete v.UpdatedBy;
            delete v.UpdatedDate;
            delete v.AadharImage;
            delete v.isApproved;
            delete v.isDeleted;
          });
        }
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.GETEXPORT_REQUEST };
  }
  function success(datas) {
    return { type: userConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: userConstants.GETEXPORT_FAILURE, error };
  }
}

function onHideShow(isbool) {
  return {
    type: userConstants.DIALOG_REQUEST,
    isbool,
  };
}

function loadingData(isbool) {
  return {
    type: userConstants.LOADING_REQUEST,
    isbool,
  };
}

function onHideShowIsActive(isbool) {
  return {
    type: userConstants.DIALOG_ISACTIVE_REQUEST,
    isbool,
  };
}
