export const AlldealerdataLogConstants ={
    GETALL_REQUEST: 'dealerdata_GETALL_REQUEST',
    GETALL_SUCCESS: 'dealerdata_GETALL_SUCCESS',
    GETALL_FAILURE: 'dealerdata_GETALL_FAILURE',

    GETEXPORT_REQUEST: 'dealerdata_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'dealerdata_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'dealerdata_GETEXPORT_FAILURE',

    GETSCITYDDL_REQUEST: 'GETSCITYDDL_REQUEST',
    GETSCITYDDL_SUCCESS: 'GETSCITYDDL_SUCCESS',
    GETSCITYDDL_FAILURE: 'GETSCITYDDL_FAILURE',

    GETSTATEDDL_REQUEST: 'GETSTATEDDL_REQUEST',
    GETSTATEDDL_SUCCESS: 'GETSTATEDDL_SUCCESS',
    GETSTATEDDL_FAILURE: 'GETSTATEDDL_FAILURE',

    LOADING_REQUEST: 'LOADING_REQUEST'
}