import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { loginActions, alertActions, changePasswordActions } from "../actions";
import logo1 from "./../assets/images/Header_logo.png";

class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resendbtn: true,
      UserName: null,
      Password: null,
      loggedIn: false,
      eyeToggle: true,
      forgotModal: false,
      Otp: null,

      formError: {
        UserName: "",
        Password: "",
      },
      formForgotHeading: "Forgot Password",
      buttonLabel: "Submit",
      Email: "",
      forgotFormError: {
        Email: null,
      },
      btnlog: true,
    };
    this.formHandle = this.formHandle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.passwordToggle = this.passwordToggle.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.handleForgotChange = this.handleForgotChange.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.submitForgotForm = this.submitForgotForm.bind(this);
    this.setResend = this.setResend.bind(this);
  }

  componentDidMount() {
    sessionStorage.removeItem("LoggedInUser");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("UserData");
    sessionStorage.removeItem("UserRole");
    sessionStorage.removeItem("UserNameData");
    sessionStorage.removeItem("UserEmailData");
    sessionStorage.removeItem("UserMobileData");
    sessionStorage.removeItem("CompanyData");
    sessionStorage.removeItem("assignedPermissionsForUser");
  }

  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;
      default:
        break;
    }
    this.formHandle(value, name);
    this.setState({ errors, [name]: value });
  }
  formHandle(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "UserName":
        if (value === null || value.length < 1) {
          errors.UserName = "Please enter your email-id.";
        } else if (value.length >= 1) {
          /* eslint-disable */
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            errors.userName = "Please enter valid email-id.";
          } else errors.UserName = null;
          /* eslint-enable */
        } else errors.UserName = null;
        break;
      case "Password":
        if (value === null || value.length < 1) {
          errors.Password = "Please enter password";
        } else errors.Password = null;
        break;
      default:
        break;
    }
    return IsValid;
  }

  passwordToggle() {
    this.setState({ eyeToggle: !this.state.eyeToggle });
  }

  submitForm(event, flag) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.formHandle(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      debugger;
      var formData = {
        Email: this.state.UserName,
        Password: this.state.Password,
      };
      this.props.DeleteAccountUser(formData);
    }
  }

  //to reset form
  formForgotReset() {
    this.setState({ Email: this.state.UserName });
    let errors = this.state.forgotFormError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
      return errors[key];
    });
  }
  //to open dialog
  openDialog() {
    this.setState({ visible: true, Email: this.state.Email });
    //this.formForgotReset();
  }
  //to hide function
  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
    });
    this.formForgotReset();
  }
  //to submit form
  submitForgotForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.forgotFormError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForgotForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const formData = {
        Email: this.state.Email,
      };

      this.props.forgotPassword(formData);
    }
  }
  //to handle change
  handleForgotChange(event) {
    const { name, value } = event.target;
    let errors = this.state.forgotFormError;

    this.validateForgotForm(value, name);
    this.setState({ errors, [name]: value });
  }

  setResend() {
    if (
      this.props.res &&
      this.props.res.ResponseCode &&
      this.props.res.ResponseCode == "205"
    ) {
      this.setState({
        resendbtn: false,
      });
      return false;
    } else return true;
  }
  //to validate form
  validateForgotForm(value, name) {
    let errors = this.state.forgotFormError;
    let IsValid = true;

    switch (name) {
      case "Email":
        if (value == null || value.length < 1) {
          IsValid = false;
          errors.Email = "Please enter your email-id.";
        } else if (value.length >= 1) {
          /* eslint-disable */
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.Email = "Please enter valid email-id.";
          } else errors.Email = "";
          /* eslint-enable */
        } else errors.Email = "";
        break;
      default:
        break;
    }

    return IsValid;
  }
  render() {
    return (
      <>
        <section className="body1">
          <div className="login-wrap mt-5 login-page">
            {/* <img src={logo1} className="img-fluid" /> */}
            <div
              className="login-html slide-in-right mt-5"
              style={{ height: "78%" }}
            >
              <div className="foot-lnk"></div>
              <label className="tab text-center">
                ACCOUNT DELETION REQUEST
              </label>
              <div className="login-form">
                <div className="mt-3">
                  <form
                    onSubmit={this.submitForm}
                    style={{ background: "none" }}
                  >
                    <div className="input-group form-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="Email Id"
                        onChange={this.handleChange}
                        name="UserName"
                        className="form-control user"
                        noValidate
                      />
                      <span className="focus-input100"></span>
                      {this.state.formError.UserName != null ? (
                        <div className="error1">
                          {this.state.formError.UserName}
                        </div>
                      ) : null}
                    </div>
                    <div className="input-group form-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-lock"></i>
                        </span>
                      </div>
                      <input
                        type={this.state.eyeToggle ? "password" : "text"}
                        autoComplete="off"
                        onChange={this.handleChange}
                        placeholder="Password"
                        name="Password"
                        className="form-control"
                        noValidate
                      />
                      <span className="focus-input100 "></span>
                      <i
                        className={
                          this.state.eyeToggle
                            ? "pi pi-eye eye-design"
                            : "pi pi-eye-slash "
                        }
                        onClick={this.passwordToggle}
                      ></i>
                      {this.state.formError.Password != null ? (
                        <div className="error1">
                          {this.state.formError.Password}
                        </div>
                      ) : null}
                    </div>

                    <div className="hr" />
                    <div className="group">
                      <button type="submit" className="login-button button">
                        Request Account Deletion
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Dialog
          header="Forgot Password"
          visible={this.state.forgotModal}
          onHide={() => this.setState({ forgotModal: false })}
        >
          <h5 style={{ color: "#3F51B5" }}>
            Reset Password For AV Wood Coating.
          </h5>
          <p>Enter your Email and instructions will be sent to you!</p>
          <form onSubmit={this.submitForgotForm}>
            <input
              type="text"
              placeholder="Email Id"
              onChange={this.handleForgotChange}
              name="Email"
              className="form-control"
              noValidate
            />
            {this.state.forgotFormError.Email != null ? (
              <div className="error">{this.state.forgotFormError.Email}</div>
            ) : null}
            <div className="col-md-12 mt-3 text-center">
              <Button
                label="Reset"
                className="btn btn-primary"
                type="submit"
                icon="pi pi-check"
              />
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    res:
      state.login?.items && state.login?.items[0]
        ? state.login?.items[0]
        : null,
    resendbtn: state.login?.enable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    DeleteAccountUser: (login) =>
      dispatch(loginActions.DeleteAccountUser(login)),
    forgotPassword: (data) =>
      dispatch(changePasswordActions.forgotPassword(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
