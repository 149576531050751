// request for lazy loading for all pages
export default class tableRequest {
    IsActive = true;
    PageNo = 1;
    First = 1;
    SearchValue = "";
    PageSize = 5;
    SortColumn = "CreatedDate";
    SortOrder = "DESC";
    FromDate = "";
    ToDate = "";
    UserId = JSON.parse(sessionStorage.getItem("UserData"));
    ProductId = 0;
    CityId = 0;
    StateId = 0;
}