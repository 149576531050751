import { authHeader, handleResponse } from '../helpers';
export const ScannedHistoryServices = {
    fetchVerificationData   
};

function fetchVerificationData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}VerificationLog/GetVerificationReport`, requestOptions)
        .then(handleResponse);
}