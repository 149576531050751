import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Dialog } from "primereact/dialog";
import { ToastContainer, toast } from "react-toastify";
import { mappingActions, productMasterActions } from "../../actions";
import tableRequest from "../../models/tableRequest.js";
// import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
// import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
// import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

class uploadMapping extends React.Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    // this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitseries = this.submitseries.bind(this);
    this.resetForm = this.resetForm.bind(this);
    //this.FromNoValue = this.FromNoValue.bind(this);
    this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
    this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onHidee = this.onHidee.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.openDialogue = this.openDialogue.bind(this);
    this.resetFormHide = this.resetFormHide.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);

    this.onRowSelect = this.onRowSelect.bind(this);
    //this.onRowUnselect = this.onRowUnselect.bind(this);

    this.state = {
      IsAdd: true,
      IsClick: true,
      IsEdit: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
      confirm: false,
      buttonLabel: "Submit",
      buttonLabel1: "Upload Certificate",
      formHeading: "Add Mapping",
      displayModal: false,
      switchAction: false,
      showSnackbar: false,
      displayConfirmation: false,
      FileName: "",
      ProductName: "",
      MappingId: 0,
      CreatedDate: null,
      UpdatedDate: null,
      ProductId: "",
      ProductName: "",
      FromNo: "",
      ToNo: "",
      Qty: "",
      BatchNo: "",
      IsActive: true,
      Issueseriesdata: [],
      selectedProduct: null,
      formError: {},
      serieserror: {
        FromNo: "",
        ToNo: "",
        Qty: "",
      },
    };
  }
  //to trigger api on page call
  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        }
      }
    }

    this.setState({
      Issueseriesdata: this.props.Issueseries,
    });
    this.props.fetchApi(this.tblResponse);
    this.props.getPrdctDDL();
  }

  componentDidUpdate(prevProps) {
    if (this.props.MappingData) {
      if (prevProps.MappingData !== this.props.MappingData) {
        // this.props.myProp has a different value
        if (this.props.MappingData) {
          var e = this.props.MappingData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }

    if (prevProps.MaxFromNo != this.props.MaxFromNo) {
      this.setState({
        FromNo: this.props.MaxFromNo,
      });
    }
  }

  // singleCheck() {
  //     let obj = {};
  //     const checkboxes = document.getElementsByName('childCheck');
  //     for (var checkbox of checkboxes) {
  //         if (checkbox.checked) {
  //             obj[checkbox.value] = true;
  //         } else {
  //             obj[checkbox.value] = false;
  //         }
  //     }

  //     return JSON.stringify(obj);
  // }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    this.getLazyLoadingValues(event);
    this.props.fetchApi(this.tblResponse);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  getDropValue() {
    let productId = "";
    this.props.ProductData.filter((x) => {
      if (x.ProductId === this.state.ProductId) {
        productId = x.ProductId;
      }
    });
    return productId;
  }
  //to manage switch toggle confirm box
  switchToggleConfirm = (e) => {
    this.setState({ confirm: true, displayConfirmation: false });
    const formData = {
      MachineId: this.state.MachineId,
    };
    this.props.inActiveRow(this.state.MachineId);
  };

  // FromNoValue(event) {
  //     this.props.getToNumber(event.target.value);
  //     setTimeout(() => {
  //         this.setState({
  //             FromNo: this.props.MaxFromNo
  //         })
  //     }, 800);
  //     this.setState({
  //         ProductId : event.target.value
  //     })
  //     this.handleChange(event);
  // }

  //to handle form
  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    // switch (name) {
    //     case 'IsActive':
    //         value = event.target.checked;
    //         break;
    //     default:
    //         break;
    // }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };
  //to validate form data
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;

    switch (name) {
      case "ProductId":
        if (!value) {
          IsValid = false;
          errors.ProductId = "Please select Product";
        } else errors.ProductId = null;
        break;
      case "FromNo":
        if (!value) {
          IsValid = false;
          errors.FromNo = "Please enter From No";
        } else {
          if (!value.toString().match(/^[0-9]+$/.exec(value))) {
            IsValid = false;
            errors.FromNo = "Please enter only number.";
          } else errors.FromNo = null;
        }
        break;
      case "Qty":
        if (!value === value) {
          IsValid = false;
        } else {
          if (value < 1) {
            IsValid = false;
            errors.Qty = "Please enter your quantity.";
          } else {
            if (!value.toString().match(/^[0-9]+$/.exec(value))) {
              IsValid = false;
              errors.Qty = "Please enter only number.";
              this.setState({ Qty: 0 });
            } else {
              errors.ToNo = null;
              errors.Qty = null;
              errors.FromNo = null;
              const qty =
                value.toString().trim() === ""
                  ? 0
                  : parseInt(value.toString().trim());
              const fromNumber =
                this.state.FromNo.toString().trim() === ""
                  ? 0
                  : parseInt(this.state.FromNo.toString().trim());
              if (fromNumber >= 1 && qty >= 1) {
                const toNumber = qty + fromNumber - 1;
                if (toNumber >= 0) {
                  this.setState({ ToNo: toNumber });
                } else {
                  IsValid = false;
                  errors.Qty =
                    "To number should be greater than or equal to  from number.";
                  this.setState({ ToNo: 0 });
                }
              } else {
                // IsValid = false;
                // errors.ToNo = "To number should be greater than from number.";
                this.setState({ ToNo: 0 });
              }
            }
          }
        }
      default:
        break;
    }

    return IsValid;
  }
  //to get current date
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  //to submit series data
  submitseries(event) {
    event.preventDefault();

    this.props.onHideShoww(false);
  }

  // Upload Submit Form
  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      ;
      var data = new FormData();
      data.append(
        "Inputfile",
        document.querySelector('input[type="file"]').files[0]
      );
      data.append("CreatedBy", this.state.UserId);
      this.props.UploadMapping(data, this.tblResponse);
      setTimeout(() => {
        this.resetForm();
      }, 1000);
    }
  }

  //to reset form
  resetForm() {
    this.setState({
      ProductId: "",
      MappingId: 0,
      FromNo: "",
      ToNo: "",
      Qty: "",
      BatchNo: "",
      // Location: '',
      // DocURL: '',
      formError: {
        FromNo: "",
        ToNo: "",
        Qty: "",
        // BatchNo: '',
        // Location: '',
        // DocURL: '',
        ProductId: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });

    //     const checkboxes = document.getElementsByName('childCheck');
    //     for (var checkbox of checkboxes) {
    //         checkbox.checked = false;
    //     }
  }

  //to reset form on hide
  resetFormHide() {
    this.setState({
      ProductId: "",
      MappingId: 0,
      FromNo: "",
      ToNo: "",
      Qty: "",
      BatchNo: "",
      // Location: '',
      // DocURL: '',
      formError: {
        FromNo: "",
        ToNo: "",
        Qty: "",
        // BatchNo: '',
        // Location: '',
        // DocURL: '',
        ProductId: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }
  //to hide function
  onHide() {
    this.props.onHideShow(false);
    this.resetFormHide();
  }

  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add Mapping" });

    this.props.getToNumber();
  }

  onHidee() {
    this.props.onHideShoww(false);
  }

  openDialogue() {
    this.props.onHideShoww(true);
    this.setState({ buttonLabel: "Submit", formHeadingg: "Available Series" });
    this.props.GetAvailableseries(this.tblResponse);
  }

  onRowSelect(event) {
    //this.toast.show({ severity: 'info', summary: 'Series Selected', detail: `FromNo: ${event.data.FromNo}`, detail: `ToNo: ${event.data.ToNo}`, life: 3000 });
    this.setState({
      FromNo: event.data.FromNo,
      ToNo: event.data.ToNo,
      Qty: event.data.ToNo - event.data.FromNo + 1,
    });
    this.onHidee();
  }

  // onRowUnselect(event) {
  //     this.toast.show({ severity: 'warn', summary: 'Series Unselected', detail: `FromNo: ${event.data.FromNo}`, detail: `ToNo: ${event.data.ToNo}`, life: 3000 });
  // }

  // toCapitalize(str) {
  //     return str.charAt(0).toUpperCase() + str.slice(1);
  // }

  //to get format of date
  UpdatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    }
  }
  //to get format of date
  CreatedDateTemplate(rowData) {
    if (rowData.CreatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
    }
  }

  // changevalue(event, field) {
  //     var ischeck = event.currentTarget.checked;
  //     var id = event.currentTarget.id;
  //     setTimeout(() => {
  //         this.checkCheckBox(ischeck, id);
  //     }, 200);

  //     this.ischeckAllCheck(field);
  // }

  // checkCheckBox(ischeck, id) {
  //     document.getElementById(id).checked = ischeck;
  // }

  //to edit rowdata
  actionBodyTemplate(rowData, i) {
    const editMode = (e) => {
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Mapping",
        displayModal: true,
        ProductId: rowData.ProductId,
        MappingId: rowData.MappingId,
        FromNo: rowData.FromNo,
        ToNo: rowData.ToNo,
        Qty: rowData.Qty,
        BatchNo: rowData.BatchNo,
        // Location: rowData.Location,
        // DocURL: rowData.DocURL,
        CreatedDate: rowData.CreatedDate,
      });
    };
    return <Button icon="pi pi-pencil" onClick={editMode} />;
  }
  //to export data
  exportCSV() {
    this.dt.exportCSV();
  }

  //to render html
  render() {
    const renderFooter = (name) => {
      return (
        <div>
          {/* <Button label="No" icon="pi pi-times" onClick={() => this.setState({ displayConfirmation: false })} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus /> */}
        </div>
      );
    };

    const header = (
      <div className="table-header row">
        <div className="col-md-6">
          <h4>Mapping</h4>
        </div>
        {/* <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.searchFilter(e)} placeholder="Global Search" />
                    </span>
                    {this.state.IsAdd === true ?
                        <Button className="mr-2" icon="pi pi-plus" label="Add" onClick={this.openDialog} />
                        
                        : ""}
                        {this.state.IsAdd === true ?
                        <Button className="mr-2" icon="pi pi-excel" label="Upload Excel" onClick={this.openDialog} />
                        
                        : ""}
                </div> */}
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />

        <div className="col-md-4 col-xl-4">
          <label className="file">
            <input
              type="file"
              name="file"
              onChange={this.handleChange}
              id="file"
              aria-label="File browser example"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ></input>
            <span data-content={this.state.fileName} className="file-custom"></span>
          </label>
          {this.state.formError.file !== "" ? (
            <div className="error mb-2">{this.state.formError.file}</div>
          ) : (
            ""
          )}
          {/* <ExcelFile
            filename={"SampleMapping"}
            element={
              <Button className="btn btn-warning btn-block centerAlign">
                Download Sample Excel
              </Button>
            }
          >
            <ExcelSheet
              name={"Sample"}
              data={[
                {
                  ProductCode: "11AA000.",
                  FromNo: 100,
                  Qty: 10,
                  ToNo: 110,
                  BatchNo: "BX009",
                },
              ]}
            >
              <ExcelColumn label="ProductCode" value="ProductCode" />
              <ExcelColumn label="FromNo" value="FromNo" />
              <ExcelColumn label="Qty" value="Qty" />
              <ExcelColumn label="ToNo" value="ToNo" />
              <ExcelColumn label="BatchNo" value="BatchNo" />
            </ExcelSheet>
          </ExcelFile> */}
          <button
            type="button"
            style={{ marginTop: 5 }}
            className="btn btn-warning btn-block centerAlign"
            onClick={this.submitForm}
          >
            Upload
          </button>
        </div>
        <br></br>

        {/* table data */}

        <div className="card">
          <DataTable
            value={this.props.MappingData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20, parseInt(this.state.totalRecords)]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            {/* <Column field="MappingId" header="Mapping Id" sortable></Column> */}
            <Column field="ProductName" header="Product Name" sortable></Column>
            <Column field="FromNo" header="From No" sortable></Column>
            <Column field="ToNo" header="To No" sortable></Column>
            <Column field="Qty" header="Quantity" sortable></Column>
            <Column field="BatchNo" header="Batch No" sortable></Column>
          </DataTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('state.mapping.Issueseries', state.mapping.Issueseries);
  //console.log('state.mapping.issueData', state.mapping.issueData);
  return {
    MappingData: state.mapping.items,
    ProductData:
      (typeof state.product.prdctddl !== "undefined" &&
        state.product.prdctddl !== null) === true
        ? state.product?.prdctddl
        : null,
    Issueseries: state.mapping.Issueseries,
    MaxFromNo:
      state.mapping.issueData != null ? state.mapping.issueData[0].FromNo : "",
    showDialog: state.mapping.showDialog,
    showDialogue: state.mapping.showDialogue,
    loading: state.company.loding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(mappingActions.getAll(obj));
    },
    fetchProductApi: (obj) => {
      dispatch(productMasterActions.getProductName(obj));
    },
    getToNumber: () => {
      dispatch(mappingActions.GetToNo());
    },
    GetAvailableseries: (data) => {
      dispatch(mappingActions.GetAvailableseries(data));
    },
    UploadMapping: (obj, tblResponse) => {
      dispatch(mappingActions.UploadMapping(obj, tblResponse));
    },
    onHideShow: (isbool) => dispatch(mappingActions.onHideShow(isbool)),
    onHideShoww: (isbool) => dispatch(mappingActions.onHideShoww(isbool)),
    getPrdctDDL: () => dispatch(productMasterActions.getPrdctDDL()),
    loadingData: (isLoding) => dispatch(mappingActions.loadingData(isLoding)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(uploadMapping);
